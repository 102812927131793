import { FC } from "react";
import { Box, Skeleton, Typography, styled } from "@mui/material";
import tublianLogo from "../../images/landing/logo_new.png";
import _8020 from "../../images/learning/80-20.png";
import helperFunctions from "../../libs/helperFunctions";

const BannerCard: FC<IBannerCardProps> = ({
  type,
  beta = false,
  title,
  imgUrl,
  paragraphs,
  loading,
}) => {
  return (
    <BannerWrap type={type}>
      <Box className="textContent">
        <Box className="headers">
          <Typography component="h1">
            {loading ? (
              <Skeleton />
            ) : (
              <>
                {title}
                {beta && <span className="beta-tag">BETA</span>}
              </>
            )}
          </Typography>
          {paragraphs?.map((p, i) => (
            <Typography key={i}>
              {loading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </>
              ) : (
                p
              )}
            </Typography>
          ))}
        </Box>
        <Box className="footerText">
          <span className="logo">
            {loading ? (
              <Skeleton variant="circular" />
            ) : (
              <img src={tublianLogo} alt="tublian" />
            )}
          </span>
          <Typography>
            Powered by <span>Tublian AI Copilot</span>
          </Typography>
        </Box>
      </Box>
      <Box className="imgContent">
        <img
          onError={(e) => helperFunctions.setImagePlaceholder(e, _8020)}
          src={imgUrl || _8020}
          alt={"tublian 8020"}
        />
      </Box>
    </BannerWrap>
  );
};

export default BannerCard;

type BannerType = "learning" | "course";

interface IBannerCardProps {
  type: BannerType;
  beta: boolean;
  title: string;
  paragraphs: string[];
  imgUrl?: any;
  loading?: boolean;
}

const BannerWrap = styled(Box)<{ type: BannerType }>(({ theme, type }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  minHeight: type === "course" ? "auto" : 195,
  padding: type === "course" ? 0 : 20,
  borderRadius: 20,
  background:
    type === "learning"
      ? "linear-gradient(90deg, #7F5DDD 0%, #1E1635 100%)"
      : "",
  [theme.breakpoints.down(700)]: {
    flexDirection: type === "learning" ? "column-reverse" : "column", //
    height: "auto",
    // maxWidth: 460,
    minHeight: type === "learning" ? 340 : "unset",
    padding: type === "learning" ? "20px" : "0",
    alignItems: "center",
  },
  [theme.breakpoints.down(360)]: {
    minHeight: 397,
  },
  [theme.breakpoints.down(320)]: {
    minHeight: 422,
  },
  "& .imgContent": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "15px",
    "& img": {
      borderRadius: "15px",
      // width: 225,
      height: 172,
    },
    [theme.breakpoints.down(768)]: {
      display: "none",
    },
  },
  "& .textContent": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "58%",
    minHeight: "100%",
    zIndex: 2,
    "& .headers": {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      [theme.breakpoints.down(700)]: {
        alignItems: "stretch", //
        textAlign: "left", //
      },
    },
    "& .beta-tag": {
      background: "#2A1D4E",
      borderRadius: "6px",
      padding: "2px 8px",
      marginLeft: "8px",
    },
    "& h1": {
      color: "#FEFEFE",
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "2rem",
      zIndex: 2,
      [theme.breakpoints.down(700)]: {
        fontSize: "1.125rem",
        lineHeight: "1.875rem",
      },
    },
    "& p": {
      color: "#FEFEFE",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.625rem",
      zIndex: 2,
      "& span": {
        color: "#FEFEFE",
        fontWeight: 700,
      },
      [theme.breakpoints.down(700)]: {
        fontSize: "0.875rem",
      },
    },
    "& .footerText": {
      display: "flex",
      gap: 8,
      marginTop: "12px",
      "& .logo": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        backgroundColor: "#FEFEFE",
        width: 23,
        height: 23,
        padding: "5px 5px 4.09px",
        marginRight: "-3px",
        "& img": {
          width: 12,
        },
      },
      "& svg": {
        color: "#FDD649",
      },
      "& p": {
        [theme.breakpoints.down(700)]: {
          fontSize: "0.875rem",
          justifyContent: "center",
        },
      },
      [theme.breakpoints.down(700)]: {
        marginTop: "9.5px",
        justifyContent: "left",
      },
    },
    [theme.breakpoints.down(768)]: {
      marginTop: "9.5px",
      width: "100%",
    },
  },
}));
