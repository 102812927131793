import { Box, Typography, styled } from "@mui/material";
import tublianOSHeroSvg from "../../../images/landing/OsPage/os-hero.svg";
import rocketIcon from "../../../images/landing/OsPage/rocket_icon_dark.svg";

const TublianOsHero = ({
  handleGithubSignIn,
}: {
  handleGithubSignIn: () => void;
}) => {
  return (
    <Root>
      <Status>
        <img src={rocketIcon} alt="" />
        <Typography>Coming Soon</Typography>
      </Status>

      <HeroText>
        <MainText>
          <Typography component="h1">Unleash your creativity with</Typography>
          <Typography component="h1">
            Tublian
            <img className="heroImg" src={tublianOSHeroSvg} alt="" />
          </Typography>
        </MainText>
        <Subtext>
          Where Passion Meets Productivity in Software Development.
        </Subtext>

        <CtaButton onClick={handleGithubSignIn}>Join Waitlist</CtaButton>
      </HeroText>
    </Root>
  );
};

export default TublianOsHero;

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 1200,
  [theme.breakpoints.down(768)]: {
    width: "100%",
    padding: "0 16px",
  },
}));

const HeroText = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "#FEFEFE",
  width: "100%",
  [theme.breakpoints.down(700)]: {
    padding: "0 16px",
  },
}));

const MainText = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "6px",
  width: "987px",
  margin: "18px 0 20px",
  color: "#FEFEFE",
  "& h1": {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    color: "#FEFEFE",
    fontWeight: 700,
    fontSize: "4.25rem",
    lineHeight: "5.423125rem",
    textAlign: "center",
    [theme.breakpoints.down(1025)]: {
      fontSize: "3rem",
      lineHeight: "3.828125rem",
      gap: "16px",
      "& .heroImg": {
        height: "60px",
      },
    },
    [theme.breakpoints.down(768)]: {
      fontSize: "1.5rem",
      lineHeight: "1.91375rem",
      gap: "8px",
      "& .heroImg": {
        height: "32px",
      },
    },
    [theme.breakpoints.down(380)]: {
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      // gap: "8px",
      "& .heroImg": {
        height: "28px",
      },
    },
  },
  [theme.breakpoints.down(768)]: {
    gap: "4px",
    margin: "20px 0",
  },
}));

const Subtext = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1.25rem",
  lineHeight: "2rem",
  textAlign: "center",
  color: "#FEFEFE",
  marginBottom: "40px",
  zIndex: 3,
  [theme.breakpoints.down(900)]: {
    fontSize: "1.2rem",
    textAlign: "center",
    marginBottom: 20,
  },
}));

const CtaButton = styled("button")(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1rem",
  lineHeight: "1.25rem",
  color: "#FEFEFE",
  background: "linear-gradient(to bottom, #FDD649 0%, #97802C 100%)",
  border: "1px solid rgba(253, 214, 73, 1)",
  borderRadius: 20,
  padding: "8px 32px",
  "&:hover": {
    background: "#97802C",
  },
  [theme.breakpoints.down(900)]: {
    width: "100%",
    maxWidth: "358px",
  },
}));

const Status = styled(Box)({
  display: "flex",
  padding: "4px 20px",
  justifyContent: "center",
  alignItems: "center",
  // margin: "auto",
  gap: "6px",
  background: "linear-gradient(180deg, #222325 0%, #383A3D 100%)",
  border: "1px solid #333538",
  borderRadius: "20px",
  width: "fit-content",
  "& p": {
    color: "#FEFEFE",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: 700,
  },
});
