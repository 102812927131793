import { Button, styled } from "@mui/material";
import { ICustomButtonProps, getSizeStyles } from "./CustomBtnResources";

const CompanyButton = ({
  disabled,
  children,
  onClick,
  style,
  size = "large",
  nativeType,
  type = "primary",
  loading = false,
  loadingText = "Loading...",
  preImg,
  postImg,
}: ICustomButtonProps) => {
  const CompButton = styled(Button)`
    && {
      text-transform: none;
      text-decoration: none;
      background: linear-gradient(
        to right,
        #fbda61 0%,
        rgba(255, 90, 205, 0.84) 100%
      );
      color: #FEFEFE;
      border-radius: 20px;
      font-weight: 500;
      z-index: 999;
      width: auto;
      padding: ${getSizeStyles(size, "comapny").padding};
      font-size: ${getSizeStyles(size, "comapny").fontSize};
      line-height: ${getSizeStyles(size, "comapny").lineHeight};
      &:hover {
        background: linear-gradient(to right, #fdecaf 0%, #ffade6 100%);
        transform: scale(1.02);
      }
    }
  `;

  return (
    <CompButton disabled={disabled} onClick={onClick} style={style}>
      {!loading && preImg && typeof preImg === "string" ? (
        <img
          src={preImg}
          alt="preImg"
          className="preImg"
          style={{ display: "flex", marginRight: 8 }}
          width={size === "large" || type === "onBoarding" ? 24 : 18}
          height={size === "large" || type === "onBoarding" ? 24 : 18}
        />
      ) : (
        !loading &&
        preImg && (
          <div style={{ display: "flex", marginRight: 8 }} className="preImg">
            {preImg}
          </div>
        )
      )}

      {!loading ? children : loadingText}

      {!loading && postImg && typeof postImg === "string" ? (
        <img
          src={postImg}
          className="postImg"
          style={{ display: "flex", marginLeft: 8 }}
          alt="postImg"
          width={size === "large" ? 24 : 18}
          height={size === "large" ? 24 : 18}
        />
      ) : (
        !loading &&
        postImg && (
          <div style={{ display: "flex", marginLeft: 8 }} className="postImg">
            {postImg}
          </div>
        )
      )}
    </CompButton>
  );
};

export default CompanyButton;
