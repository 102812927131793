import { userProfile } from './UserProfile';
import ProfileEndpoint from "./ProfileEndpoint";
import { logEvent } from './amplitude';
import Bugsnag from "@bugsnag/js";


const github_client_id = process.env.REACT_APP_GITHUB_CLIENT_ID;
const github_auth_lambda_url = `${process.env.REACT_APP_FT_BACKEND_API_BASE}/auth/github`;
const github_lambda_url = `${process.env.REACT_APP_FT_BACKEND_API_BASE}/user/github`;
const github_lambda_headers = {
  "x-api-key": process.env.REACT_APP_FT_BACKEND_API_KEY,
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

const getHeaders = () => {
  const headers = { ...github_lambda_headers };

  const jwt = userProfile(localStorage).token();
  if (jwt) headers.Authorization = `Bearer ${jwt}`;

  return headers;
}

const Github = {
  async signIn(code, openToInternship = false) {
    const user = userProfile(localStorage);
    try {
      var redirectUrl = new URL(`${window.location.protocol}//${window.location.host}/auth/success/redirect`);
      redirectUrl.searchParams.append('provider', 'github');
      redirectUrl.searchParams.set('openToInternship', openToInternship);
      const scopes = user.githubScopes();
      var url = `https://github.com/login/oauth/authorize?client_id=${github_client_id}&redirect_uri=${encodeURIComponent(redirectUrl.href)}&scope=${scopes}&state=${code}`
      // console.log("github oauth " + url);
      logEvent("SignIn", code);
      window.open(url, "_self");
    } catch (e) {
      console.log(e);
    }
  },
  async editProfile(userName) {
    const r = await ProfileEndpoint.generateTokenForEdit(userName);
    // console.log("Token >>>> ", r);
    await this.signIn(r.token);
  },
  async accessToken(token) {
    var response = await fetch(github_auth_lambda_url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        type: "access_token",
        token: token
      })
    });
    return response.json();
  },
  reAuthenticateUserUrl(uri, scopes, code) {
    var redirectUrl = new URL(
      `${window.location.protocol}//${window.location.host}${uri}`
    );
    redirectUrl.searchParams.append("provider", "github");
    var url = encodeURI(
      `https://github.com/login/oauth/authorize?client_id=${github_client_id}&redirect_uri=${redirectUrl.href}&scope=${scopes}&state=${code}`
    );
    return url;
  },
  async reAuthenticateUser(scopes = "read:user", code) {
    // console.log("Reauthenticating");
    try {
      const url = Github.reAuthenticateUserUrl(
        "/auth/success/redirect",
        scopes,
        code
      );
      // console.log("github oauth " + url);
      logEvent("Reauthenticating", code);
      window.open(url, "_self");
    } catch (e) {
      console.log(e);
    }
  },
  async currentUserInfo() {
    var response = await fetch(github_lambda_url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        type: "user_info",
      }),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return {};
    });
  },
  async roles() {
    var response = await fetch(github_lambda_url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        type: "user_roles",
      }),
    });
    return response.json().catch((e) => {
      Bugsnag.notify(e);
      return [];
    });
  },
};

export default Github;
