import { FC } from "react";
import useTubliChatBotStyles from "./tubliChatBotStyles";
import tublibot from "../../../images/landing/tubliBot.svg";
import { Avatar } from "@mui/material";
import { ITublibotAvatar } from "./tubliChatBotResources";
import { userProfile } from "../../../libs/UserProfile";

const TublibotAvatar: FC<ITublibotAvatar> = ({ size = 24, type = "bot" }) => {
  const classes = useTubliChatBotStyles();
  let sourceImg = tublibot;

  if (type === "user") {
    const userCache = userProfile(localStorage);
    sourceImg = userCache.userAvatar();
  }

  return (
    <Avatar
      className={classes.tublibot}
      alt="Avatar"
      src={sourceImg}
      style={{
        width: size,
        height: size,
      }}
    />
  );
};

export default TublibotAvatar;
