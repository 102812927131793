import { GithubRepo } from "./github-repo";

export class GithubIssue {
    url: string;
    title: string;
    body: string;
    labels: string[];
    estimatedCred: number;
    issueId: number;
    entityId: number;
    repoId: number;
    roleOfAuthor: string;
    lastUpdated: Date;
    repo: GithubRepo;

    creator() {
        if (this.roleOfAuthor === "CONTRIBUTOR") {
            return "Created by: Contributor"
        }
        if (this.roleOfAuthor === "COLLABORATOR") {
            return "Created by: Collaborator"
        }
        if (this.roleOfAuthor === "MEMBER") {
            return "Created by: Member"
        }
        return "Created by: User"
    }

    constructor(url: string,
        title: string,
        body: string,
        labels: string[],
        estimatedCred: number,
        issueId: number,
        entityId: number,
        repoId: number,
        roleOfAuthor: string,
        lastUpdated: Date,
        repo: GithubRepo) {
        this.url = url;
        this.title = title;
        this.body = body;
        this.labels = labels;
        this.estimatedCred = estimatedCred;
        this.issueId = issueId;
        this.entityId = entityId;
        this.repoId = repoId;
        this.roleOfAuthor = roleOfAuthor;
        this.lastUpdated = lastUpdated;
        this.repo = repo;
    }

    static createIssue(issueJson: any, repo: GithubRepo) {
        const issueId = issueJson.id;
        const entityId = issueJson.entityId;
        const body = issueJson.body;
        const labels = issueJson.labels;
        const title = issueJson.title;
        const url = issueJson.url;
        const roleOfAuthor = issueJson.roleOfAuthor;
        const repoId = issueJson.repoId;
        const estimatedCred = issueJson.estimatedCred;
        const lastUpdated = new Date(issueJson.lastUpdated);
        const issue = new GithubIssue(url,
            title,
            body,
            labels,
            estimatedCred,
            issueId,
            entityId,
            repoId,
            roleOfAuthor,
            lastUpdated,
            repo);
        return issue;
    }
}

export class GithubIssueSummary {
    url: string;
    title: string;
    body: string;
    languages: string[];
    estimatedCred: number;
    issueId: number;
    repoId: number;

    constructor(url: string,
        title: string,
        body: string,
        languages: string[],
        estimatedCred: number,
        issueId: number,
        repoId: number) {
        this.url = url;
        this.title = title;
        this.body = body;
        this.languages = languages;
        this.estimatedCred = estimatedCred;
        this.issueId = issueId;
        this.repoId = repoId;
    }
}