import { Link, NavLink } from "react-router-dom";
import { Box, styled } from "@mui/material";
import logo from "../../images/tublianLogo.svg";
import darkLogo from "../../images/logo_text_dark.svg";

const Footer = ({ darkMode = false }) => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <BgContainer>
        <LogoContainer to="/">
          <img alt="logo" src={darkMode ? darkLogo : logo} />
        </LogoContainer>

        <LinkContainer darkMode={darkMode?.toString()}>
          <Header darkMode={darkMode?.toString()}>Links</Header>

          <Link to="/manifesto">Manifesto</Link>
          <a
            href="https://devlog.tublian.com/"
            target="_blank"
            rel="noreferrer"
          >
            Blog
          </a>
          <Link to="/streetcred">What is Streetcred?</Link>
          <Link to="/internship">OS Internship</Link>
        </LinkContainer>
        <LinkContainer darkMode={darkMode?.toString()}>
          <Header darkMode={darkMode?.toString()}>Socials</Header>

          <a
            href="https://www.linkedin.com/company/tublian"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
          <a
            href="https://twitter.com/tublian"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.youtube.com/channel/UCrK_of2nLDdw6BBVGucbM_w"
            target="_blank"
            rel="noreferrer"
          >
            Youtube
          </a>
          <a
            href="https://discord.gg/S6KGq3NR7F"
            target="_blank"
            rel="noreferrer"
            style={{ width: "241px" }}
          >
            Join our community on Discord
          </a>
        </LinkContainer>
      </BgContainer>
      <Copyright darkMode={darkMode?.toString()}>
        <footer>
          {currentYear} Tublian Company Group. All right reserved.
        </footer>
        <CopyrightLinks darkMode={darkMode?.toString()}>
          <a href="/privacy">Privacy policy</a>
          <a href="/terms">Terms of use</a>
        </CopyrightLinks>
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "relative",
  zIndex: 2,
  margin: "auto",
}));

const BgContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  margin: "0px 0 81px 0",
  padding: "48px 64px 14px 64px",
  justifyContent: "flex-start",
  gap: "57px",
  [theme.breakpoints.down(900)]: {
    flexDirection: "column",
    margin: 0,
    gap: "40px",
    width: "100%",
  },
  [theme.breakpoints.down(768)]: {
    padding: "20px 16px 0",
  },
}));

const LogoContainer = styled(NavLink)(({ theme }) => ({
  [theme.breakpoints.down(900)]: {
    margin: "0 0 8px",
    width: "92px",
  },
}));

const Header = styled("h4")<{ darkMode?: string }>(({ theme, darkMode }) => ({
  color: darkMode === "true" ? "#121212" : "#FEFEFE",
  fontSize: 18,
  fontWeight: 500,
  lineHeight: "30px",
  marginBottom: 4,
}));

const LinkContainer = styled(Box)<{ darkMode?: string }>(({ darkMode }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  "&>a": {
    textDecoration: "none",
    color: darkMode === "true" ? "#696969" : "#B7B7B7",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    "&:hover": {
      color: darkMode === "true" ? "#121212" : "#FEFEFE",
    },
  },
}));

const Copyright = styled(Box)<{ darkMode?: string }>(({ theme, darkMode }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 64px 14px 64px",
  [theme.breakpoints.down(900)]: {
    flexDirection: "column-reverse",
    gap: "28px",
  },
  [theme.breakpoints.down(768)]: {
    padding: "20px 16px",
  },
  "&>footer": {
    color: darkMode === "true" ? "#888" : "#696969",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
  },
}));

const CopyrightLinks = styled(Box)<{ darkMode?: string }>(
  ({ theme, darkMode }) => ({
    "&>a": {
      textDecoration: "none",
      color: darkMode === "true" ? "#696969" : "#B7B7B7",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem",
      "&:hover": {
        color: darkMode === "true" ? "#121212" : "#FEFEFE",
      },
    },
    "& > :not(:last-child)": {
      marginRight: "18px",
    },
    [theme.breakpoints.down(900)]: {
      marginTop: "40px",
    },
  })
);
