import { FC, useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import CoursePresentationCard from "./CoursePresentationCard";
import { useLearnContext } from "./LearnContext";
import CustomButton from "../../Common/CustomButton";
import CustomLoader from "../../Common/CustomLoader";
import EmptyRoadmapPhasesState from "../EmptyRoadmapPhasesState";
import { ILearningProgress } from "../Common/LearningTypes";
import { userProfile } from "../../../libs/UserProfile";

const RoadmapPhaseView: FC<RoadmapPhaseViewProps> = ({
  roadmapId,
  phaseProgress,
}) => {
  const userName = userProfile(localStorage).userId();
  const [roadmapRetries, setRoadmapRetries] = useState(0);
  const {
    roadmapPhasesList,
    loadingState,
    handleGetRoadmap,
    handleDeleteRoadmap,
    getPhaseProgress,
    setError,
  } = useLearnContext();

  const notLoading =
    !loadingState.loadingRoadmaps && !loadingState.creatingRoadmaps;

  const creatingRoadmap =
    !loadingState.loadingRoadmaps &&
    !loadingState.deletingRoadmap &&
    loadingState.creatingRoadmaps;

  const handleResumeFetch = async () => {
    if (roadmapRetries >= 2) {
      await handleGetRoadmap(roadmapId, userName); // 3rd and final try
      if (roadmapPhasesList?.roadmapState === "failure") {
        handleDeleteRoadmap(roadmapId, false); // Delete if 3rd try fails
        setRoadmapRetries(0); // Reset retry count
        setError({
          error: true,
          errorMsg:
            "Unable to resume roadmap generation. Please try a different learning prompt",
        });
      }
    } else {
      setRoadmapRetries((prev) => prev + 1);
      handleGetRoadmap(roadmapId, userName);
    }
  };

  useEffect(() => {
    if (notLoading && roadmapPhasesList?.roadmapState === "incomplete") {
      handleGetRoadmap(roadmapId, userName);
    }

    // eslint-disable-next-line
  }, [loadingState.loadingRoadmaps]);

  if (loadingState.loadingRoadmaps) {
    return (
      <LoaderWrap>
        <CustomLoader primaryMsg="Getting Your Roadmap..." />
      </LoaderWrap>
    );
  }

  return (
    <MainContent>
      <Box sx={{ width: "100%" }}>
        <RoadmapContent>
          <Box className="roadmapPhases">
            {roadmapPhasesList?.roadmap?.phases?.map((p, i) => (
              <CoursePresentationCard
                key={i}
                course={{
                  id: p.id,
                  learningPrompt:
                    roadmapPhasesList?.roadmap?.learningPrompt || "",
                  phaseRoadmapId: roadmapId,
                  phaseType: "normal",
                  imageUrl: roadmapPhasesList?.roadmap.image || undefined,
                  courseName: p.phaseTitle,
                  description: p.overview,
                  stacks: p.tags,
                  courseTopics: p.tags,
                  structure: p.sections.map((s) => s.title),
                  sections: new Map(p.sections.map((s, j) => [j + 1, s])),
                }}
                completedCnt={getPhaseProgress(phaseProgress?.[p.id]).completed}
                sectionsCnt={getPhaseProgress(phaseProgress?.[p.id]).total}
                btnText={`Phase ${i + 1}`}
                imageUrl={roadmapPhasesList?.roadmap.image}
                viewOnly={false}
              />
            ))}
          </Box>
          {notLoading && roadmapPhasesList?.roadmapState === "incomplete" && (
            <Box className="resumeBtn">
              <CustomButton
                onClick={() => handleGetRoadmap(roadmapId, userName)}
                type="primary"
                size="medium"
              >
                Resume roadmap generation
              </CustomButton>
            </Box>
          )}

          {notLoading && roadmapPhasesList?.roadmapState === "failure" && (
            <Box className="resumeBtn">
              <CustomButton
                onClick={handleResumeFetch}
                disabled={roadmapRetries >= 2}
                type="grey"
                size="medium"
              >
                Restart roadmap generation
              </CustomButton>
            </Box>
          )}
        </RoadmapContent>

        <EmptyRoadmapPhasesState />

        {creatingRoadmap && (
          <LoaderWrap sx={{ marginBottom: "20px" }}>
            <CustomLoader
              primaryMsg="Personalizing your path to perfection, in real time"
              secondaryMsg="Each part of your roadmap will appear as we craft it..."
            />
          </LoaderWrap>
        )}

        {loadingState.loadingRoadmaps && (
          <LoaderWrap>
            <CustomLoader
              primaryMsg="Getting Your Roadmap..."
              secondaryMsg="This might take a while"
            />
          </LoaderWrap>
        )}
      </Box>
    </MainContent>
  );
};

export default RoadmapPhaseView;

interface IPhaseProgress {
  [id: string]: ILearningProgress;
}

interface RoadmapPhaseViewProps {
  roadmapId: string;
  phaseProgress: IPhaseProgress | undefined;
}

const MainContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  "&>div": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down(860)]: {
      width: "100%",
    },
    [theme.breakpoints.down(736)]: {
      width: "100%",
    },
  },
  "& .creditStatWrap": {
    margin: "8px 0 12px",
  },
}));

const LoaderWrap = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "12px",
});

const RoadmapContent = styled(Box)({
  "& .roadmapPhases": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "8px 0 0",
  },
  "& .resumeBtn": {
    marginTop: "12px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
});
