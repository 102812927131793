import { CSSProperties, FC } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ProgressBar: FC<ProgressBarProps> = ({
  startText,
  endText,
  endTextIcon,
  current,
  max,
  progressColor = "#76F368",
  startTextColor = "#888",
  endTextColor = "#888",
  borderRadius = 0,
  height = 10,
  textRowStyles = {},
  progressBarStyles = {},
  margin = 0,
}) => {
  const progress = (current / max) * 100;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginBottom: `${margin}px`,
          ...textRowStyles,
        }}
      >
        <Typography
          sx={{
            color: startTextColor,
            fontSize: "0.875rem",
            lineHeight: "1.5rem",
            fontWeight: 400,
          }}
        >
          {startText}
        </Typography>
        {endTextIcon && typeof endTextIcon === "string" ? (
          <img src={endTextIcon} alt="end icon" width="12px" height="12px" />
        ) : (
          endTextIcon && (
            <Box
              sx={{
                color: endTextColor,
                textTransform: "capitalize",
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                fontWeight: 500,
              }}
            >
              {endTextIcon}
            </Box>
          )
        )}
        <Typography
          sx={{
            color: endTextColor,
            textTransform: "capitalize",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            fontWeight: 500,
            marginLeft: endTextIcon ? "-6px" : "0px",
          }}
        >
          {endText}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        style={{
          borderRadius,
          height,
          ...progressBarStyles,
          backgroundColor: "#272727",
        }}
        sx={{
          backgroundColor: progressColor,
          width: "100%",
          "& .MuiLinearProgress-barColorPrimary": {
            background: progressColor,
            borderRadius: "10px",
          },
        }}
      />
    </Box>
  );
};

export default ProgressBar;

interface ProgressBarProps {
  startText?: string | number;
  endText?: string | number;
  current: number;
  max: number;
  progressColor?: string;
  endTextIcon?: any;
  startTextColor?: string;
  endTextColor?: string;
  borderRadius?: number;
  height?: number;
  textRowStyles?: any;
  progressBarStyles?: CSSProperties;
  margin?: number;
}
