import { useEffect } from "react";
import { Course, RoadmapListItem } from "../../Common/LearningTypes";
import { userProfile } from "../../../../libs/UserProfile";
import LearningRoadmapEndpoint from "../../../../libs/LearningRoadmapEndpoint";
import { useLearnContext } from "../LearnContext";
import helperFunctions from "../../../../libs/helperFunctions";

const useFetchRoadmapData = (
  roadmapId: string | undefined,
  courseId: string | undefined
) => {
  const userId = userProfile(localStorage).userId();
  const { reencodeRoadmapId } = helperFunctions;

  const { activeCourse, getRoadmapPhaseByCourseId, setActiveCourse, setError } =
    useLearnContext();

  useEffect(() => {
    const fetchRoadmapData = async () => {
      if (!roadmapId || !courseId) return;

      // eslint-disable-next-line
      const encodedRoadmapId = reencodeRoadmapId(roadmapId);

      if (activeCourse.phaseRoadmapId !== encodedRoadmapId) {
        try {
          const roadmapData: RoadmapListItem =
            await LearningRoadmapEndpoint.getRoadmap(encodedRoadmapId, userId);

          const phase = getRoadmapPhaseByCourseId(roadmapData, courseId);

          if (phase) {
            const { phaseTitle, overview, tags, sections, id } = phase;

            const parsedCourse: Course = {
              id,
              learningPrompt: roadmapData?.roadmap?.learningPrompt || "",
              phaseType: roadmapData?.roadmap?.roadmapType || "normal",
              phaseRoadmapId: encodedRoadmapId || "",
              courseName: phaseTitle,
              imageUrl: roadmapData?.roadmap?.image || undefined,
              description: overview,
              stacks: tags,
              courseTopics: tags,
              structure: sections.map(({ title }) => title),
              sections: new Map(sections.map((s, j) => [j + 1, s])),
            };

            setActiveCourse(parsedCourse);
          }
        } catch (error) {
          console.error("Error fetching roadmap data:", error);
          setError({
            error: true,
            errorMsg: "Error fetching roadmap data, please retry.",
          });
        }
      }
    };

    fetchRoadmapData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roadmapId, activeCourse, courseId, userId]);
};

export default useFetchRoadmapData;
