import { useCallback } from "react";
import { UserProvider } from "./Presentation/UserContext";
import ChallengesPresentation from "../Challenges/ChallengesPresentation/ChallengesPresentation";
import Sidebar from "../UserHome/Base/Sidebar/Sidebar";
import MainLayout from "../UserHome/Base/MainLayout";
import IndividualChallenge from "./IndividualChallenge";

export default function PersonalizedChallenge({
  type = "questions",
  view,
}: {
  view: string;
  type: string;
}) {
  const isPublic = (view && view === "public") || false;

  const renderMainArea = useCallback(() => {
    switch (type) {
      case "challenge_details":
        return <IndividualChallenge isPublic={isPublic} />;
      case "challenge_list":
        return <ChallengesPresentation isPublic={isPublic} />;
      default:
        return <ChallengesPresentation isPublic={isPublic} />;
    }
  }, [type, isPublic]);

  return (
    <div className="wrapper">
      <UserProvider>
        <Sidebar isPublic={isPublic} />
        <MainLayout pageHeader="Challenge" isPublic={isPublic}>
          {renderMainArea()}
        </MainLayout>
      </UserProvider>
    </div>
  );
}
