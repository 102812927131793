import { FC } from "react";
import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface CustomAvatarProps {
  src: string;
  size: number;
  type?: string;
  avatarClasses?: string;
  wrapperClasses?: string;
}

const useStyles = makeStyles({
  root: (props: { size: number }) => ({
    position: "relative",
    width: props.size || 36,
    height: props.size || 38,
    border: "1px solid transparent",
    background:
      "linear-gradient(#090600, #090600) padding-box, linear-gradient(109.15deg, #FBDA61 0%, #FF5ACD 100%) border-box",
    borderRadius: "50%",
  }),
  overlay: {
    position: "absolute",
    top: "calc(100% - 16px)",
    left: 20,
    width: 16,
    height: 16,
  },
});

const CustomAvatar: FC<CustomAvatarProps> = ({
  src,
  size,
  wrapperClasses,
  avatarClasses,
  type,
}) => {
  const classes = useStyles({ size });

  return (
    <div className={`${classes.root} ${wrapperClasses}`}>
      <Avatar
        className={avatarClasses}
        alt="Avatar"
        src={src}
        style={{ width: "100%", height: "100%" }}
      />
      {/* {type && (
        <div className={classes.overlay}>
          <img
            src={chooseOvelay(type)}
            width={20}
            height={20}
            alt="avatar-overlay"
          />
        </div>
      )} */}
    </div>
  );
};

export default CustomAvatar;
