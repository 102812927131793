import { HelpOutline } from "@mui/icons-material";
import { Tooltip, Typography, styled } from "@mui/material";
import { useLearnContext } from "./Course/LearnContext";
import Spinner from "../Common/Spinner";

const CreditStatus = ({ hasBorder = true }) => {
  const { credits, loadingState } = useLearnContext();

  if (loadingState.loadingCredits) {
    return <Spinner size={18} pryColor="#FEFEFE" secColor="#121212" />;
  }

  return (
    <Credits credits={credits} hasborder={hasBorder ? "true" : "false"}>
      <StyledTooltip
        title="One credit = one learning roadmap"
        componentsProps={{
          tooltip: {
            sx: {
              width: "184px",
              background: "#1E1E1E",
              color: "#FEFEFE",
              border: "1px solid #292929",
              borderRadius: "6px",
              padding: "4px 8px",
            },
          },
        }}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [-12, 0],
                },
              },
            ],
          },
        }}
        arrow={false}
        placement="bottom-start"
      >
        <HelpOutline />
      </StyledTooltip>
      <span style={{ color: "#FEFEFE" }}>{credits}</span>
      credits left
    </Credits>
  );
};

export default CreditStatus;

const Credits = styled(Typography)<{ credits: number; hasborder: string }>(
  ({ theme, credits, hasborder }) => ({
    border:
      hasborder === "true"
        ? `1px solid ${credits > 0 ? "#292929" : "#FA8785"}`
        : "",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "4px",
    padding: hasborder === "true" ? "4px 8px" : "",
    borderRadius: "21px",
    background: "#1E1E1E",
    color: "#B7B7B7",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1.25rem",
    // width: "187px",
  })
);

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  color: "#888",
  backgroundImage: "none",
  cursor: "pointer",
}));
