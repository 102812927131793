import { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Popper,
  Radio,
  RadioGroup,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CustomButton from "../../Common/CustomButton";
import SlidingSelect from "./SlidingSelect";
import { FilterContentType, FilterOptions } from "../Common/LearningTypes";
import { filterSvg, infoSvg } from "../../UserHome/Base/SVG";

const CustomizeCourseContent = ({
  open,
  setOpen,
  filterOptions,
  updateFilterOptions,
  setAlertActive,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  filterOptions: FilterOptions;
  updateFilterOptions: any;
  setAlertActive: Dispatch<SetStateAction<boolean>>;
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickFilter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const DropdownComp = () => (
    <DropdownMenu onClick={() => setOpen(!open)} id="export-panel" open={open}>
      <Box>
        <Box>
          <Typography component="h3">Content Type</Typography>
          <Close className="closeIconMobile" onClick={handleClickAway} />
          <Box sx={{ marginTop: "14px" }}>
            <StyledFormControl>
              <RadioGroup
                aria-labelledby="content-type"
                defaultValue="both"
                name="content-type-group"
                value={filterOptions.contentType}
                onChange={(e) =>
                  updateFilterOptions(
                    "contentType",
                    e.target.value as FilterContentType
                  )
                }
              >
                <StyledLabel
                  value="video"
                  control={<StyledRadio />}
                  label="Videos Only"
                />
                <StyledLabel
                  value="blog"
                  control={<StyledRadio />}
                  label="Articles & Blogs"
                />
                <StyledLabel
                  value="both"
                  control={<StyledRadio />}
                  label="Both"
                />
              </RadioGroup>
            </StyledFormControl>
          </Box>
          <Box>
            <Typography component="h3" sx={{ margin: "12px 0 6px" }}>
              Timeline
            </Typography>
            <SlidingSelect
              onChange={(value) => updateFilterOptions("timeFrame", value)}
              value={filterOptions.timeFrame}
              options={[
                // { label: "Latest", value: 1 },
                { label: "Latest", value: 3 },
                { label: "Recent", value: 9 },
                { label: "Most Popular", value: 15 },
                // { label: "Past 2 Years", value: 24 },
              ]}
            />
          </Box>
        </Box>
        <BtnWrap>
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              background: "#292929",
              padding: "6px 10px",
              borderRadius: "7px",
              border: "1px solid #414141",
              width: "100%",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              fontWeight: 500,
              height: "auto",
              color: "#FEFEFE",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
              },
              "& svg": {
                width: "14px",
                height: "14px",
                paddingTop: "5.17px",
                color: "#B7B7B7",
              },
            }}
          >
            {infoSvg}
            Applies exclusively to this phase.
          </Box>
          <CustomButton
            type="secondary"
            size="small"
            onClick={() => setAlertActive(true)}
          >
            Apply
          </CustomButton>
        </BtnWrap>
      </Box>
    </DropdownMenu>
  );

  if (isMobile) {
    return (
      <StyledFilterWrap sx={{ position: "static" }}>
        <FilterButton onClick={handleClickFilter}>
          {filterSvg}
          Customize Content
        </FilterButton>
        <DropdownComp />;
      </StyledFilterWrap>
    );
  }

  return (
    <StyledFilterWrap>
      <FilterButton onClick={handleClickFilter}>
        {filterSvg}
        Customize Content
      </FilterButton>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{
          zIndex: 9,
        }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              rootBoundary: "viewport",
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          {DropdownComp()}
        </ClickAwayListener>
      </Popper>
    </StyledFilterWrap>
  );
};

export default CustomizeCourseContent;

const StyledFilterWrap = styled(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
});

const FilterButton = styled(Button)({
  color: "#FEFEFE",
  background: "#1E1E1E",
  width: "220px",
  border: "1px solid #292929",
  borderRadius: "10px",
  display: "flex",
  gap: "6px",
  fontWeight: 500,
  fontSize: "1rem",
  lineHeight: "1.25rem",
  "&:hover": {
    background: "#414141",
  },
});

const DropdownMenu = styled(Box)<{ open: boolean }>(({ theme, open }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  border: "1px solid #292929",
  backgroundColor: "#1E1E1E",
  zIndex: 99,
  borderRadius: "20px",
  gap: "8px !important",
  padding: "32px 20px 20px",
  width: "268px",
  minHeight: "380px",
  "& h3": {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.25rem",
    color: "#FEFEFE",
  },
  "& p": {
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    color: "#FEFEFE",
  },
  "& .closeIcon": {
    position: "absolute",
    top: 12,
    right: 12,
  },
  "& .closeIconMobile": {
    display: "none",
  },
  "&>div": {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.down(768)]: {
    display: open ? "flex" : "none !important",
    padding: "20px",
    borderRadius: "0",
    position: "absolute",
    top: 0,
    right: 0,
    height: "100vh",
    width: "calc(100% - 16px)",
    "& .closeIcon": {
      display: "none",
    },
    "& .closeIconMobile": {
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 99,
      color: "#FEFEFE",
      fontSize: "18px",
    },
  },
}));

const BtnWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "14px",
  width: "100%",
  "& button": {
    width: "100% !important",
  },
  marginTop: "20px",
  [theme.breakpoints.down(768)]: {
    position: "absolute",
    bottom: 0,
    marginTop: "0",
  },
}));
const StyledFormControl = styled(FormControl)({
  "& *": {
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
});

const StyledLabel = styled(FormControlLabel)({
  color: "#FEFEFE",
  "& .Mui-checked .MuiSvgIcon-root": {
    color: "#FEFEFE",
  },
});

const StyledRadio = styled(Radio)({
  "& .MuiSvgIcon-root": {
    fontSize: 18,
    color: "#414141",
  },
});
