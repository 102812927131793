import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Icon,
  MenuItem,
  Select,
  Typography,
  outlinedInputClasses,
  styled,
} from "@mui/material";
import CustomButton from "../../Common/CustomButton";
import { useLearnContext } from "./LearnContext";
import Spinner from "../../Common/Spinner";
import internshipMap from "../../../images/learning/internship-world-map.png";
import { arrowRightSvg, eightyTwentyIcon } from "../../UserHome/Base/SVG";

const Generate8020Internship = ({ reference }: { reference: string }) => {
  const [stack, setStack] = useState<Stack>("python");
  const [creatingRoadmap, setCreatingRoadmap] = useState(false);

  const { triggerSearchRoadmaps } = useLearnContext();

  const handleChange = (event: any) => {
    setStack(event.target.value);
  };

  const generateInternshipRoadmap = () => {
    console.log("🚀 ~ Generate8020Internship ~ stack:", stack);

    if (stack) {
      setCreatingRoadmap(true);

      const prompt = `I am a software developer who wants to become proficient in AI. The learning needs to focus on four important core concepts divided into 4 weeks.

      Create atleast 4 sections for each week to cover the topics mentioned below.

      Week 1: Fundamentals of AI

      Core fundamental concepts of machine learning and deep learning. Create atleast 4 sections that covers all the topics below
      - Core Concepts: Introduction to AI, including supervised vs. unsupervised learning, regression, classification, and basics of neural networks.
      - Introduction to Machine Learning and Deep Learning: Explore key algorithms and their applications.
      - Introduction to Large Language Models (LLM)
      - Focus on AI and machine learning concepts needed to understand inner workings of Large Language Models.
      - History of Large Language Models


      Week 2: Building software using AI

      Focus on using AI as an assistance for developing software. Create atleast 4 sections that covers all the topics below
      - Prompt Engineering
      - Techniques and best practices for designing effective prompts for AI.
      - Using Github Copilot as a coding assistance.
      - Generating code using AI.
      - How AI is changing software development.

      Week 3: Building applications on top of Large Language Models (LLMs)

      Focus on building application on top of LLMs. Create atleast 4 sections that covers all the topics below

      - How Large Models can be used to buid applications.
      - How to integrate LLMs with existing software platforms.
      - Types of applications and use cases
      - Introduction to RAG (Retrieval-Augmented Generation): Detailed explanation and examples.
      - Discussion on the do’s and don’ts when working with LLMs.
      - Challenges and pitfalls of LLMs

      Week 4: LangChain framework

      Introduction to LangChain framework. Create atleast 4 sections that covers all the topics below.

      - Introduction to LangChain Framework: Overview and core concepts (Agents, Chains, Tools, Prompts) specific to ${stack} (or replace with the actual stack being used).
      - Step-by-step guide to developing an application using LangChain and ${stack}.
      - Building chatbots using LangChain and ${stack}
      - Q&A with RAG using LangChain and ${stack}
      - Develop a mini-project using LangChain and ${stack} to solve a real problem
      `;

      triggerSearchRoadmaps(prompt, "internship");
    }
  };

  return (
    <StyledWrap>
      <StyledContent>
        <TopContent>
          <Typography component="h3">Tublian AI Internship {reference ? "(Only for " + reference + " members)" : ""}</Typography>
          <Typography color="#CFCFCF">
            Start your internship by generating the AI internship roadmap
          </Typography>

          <CustomButton
            type="tertiary"
            style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
            postImg={<StyledIcon>{arrowRightSvg}</StyledIcon>}
            onClick={() =>
              window.open(
                "https://docs.google.com/document/d/1yHmhoY7N_RFXOOZVyW7mHh_CqsJ-v_nFomhwpNk_Rkw/edit?usp=sharing",
                "_blank"
              )
            }
          >
            AI Internship Guidline
          </CustomButton>

          <Typography
            color="#FEFEFE"
            sx={{
              fontSize: "0.875rem",
              lineHeight: "1.5rem",
              marginTop: "8px",
            }}
          >
            Choose Your Preferred Programming Language:
          </Typography>
          <StyledSelect
            defaultValue="python"
            sx={{ border: "none !important" }}
            value={stack}
            // IconComponent={<KeyboardArrowDownOutlined />}
            onChange={handleChange}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: "#292929",
                  border: "1px solid #414141",
                  marginTop: "8px",
                  padding: "4px",
                  "& .MuiMenuItem-root": {
                    marginBottom: "4px",
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    padding: "2px 4px",
                  },
                  "& .MuiMenuItem-root:last-child": {
                    marginBottom: 0,
                  },
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: "#414141",
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "#414141",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "#414141",
                  },
                },
              },
            }}
          >
            <MenuItem value="python">Python</MenuItem>
            <MenuItem value="javascript">JavaScript</MenuItem>
          </StyledSelect>
        </TopContent>

        {creatingRoadmap ? (
          <Spinner size={18} pryColor="#FEFEFE" secColor="#121212" />
        ) : (
          <CustomButton
            size="medium"
            type="grey"
            postImg={eightyTwentyIcon}
            onClick={generateInternshipRoadmap}
          >
            Generate Roadmap
          </CustomButton>
        )}
      </StyledContent>
      <StyledImage src={internshipMap} alt="Tublian Internship" />
    </StyledWrap>
  );
};

export default Generate8020Internship;

type Stack = "python" | "javascript";

const StyledWrap = styled(Card)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#1E1E1E",
  width: "100%",
  borderRadius: "15px",
  padding: 0,
  "& h3": {
    fontWeight: 700,
    fontSize: "1.125rem",
    lineHeight: "1.875rem",
    color: "#FEFEFE",
  },
  "& p": {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  [theme.breakpoints.down(768)]: {
    flexDirection: "column",
  },
}));

const StyledContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "20px",
  padding: "16px",
});

const TopContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "6px",
});

const StyledImage = styled("img")(({ theme }) => ({
  height: "auto",
  display: "flex",
  alignSelf: "flex-end",
  [theme.breakpoints.down(768)]: {
    alignSelf: "center",
  },
  [theme.breakpoints.down(390)]: {
    width: "100%",
  },
}));

const StyledSelect = styled(Select)`
  width: 116px;
  border-radius: 5px;
  padding: 8px 0 8px 12px;
  gap: 10px;
  background: #292929;
  color: #fefefe;

  & .MuiSelect-select {
    padding: 0;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #fefefe;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border: 1px solid #414141 !important;
  }
`;

const StyledIcon = styled(Icon)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "18px",
  height: "18px",
  "& svg": {
    width: "18px",
    height: "18px",
  },
});
