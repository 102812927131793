import { Button, styled } from "@mui/material";
import { ICustomButtonProps, getSizeStyles } from "./CustomBtnResources";

const GreyButton = ({
  disabled,
  children,
  onClick,
  style,
  size = "large",
  nativeType,
  type = "grey",
  loading = false,
  loadingText = "Loading...",
  loadingSpinner,
  preImg,
  postImg,
}: ICustomButtonProps) => {
  const GreyButton = styled(Button)`
    && {
      font-weight: 500;
      padding: ${getSizeStyles(size, type).padding};
      font-size: ${getSizeStyles(size, type).fontSize};
      line-height: ${getSizeStyles(size, type).lineHeight};
      width: auto;
      background-color: #292929;
      border-radius: 30px;
      color: #fefefe;
      &:hover {
        background-color: #414141;
      }
      /* 
      &:active {
        background-color: #302c19;
        border: 1px solid #feefc3;
        color: #feefc3;
      }
      */
      &:disabled {
        background-color: #292929 !important;
        color: #696969;
      }
    }
  `;

  return (
    <GreyButton
      type={nativeType}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {!loading && preImg && typeof preImg === "string" ? (
        <img
          src={preImg}
          alt="preImg"
          style={{ display: "flex", marginRight: 8 }}
          width={size === "large" || type === "onBoarding" ? 24 : 18}
          height={size === "large" || type === "onBoarding" ? 24 : 18}
        />
      ) : (
        !loading &&
        preImg && (
          <div style={{ display: "flex", marginRight: 8 }}>{preImg}</div>
        )
      )}
      {!loading ? children : loadingSpinner || loadingText}
      {!loading && postImg && typeof postImg === "string" ? (
        <img
          src={postImg}
          style={{ display: "flex", marginLeft: 8 }}
          alt="postImg"
          width={size === "large" ? 24 : 18}
          height={size === "large" ? 24 : 18}
        />
      ) : (
        !loading &&
        postImg && (
          <div style={{ display: "flex", marginLeft: 8 }}>{postImg}</div>
        )
      )}
    </GreyButton>
  );
};

export default GreyButton;
