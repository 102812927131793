import { FC, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, styled } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";

import { useLearnContext } from "./Course/LearnContext";
import CustomLoader from "../Common/CustomLoader";
import SharedRoadmapPhasesList from "./SharedRoadmapPhasesList";
import CustomButton from "../Common/CustomButton";
import OverlayModal from "../Common/OverlayModal";
import StatusNotification from "../Common/StatusNotification";
import EmptyRoadmapState from "./EmptyRoadmapState";
import FeedBack from "../Common/FeedBack";
import BannerCard from "./BannerCard";
import { logEvent } from "../../libs/amplitude";
import { userProfile } from "../../libs/UserProfile";
import LearningRoadmapEndpoint from "../../libs/LearningRoadmapEndpoint";
import helperFunctions from "../../libs/helperFunctions";

const ShareRoadmapView: FC<IProps> = ({ isPublic, roadmapOwner }) => {
  const history = useHistory();
  const { reencodeRoadmapId } = helperFunctions;

  let { userId, roadmapId } = useParams<{
    userId: string;
    roadmapId: string;
  }>();

  if (roadmapOwner && !userId) {
    userId = roadmapOwner;
  }

  roadmapId = reencodeRoadmapId(roadmapId) || "";

  const {
    error,
    roadmapList,
    loadingState,
    popupStateData,
    setError,
    setRoadmapList,
    updateLoadingState,
    setCredits,
    getCreditStatus,
    updatePopupStateData,
    setPopupStateData,
  } = useLearnContext();

  const showRoadmaps =
    !loadingState.loadingRoadmaps &&
    !loadingState.deletingRoadmap &&
    roadmapList?.length > 0;

  const handleBack = () => {
    if (window.history.length > 1) {
      // history.goBack();
      history.push("/learn");
    }
  };

  const handleTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updatePopupStateData("feedbackData", {
      ...popupStateData.feedbackData,
      feedbackMsg: e.target.value,
    });
  };

  const handleFeedback = () => {
    // console.log("data", popupStateData);
    const { feedbackData, course } = popupStateData;
    const { reaction, feedbackMsg } = feedbackData;

    const user = isPublic ? "public" : userProfile(localStorage).userId();

    logEvent("8020", user, {
      action: "feedback",
      reaction, // liked or disliked
      feedbackMsg, // feedback message
      course, // phase liked
    });

    setPopupStateData({
      isActive: false,
      feedbackData: {},
      popupMode: "",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (roadmapId?.length > 0 && userId) {
          updateLoadingState("loadingRoadmaps", true);

          const res = await LearningRoadmapEndpoint.getRoadmap(
            roadmapId,
            userId
          );
          // console.log("🚀 ~ fetchData ~ res:", res);
          setRoadmapList([res]);
          updateLoadingState("loadingRoadmaps", false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError({
          error: true,
          errorMsg:
            "Error encountered while loading roadmap, please try again.",
        });
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [roadmapId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getCreditStatus();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (isPublic) {
      setCredits(0);
    } else {
      fetchData();
    }

    // eslint-disable-next-line
  }, [isPublic]);

  return (
    <PrimaryColumn>
      {!isPublic && (
        <BackBox>
          <CustomButton size="small" type="tertiary" onClick={handleBack}>
            <ArrowBackOutlined />
            Back
          </CustomButton>
        </BackBox>
      )}
      <OverlayModal isPreviewOpen={popupStateData.isActive || error.error}>
        {error.error && (
          <StatusNotification
            status="error"
            statusMessage={error.errorMsg}
            cancelBtnName="Close"
            cancelBtnHandleClick={() =>
              setError({ error: false, errorMsg: "" })
            }
          />
        )}
        {popupStateData.popupMode === "feedback" && (
          <FeedBack
            title="Additional Feedback"
            inputPlaceholder={`What do you like about this phase?`}
            textareaValue={popupStateData?.feedbackData?.feedbackMsg || ""}
            cancelBtnName="Close"
            proceedBtnName="Send Feedback"
            textareaHandler={handleTextarea}
            cancelBtnHandleClick={handleFeedback}
            proceedBtnHandleClick={handleFeedback}
          />
        )}
      </OverlayModal>
      <HeaderWrap>
        <BannerCard
          type="learning"
          beta={false}
          title="8020: Fastest Way to Learn Anything"
          paragraphs={[
            "An AI-powered personalized learning, tailored uniquely to your passions, existing knowledge, and skills for a journey that aligns perfectly with your individual goals.",
            "We prioritize the crucial 20% that delivers 80% of practical outcomes, combining relevant content and hands-on experience via Open Source projects and AI-guided questions.",
            "10x YOUR SKILLS.",
          ]}
        />
      </HeaderWrap>
      <MainContent>
        <Box>
          {showRoadmaps && (
            <RoadmapListWrap sx={{ marginBottom: "20px" }}>
              <SharedRoadmapPhasesList
                key={roadmapList.length}
                roadmapIndex={0}
                collapsed={false}
                roadmapList={{ ...roadmapList[0] }}
                userId={roadmapOwner}
                viewOnly={isPublic}
                parentComponent="roadmapView"
                isPublic={isPublic}
              />
            </RoadmapListWrap>
          )}
          <EmptyRoadmapState />
          {loadingState.loadingRoadmaps && (
            <LoaderWrap>
              <CustomLoader
                primaryMsg="Getting Your Roadmap..."
                secondaryMsg="This might take a while"
              />
            </LoaderWrap>
          )}
        </Box>
      </MainContent>
    </PrimaryColumn>
  );
};

export default ShareRoadmapView;

interface IProps {
  isPublic: boolean;
  roadmapOwner: string;
}

const HeaderWrap = styled(Box)(({ theme }) => ({
  margin: "0 0 28px",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down(700)]: {
    margin: "auto",
    marginBottom: 20,
    gap: 20,
    width: "100%",
    alignItems: "center",
  },
}));

const PrimaryColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // margin: "0 auto",
  marginBottom: "16px",
});

const MainContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  "&>div": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down(860)]: {
      width: "100%",
    },
    [theme.breakpoints.down(736)]: {
      width: "100%",
    },
  },
  "& .creditStatWrap": {
    margin: "8px 0 12px",
  },
}));

const LoaderWrap = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const RoadmapListWrap = styled(Box)(({ theme }) => ({
  border: "1px solid #1E1E1E",
  borderRadius: "20px",
  width: "100%",
  [theme.breakpoints.down(700)]: {
    // border: "unset",
  },
}));

const BackBox = styled(Box)({
  width: "100%",
  justifySelf: "flex-start",
  marginBottom: "28px",
  "& button": {
    paddingLeft: "0px !important",
  },
});
