const animationConfig = {
  initial: { opacity: 0, y: 10 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1,
      ease: "easeIn",
    },
  },
  initial2: { x: -300, opacity: 0 },
  animate2: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 60,
      opacity: { duration: 1 },
      duration: 1,
    },
  },
  initial3: { y: 50, opacity: 0 },
  animate3: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 60,
      opacity: { duration: 1 },
      duration: 1,
    },
  },
  animate6: {
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 60,
      opacity: { duration: 1 },
      duration: 1,
    },
  },
  initial6: { opacity: 0 },
  delays: [0.2, 0.5, 0.8],
  xDirectionStart: [-0, 0, -0, 0, -0],
  xDirectionEnd: ["100vw", "-100vw", "100vw", "-100vw", "100vw"],
  initial4: { x: 200, opacity: 0 },
};

export default animationConfig;
