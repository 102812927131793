import { FC } from "react";
import { Link } from "react-router-dom";
import { styled, useMediaQuery, Box, Icon, Typography } from "@mui/material";
import { ArrowRightAltOutlined } from "@mui/icons-material";
import { saveUserProjectIssueEvent } from "../../../Common/Analytics/UserEventsAPI";
import { ProjectsCardProps } from "../../types";
import RepoStats from "./RepoStats";
import ProjectsCardContent from "./ProjectsCardContent";
import { useProjectsContext } from "../../ProjectsContext";
import CustomButton from "../../../Common/CustomButton";
import { userProfile } from "../../../../libs/UserProfile";
import { projectsSvg } from "../../../UserHome/Base/SVG";

const SimpleIssueCard = ({
  repoName,
  issue,
  score,
  projectRepoLink,
  issueLink,
  onClick,
}: {
  repoName: string;
  issue: string | undefined;
  score: number | undefined;
  projectRepoLink: string;
  issueLink: string;
  onClick: () => Promise<void>;
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="4px">
      <IssueDetails>
        Repo:
        <Link
          onClick={onClick}
          to={{ pathname: projectRepoLink }}
          target="_self"
        >
          {repoName}
        </Link>
      </IssueDetails>
      <IssueDetails>
        Issue:
        <Link to={{ pathname: issueLink }} target="_self">
          {issue}
        </Link>
      </IssueDetails>
      <IssueDetails>
        Est. Score:
        <span>{score}</span>
      </IssueDetails>
    </Box>
  );
};

const ProjectsCard: FC<ProjectsCardProps> = ({
  type,
  language,
  repoId,
  issueId,
  repoName,
  avatarUrl,
  issueLink,
  repoDescription,
  githubUrl,
  issue,
  score,
  beginnerFriendly,
  commitRate,
  growthRate,
  health,
  projectRepoLink,
  status,
  styleOverrides,
  openLinksExternally = type === "recent_projects",
}) => {
  const isMobile = useMediaQuery("(max-width: 700px)");

  const projectDetails = {
    languages: language,
    id: repoId,
    name: repoName,
    fullName: repoName,
    description: repoDescription,
    weeklyActivity: commitRate,
    beginnerFriendly,
    avatarUrl,
    growthRate,
    health,
    url: githubUrl,
  };
  const { setSelectedProject } = useProjectsContext();

  const isProjectView = (type: string) =>
    type === "project_view" || type === "recent_projects";

  const onClick = async () => {
    setSelectedProject(projectDetails);
    if (issueId && issue) {
      await saveUserProjectIssueEvent(issueId, repoId, issue, issueLink);
    }
  };

  const DescriptiveTags = ({
    langs,
    isEasy,
  }: {
    langs: string[];
    isEasy?: boolean;
  }) => {
    const userLangs = userProfile(localStorage)?.selectedLanguages();
    const commonLangs = (langs || []).filter(
      (lang) =>
        userLangs?.includes(lang?.toLowerCase()) || lang === "Tublian Pick"
    );

    let tags: string[] = [];
    if (commonLangs?.length > 0) {
      tags = commonLangs.slice(0, isMobile ? 1 : 2);
    } else {
      tags = (langs || []).slice(0, isMobile ? 1 : 2);
    }

    return langs?.length > 0 && langs[0] !== "" ? (
      <TagWrap>
        {isEasy && <EasyTag>Beginner Friendly</EasyTag>}
        {tags?.map((lang, i) => (
          <StyledTag key={i} lang={lang}>
            {lang}
          </StyledTag>
        ))}
      </TagWrap>
    ) : null;
  };

  if (type === "simple_issue_view") {
    return (
      <SimpleIssueCard
        repoName={repoName}
        issue={issue}
        score={score}
        projectRepoLink={projectRepoLink}
        issueLink={issueLink}
        onClick={onClick}
      />
    );
  }

  return (
    <StyledWrapper style={styleOverrides}>
      <Box padding="20px">
        <HeaderWrap>
          <HeaderRow1>
            <Box className="descItems">
              {type === "pathway_task" ? null : avatarUrl &&
                avatarUrl?.length > 0 ? (
                <StyledIconImg src={avatarUrl} alt="project avatar" />
              ) : (
                <StyledIcon>{projectsSvg}</StyledIcon>
              )}

              <DescriptiveTags langs={language} isEasy={beginnerFriendly} />
            </Box>

            <BoxNotOnMobile>
              <BtnTransparent
                onClick={onClick}
                to={{ pathname: issueLink }}
                target={openLinksExternally ? "_blank" : "_self"}
              >
                {isProjectView(type) ? "View Issues" : "View issue"}
                <ArrowRightAltOutlined />
              </BtnTransparent>
            </BoxNotOnMobile>
          </HeaderRow1>
        </HeaderWrap>

        <ContentWrap>
          <ProjectsCardContent
            type={type}
            issueLink={issueLink}
            projectRepoLink={projectRepoLink}
            repoName={repoName}
            issue={issue}
            repoDescription={repoDescription}
            score={score}
            handleProjectRepoLink={onClick}
            openLinksExternally={openLinksExternally}
          />
        </ContentWrap>

        {type !== "recent_projects" && (
          <FooterWrap>
            <RepoStats
              commitRate={commitRate}
              growthRate={growthRate}
              health={health}
            />
          </FooterWrap>
        )}

        <ViewBtnMobile>
          <Link
            to={{ pathname: issueLink }}
            target={openLinksExternally ? "_blank" : "_self"}
          >
            <CustomButton type="primary" size="medium">
              {isProjectView(type) ? "View Issues" : "View issue"}
            </CustomButton>
          </Link>
        </ViewBtnMobile>
      </Box>
    </StyledWrapper>
  );
};

export default ProjectsCard;

const StyledWrapper = styled(Box)((props) => ({
  width: "100%",
  borderRadius: 20,
  backgroundColor: "#1E1E1E !important",
  "@media (max-width: 700px)": {
    width: "100%",
    minWidth: "unset",
    maxWidth: "unset",
  },
}));

const TagWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 12,
  [theme.breakpoints.down(700)]: {
    marginBottom: 4,
    "& *": {
      fontSize: "0.75rem",
    },
  },
}));

const ViewBtnMobile = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
  "& button": {
    width: "100% !important",
  },
  marginTop: 20,
  [theme.breakpoints.up(700)]: {
    display: "none",
  },
}));

const FooterWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: 16,
  [theme.breakpoints.down(700)]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const ContentWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "& a": {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: "#FEFEFE",
    },
  },
}));

const HeaderWrap = styled(Box)(({ theme }) => ({
  marginBottom: 16,
  [theme.breakpoints.down(700)]: {
    marginBottom: 12,
  },
}));

const HeaderRow1 = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down(700)]: {
    alignItems: "flex-start",
  },
  "& .descItems": {
    display: "flex",
    alignItems: "center",
    gap: 12,
  },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: "#301E21",
  color: "#F43E5C",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 10,
  [theme.breakpoints.down(700)]: {
    width: 30,
    height: 30,
  },
}));

const StyledIconImg = styled("img")(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: "#301E21",
  color: "#F43E5C",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 10,
  padding: 8,
  [theme.breakpoints.down(700)]: {
    width: 30,
    height: 30,
  },
}));

const BtnTransparent = styled(Link)({
  textDecoration: "none",
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1.25,
  color: "#FDD649",
  display: "flex",
  alignItems: "center",
  gap: 8,
  "&:hover": {
    color: "#FEE38B",
  },
});

const BoxNotOnMobile = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
  [theme.breakpoints.down(700)]: {
    display: "none",
  },
}));

const EasyTag = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1.25,
  padding: "4px 8px",
  color: "#2DB7F8",
  textAlign: "center",
  border: "1px solid #2DB7F8",
  borderRadius: 20,
  textTransform: "capitalize",
});

const StyledTag = styled(Typography)<{ lang: string }>(({ theme, lang }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "20px",
  padding: "4px 8px",
  borderRadius: 20,
  textTransform: "capitalize",
  color: lang === "Tublian Pick" ? "#00806C" : "#FEFEFE",
  border: lang === "Tublian Pick" ? "1px solid #00806C" : "1px solid #696969",
}));

const IssueDetails = styled(Typography)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  fontSize: "1rem",
  fontWeight: 700,
  lineHeight: "1.5rem",
  color: "#FEFEFE",
  "& span": {
    fontWeight: 400,
  },
  "& a": {
    fontWeight: 400,
    // color: "#FEFEFE",
  },
}));
