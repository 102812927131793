import { useState } from "react";
import {
  Box,
  ClickAwayListener,
  Popper,
  Typography,
  styled,
} from "@mui/material";
import { LinkOutlined } from "@mui/icons-material";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import linkedinIcon from "../../../images/landing/linkedin.png";
import { exportBtnSvg } from "../../UserHome/Base/SVG";
import XIcon from "../../../images/XshareLogo.svg";

const CustomShareBtn = ({
  url,
  title,
  description,
}: {
  url: string;
  title?: string;
  description?: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickExport = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleShare = (type: string) => {
    const urlToShare = encodeURIComponent(url.replaceAll(" ", "%20"));
    let shareUrl;

    const text = encodeURIComponent(
      `${title?.slice(0, 140)}${(title || "").length > 140 ? "..." : ""} ${
        description ? ": " + description : ""
      }`
    );

    if (type === "twitter") {
      shareUrl = `https://twitter.com/intent/tweet?text=${text}&url=${urlToShare}`;
    }

    if (type === "linkedin") {
      shareUrl = `https://www.linkedin.com/sharing/share-offsite/?text=${text}&url=${urlToShare}`;
    }
    window.open(shareUrl, "_blank");
  };

  const handleCopy = () => {
    handleClickAway();
    toast.success("🎉 Successfully copied share link", {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
      progress: undefined,
    });
  };

  return (
    <Box
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      <ExportBtn id="exportBtn" onClick={handleClickExport}>
        {exportBtnSvg}
      </ExportBtn>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom"
        style={{
          zIndex: 9,
        }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 13],
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              rootBoundary: "viewport",
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <DropdownMenu id="export-panel">
            <Box>
              <img src={XIcon} alt="X icon" className="sharePanelIcon" />
              <Typography onClick={() => handleShare("twitter")}>
                Twitter
              </Typography>
            </Box>
            <Box>
              <img
                src={linkedinIcon}
                alt="linkedin icon"
                className="sharePanelIcon"
              />
              <Typography onClick={() => handleShare("linkedin")}>
                Linkedin
              </Typography>
            </Box>
            <CopyToClipboard onCopy={handleCopy} text={encodeURI(url)}>
              <Box>
                <LinkOutlined htmlColor="#888" className="sharePanelIcon" />
                <Typography>Copy Link</Typography>{" "}
              </Box>
            </CopyToClipboard>
          </DropdownMenu>
        </ClickAwayListener>
      </Popper>
      {/* )} */}
    </Box>
  );
};

export default CustomShareBtn;

const ExportBtn = styled(Box)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  color: "#FDD649",
  width: 20,
  height: 20,
  "& svg": {
    width: 20,
    height: 20,
  },
  "&:hover": {
    backgroundColor: "#696969",
  },
});

const DropdownMenu = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start !important",
  border: "1px solid #414141",
  backgroundColor: "#292929",
  zIndex: 19,
  borderRadius: 20,
  gap: "8px !important",
  padding: "16px 10px",
  width: 172,
  "& div": {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: 8,
    width: 152,
    padding: "4px 11.5px",
    "&:hover": {
      backgroundColor: "#414141",
    },
  },
  "& p": {
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    color: "#FEFEFE",
  },
  "& .sharePanelIcon": {
    width: 18,
    height: 18,
  },
});
