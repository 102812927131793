import { Route, Switch, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import OnboardingThemeProvider from "./themes/onboarding-theme";
import { useAppContext } from "./libs/contextLib";
import LandingPage from "./containers/LandingPage";
import Challenge from "./components/Feed/PersonalizedChallenge";
import Learning from "./components/Feed/PersonalizedLearning";
import ReAuthentication from "./components/Onboarding/ReAuthentication";
import TublianOs from "./components/Landing/TublianOs/TublianOs";

const NotFound = loadable(() => import("./components/NotFound"));
const NoPermission = loadable(() => import("./components/NoPermission"));
const PersonalizedPosts = loadable(() =>
  import("./components/Feed/PersonalizedPosts")
);
const PersonalizedQuestions = loadable(() =>
  import("./components/Feed/PersonalizedQuestions")
);
const PersonalizedPlayground = loadable(() =>
  import("./components/Feed/PersonalizedPlayground")
);
const PersonalizedNotifications = loadable(() =>
  import("./components/Feed/PersonalizedNotifications")
);
const PersonalizedProjects = loadable(() =>
  import("./components/Feed/PersonalizedProjects")
);
const GithubFirstContribution = loadable(() =>
  import("./components/Feed/FirstGithubContributionPresentation")
);
const OpenSourcePathway = loadable(() =>
  import("./components/Feed/OpenSourcePathway")
);

const AuthenticatedRoute = loadable(() =>
  import("./components/AuthenticatedRoute")
);
const Welcome = loadable(() => import("./components/Onboarding/Welcome"));
const OnboardingAuthorization = loadable(() =>
  import("./components/Onboarding/Authorization/OnboardingAuthorization")
);
const CustomerSignUp = loadable(() =>
  import("./components/CustomerOnboarding/CustomerSignUp")
);
const SignUp = loadable(() => import("./components/Onboarding/SignUp"));
const Profile = loadable(() => import("./components/UserProfile/Profile"));
const EditProfile = loadable(() =>
  import("./components/UserProfile/EditProfile/Index")
);
const Home = loadable(() => import("./components/Home/Home"));
const UserHome = loadable(() => import("./components/UserHome/UserHome"));

const PersonalizedTublibotExplorer = loadable(() =>
  import("./components/Feed/PersonalizedTublibotExplorer.tsx")
);

const Privacy = loadable(() =>
  import("./components/Legal/PrivacyPresentation")
);
const Terms = loadable(() => import("./components/Legal/TermsPresentation"));
const Manifesto = loadable(() =>
  import("./components/Landing/manifesto/ManifestoPresentation")
);
const Hiring = loadable(() =>
  import("./components/Landing/CompanyPage/CompanyPage")
);
const BeACreator = loadable(() =>
  import("./components/Landing/BeACreator/BeACreator")
);
const DiscoverOpps = loadable(() =>
  import("./components/Landing/DiscoverOpps/DiscoverOpps")
);
const Streetcred = loadable(() =>
  import("./components/Landing/Streetcred/Streetcred")
);
const OpenSourceInternship = loadable(() =>
  import("./components/Landing/OpenSourceInternship")
);
const AIInternship = loadable(() =>
  import("./components/Landing/AIInternship")
);
const EightyTwenty = loadable(() =>
  import("./components/Landing/EightyTwenty/EightyTwenty")
);
const CustomerPage = loadable(() =>
  import("./components/Landing/CustomerPage")
);
const Admin = loadable(() => import("./containers/Admin"));
const DiscordAuthorization = loadable(() =>
  import("./components/Feed/DiscordAuthorization")
);

export default function Routes() {
  const location = useLocation();
  const { isAuthenticated } = useAppContext();
  return (
    <Switch>
      {ReactGA.pageview(location.pathname + location.search)}
      <Route exact path="/">
        <LandingPage />
      </Route>
      <Route exact path="/privacy">
        <Privacy />
      </Route>
      <Route exact path="/terms">
        <Terms />
      </Route>
      <OnboardingRoute exact path="/login">
        <Home />
      </OnboardingRoute>
      <OnboardingRoute exact path="/manifesto">
        <Manifesto />
      </OnboardingRoute>
      <OnboardingRoute exact path="/hiring">
        <Hiring />
      </OnboardingRoute>
      <OnboardingRoute exact path="/become-a-creator">
        <BeACreator />
      </OnboardingRoute>
      <OnboardingRoute exact path="/discover-opportunities">
        <DiscoverOpps />
      </OnboardingRoute>
      <OnboardingRoute exact path="/streetcred">
        <Streetcred />
      </OnboardingRoute>
      <OnboardingRoute exact path="/internship">
        <OpenSourceInternship />
      </OnboardingRoute>
      <OnboardingRoute exact path="/ai-internship">
        <AIInternship />
      </OnboardingRoute>
      <OnboardingRoute exact path="/customers">
        <CustomerPage />
      </OnboardingRoute>
      <OnboardingRoute exact path="/welcome">
        <Home />
      </OnboardingRoute>
      <OnboardingRoute exact path="/8020">
        <EightyTwenty />
      </OnboardingRoute>
      <OnboardingRoute exact path="/tublian-os">
        <TublianOs />
      </OnboardingRoute>
      <OnboardingRoute exact path="/welcome/:code">
        <Welcome />
      </OnboardingRoute>
      <OnboardingRoute exact path="/auth/success/redirect">
        <OnboardingAuthorization />
      </OnboardingRoute>
      <OnboardingRoute exact path="/sign-up/customer">
        <CustomerSignUp />
      </OnboardingRoute>
      <OnboardingRoute exact path="/auth/success/redirect/sub-auth">
        <ReAuthentication />
      </OnboardingRoute>
      <OnboardingRoute exact path="/sign-up">
        <SignUp />
      </OnboardingRoute>
      <OnboardingRoute exact path="/new/account">
        <SignUp />
      </OnboardingRoute>
      <OnboardingRoute exact path="/404">
        <NotFound />
      </OnboardingRoute>
      <OnboardingRoute exact path="/person-not-found">
        <NotFound />
      </OnboardingRoute>
      <OnboardingRoute exact path="/no-permission">
        <NoPermission />
      </OnboardingRoute>
      <AuthenticatedRoute exact path="/feed">
        <PersonalizedPosts />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/questions">
        <PersonalizedQuestions type="questions" />
      </AuthenticatedRoute>
      <Route exact path="/questions/:questionId">
        <PersonalizedQuestions type="question_details" />
      </Route>
      <Route exact path="/playground">
        <PersonalizedPlayground view={isAuthenticated ? "private" : "public"} />
      </Route>
      <Route exact path="/challenge">
        <Challenge
          view={isAuthenticated ? "private" : "public"}
          type="challenge"
        />
      </Route>
      <Route exact path="/challenge/:challengeId">
        <Challenge
          view={isAuthenticated ? "private" : "public"}
          type="challenge_details"
        />
      </Route>

      {/* 8020 routes */}
      <AuthenticatedRoute exact path="/8020/credits/">
        <Learning
          type="buyCredits"
          view={isAuthenticated ? "private" : "public"}
        />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/8020/credits/history">
        <Learning
          type="eightyTwentyPaymentHistory"
          view={isAuthenticated ? "private" : "public"}
        />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/learn">
        <Learning
          type="roadmapListView"
          view={isAuthenticated ? "private" : "public"}
        />
      </AuthenticatedRoute>

      {/* <AuthenticatedRoute exact path="/learn/:userName/:roadmapId/">
        <Learning
          type="roadmapView"
          view={isAuthenticated ? "private" : "public"}
        />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/learn/:userName/:roadmapId/:courseId">
        <Learning
          type="phaseView"
          view={isAuthenticated ? "private" : "public"}
        />
      </AuthenticatedRoute> */}

      <Route exact path="/learn/:userName/:roadmapId/">
        <Learning
          type="roadmapView"
          view={isAuthenticated ? "private" : "public"}
        />
      </Route>
      <Route exact path="/learn/:userName/:roadmapId/:courseId">
        <Learning
          type="phaseView"
          view={isAuthenticated ? "private" : "public"}
        />
      </Route>

      {/* Project routes */}
      <Route exact path="/projects">
        <PersonalizedProjects
          type="projects"
          view={isAuthenticated ? "private" : "public"}
        />
      </Route>
      <Route exact path="/project/categories">
        <PersonalizedProjects
          type="project_categories"
          view={isAuthenticated ? "private" : "public"}
        />
      </Route>
      <Route exact path="/p/:userName/:repoName">
        <PersonalizedProjects
          type="project_issue_list_by_repo_name"
          view={isAuthenticated ? "private" : "public"}
        />
      </Route>
      <Route exact path="/project/:repoId">
        <PersonalizedProjects
          type="project_issue_list"
          view={isAuthenticated ? "private" : "public"}
        />
      </Route>
      <Route exact path="/project/:repoId/:issueId">
        <PersonalizedProjects
          type="project_issue"
          view={isAuthenticated ? "private" : "public"}
        />
      </Route>
      <Route exact path="/open-source/first">
        <GithubFirstContribution
          view={isAuthenticated ? "private" : "public"}
        />
      </Route>
      <Route exact path="/open-source/first/:id">
        <GithubFirstContribution
          view={isAuthenticated ? "private" : "public"}
        />
      </Route>
      <AuthenticatedRoute exact path="/open-source/pathway">
        <OpenSourcePathway />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/home">
        <UserHome
          view={isAuthenticated ? "private" : "public"}
          type="dashboard"
        />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/tublibot">
        <PersonalizedTublibotExplorer />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/home/streetcred">
        <UserHome
          view={isAuthenticated ? "private" : "public"}
          type="streetcredAnalysis"
        />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/notifications">
        <PersonalizedNotifications type="pair-request" />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/notifications/activities">
        <PersonalizedNotifications type="activities" />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/notifications/connections">
        <PersonalizedNotifications type="connections" />
      </AuthenticatedRoute>
      <Route exact path="/profile/:userId">
        <Profile view={isAuthenticated ? "private" : "public"} />
      </Route>
      <Route exact path="/profile/edit/:token">
        <EditProfile />
      </Route>
      <AuthenticatedRoute exact path="/auth/discord/success/redirect">
        <DiscordAuthorization />
      </AuthenticatedRoute>
      <AuthenticatedRoute requireAdmin exact path="/admin">
        <OnboardingThemeProvider>
          <Admin />
        </OnboardingThemeProvider>
      </AuthenticatedRoute>
      <Redirect to="/404" />
    </Switch>
  );
}

const OnboardingRoute = (props) => {
  const { children, ...other } = props;
  return (
    <Route {...other}>
      <OnboardingThemeProvider>{children}</OnboardingThemeProvider>
    </Route>
  );
};

// const InternshipRoute = ({ children, requireAdmin, ...rest }) => {
//   const { isAuthenticated, loading, user } = useAppContext();
//   const [hasAccessToInternship, setHasAccessToInternship] = useState(null);
//   const userCache = userProfile(localStorage);

//   useEffect(() => {
//     const checkAccessToInternship = async () => {
//       try {
//         const user = await FT.get(userCache.userId(), {});
//         setHasAccessToInternship(user.hasAccessToInternship);
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//         setHasAccessToInternship(false);
//       }
//     };

//     checkAccessToInternship();
//   }, []); // eslint-disable-line

//   if (hasAccessToInternship === null) {
//     return <Loading />;
//   }

//   return (
//     <Route {...rest}>
//       {hasAccessToInternship &&
//       isAuthenticated &&
//       (!requireAdmin || user?.isAdmin) ? (
//         children
//       ) : (
//         <>{loading ? <Loading /> : <Redirect to={"/home"} />}</>
//       )}
//     </Route>
//   );
// };
