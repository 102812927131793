import { FC, useMemo, useState } from "react";
import moment from "moment";
import { Box, Typography, styled } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { RoadmapList } from "../Common/LearningTypes";
import CustomButton from "../../Common/CustomButton";
import RoadmapCard from "./RoadmapCard";

const RenderCategory: FC<CategoryProps> = ({ categoryTitle, roadmaps }) => {
  const [roadmapLimit, setRoadmapLimit] = useState(2);

  const canShowMore = roadmapLimit < (roadmaps?.length || 0);

  const showMoreRoadmaps = () => {
    setRoadmapLimit(roadmapLimit + 2);
  };

  const showLessRoadmaps = () => {
    setRoadmapLimit(2);
  };

  const showMore = roadmapLimit < (roadmaps?.length || 0);

  if (!roadmaps || roadmaps.length === 0) return null;

  return (
    <CategoryContainer key={categoryTitle}>
      <CategoryTitle>{categoryTitle}</CategoryTitle>
      {roadmaps?.slice(0, roadmapLimit).map((roadmapItem) => {
        if (roadmapItem.roadmap.roadmapType === "godeeper") return <></>;
        return (
          <RoadmapCard key={roadmapItem.roadmapId} roadmap={roadmapItem} />
        );
      })}

      {canShowMore && (
        <CustomButton
          postImg={showMore ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          type="tertiary"
          size="medium"
          onClick={showMore ? showMoreRoadmaps : showLessRoadmaps}
        >
          {showMore ? "Show More Roadmaps" : "Show Less Roadmaps"}
        </CustomButton>
      )}
    </CategoryContainer>
  );
};

const RenderRoadmapCategories: FC<Props> = ({
  roadmapList,
}) => {
  const categorizedRoadmaps = useMemo(() => {
    const today = moment().startOf("day");
    const earlierThisMonth = moment().startOf("month");
    const pastYear = moment().subtract(1, "year");

    const todayRoadmaps: RoadmapList = [];
    const thisMonthRoadmaps: RoadmapList = [];
    const pastYearRoadmaps: RoadmapList = [];
    const overAYearAgoRoadmaps: RoadmapList = [];

    roadmapList.forEach((roadmap) => {
      const createdAt = moment(roadmap.createdAt);
      if (roadmap.roadmap.roadmapType === "godeeper") return;

      if (createdAt.isSameOrAfter(today, "day")) {
        todayRoadmaps.push(roadmap);
      } else if (createdAt.isSameOrAfter(earlierThisMonth, "day")) {
        thisMonthRoadmaps.push(roadmap);
      } else if (createdAt.isSameOrAfter(pastYear, "day")) {
        pastYearRoadmaps.push(roadmap);
      } else {
        overAYearAgoRoadmaps.push(roadmap);
      }
    });

    const categories = [
      { title: "Today", roadmaps: todayRoadmaps },
      {
        title: "Earlier this month",
        roadmaps: thisMonthRoadmaps,
      },
      { title: "Within the past year", roadmaps: pastYearRoadmaps },
      { title: "Over a year ago", roadmaps: overAYearAgoRoadmaps },
    ];

    return {
      categories,
      today: todayRoadmaps,
      earlierThisMonth: thisMonthRoadmaps,
      pastYear: pastYearRoadmaps,
      overAYearAgo: overAYearAgoRoadmaps,
    };
  }, [roadmapList]);

  const { categories } = categorizedRoadmaps;

  return (
    <>
      {categories?.map((category) => {
        return (
          <RenderCategory
            key={category.title}
            categoryTitle={category.title}
            roadmaps={category.roadmaps}
          />
        );
      })}
    </>
  );
};

export default RenderRoadmapCategories;

type Props = {
  roadmapList: RoadmapList;
};

type CategoryProps = {
  categoryTitle: string;
  roadmaps: RoadmapList;
};

const CategoryContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  paddingBottom: "16px",
  borderBottom: "1px solid #1E1E1E",
  "&:last-of-type": {
    borderBottom: "none",
  },
});

const CategoryTitle = styled(Typography)({
  color: "#FEFEFE",
  fontSize: "1rem",
  fontWeight: 700,
  lineHeight: "1.5rem",
});
