import { ThemeProvider, Theme } from '@mui/material/styles';
import React from 'react';

const typography = {
    h1: {
        fontFamily: [
            'Poppins',
            'sans-serif'
        ].join(','),
        fontWeight: 600
    },
    h2: {
        fontFamily: [
            'Poppins',
            'sans-serif'
        ].join(','),
        fontWeight: 600,
        fontSize: '4.5rem'
    },
    h3: {
        fontFamily: [
            'Poppins',
            'sans-serif'
        ].join(','),
        fontWeight: 600
    },
    h4: {
        fontFamily: [
            'Poppins',
            'sans-serif'
        ].join(','),
        fontWeight: 600
    },
    h5: {
        fontFamily: [
            'Poppins',
            'sans-serif'
        ].join(','),
        fontWeight: 600,
        fontSize: '1.375rem'
    },
    h6: {
        fontFamily: [
            'Poppins',
            'sans-serif'
        ].join(','),
        fontWeight: 600
    },
    subtitle1: {
        fontFamily: [
            'Poppins',
            'sans-serif'
        ].join(','),
        fontWeight: 600,
        fontSize: '1rem'
    },
}

export default function Provider({children}: {children?: React.ReactNode}) {
    return (
    <ThemeProvider theme={(outerTheme: Theme) => {
        outerTheme.typography.h1 = {...outerTheme.typography.h1, ...typography.h1};
        outerTheme.typography.h2 = {...outerTheme.typography.h2, ...typography.h2};
        outerTheme.typography.h3 = {...outerTheme.typography.h3, ...typography.h3};
        outerTheme.typography.h4 = {...outerTheme.typography.h4, ...typography.h4};
        outerTheme.typography.h5 = {...outerTheme.typography.h5, ...typography.h5};
        outerTheme.typography.h6 = {...outerTheme.typography.h6, ...typography.h6};
        outerTheme.typography.subtitle1 = {...outerTheme.typography.h1, ...typography.subtitle1};
        return outerTheme;
    }} >
        {children}
    </ThemeProvider>
    )
}