import { Box, styled } from "@mui/material";
import ChallengePresentationCard from "./ChallengePresentationCard";
import { activeChallenges } from "./ChallengePresentationData";

const ActiveChallenges = () => {
  return (
    <StyledWrap>
      {activeChallenges.map((challenge, index) => (
        <ChallengePresentationCard
          key={index}
          chalName={challenge.chalName}
          description={challenge.description}
          endDate={challenge.endDate}
          id={challenge.id}
          imageUrl={challenge.imageUrl}
        />
      ))}
    </StyledWrap>
  );
};

export default ActiveChallenges;

const StyledWrap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});
