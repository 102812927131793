import { useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Box,
  ClickAwayListener,
  Popper,
  Typography,
  styled,
} from "@mui/material";
import { logEvent } from "../../libs/amplitude";
import { RefCode } from "../../libs/refCode";
import GithubClient from "../../libs/GithubClient";
import { githubSvg } from "../UserHome/Base/SVG";
import { HambergerMenu, ArrowUp2, ArrowDown2 } from "iconsax-react";
import { Close } from "@mui/icons-material";
import logo from "../../images/landing/logo_new.svg";
import logoDark from "../../images/logo_text_dark.svg";

const useStyles = makeStyles((theme) => ({
  responsiveNav: {
    transform: "none",
    zIndex: 9999,
  },
}));

const NewHeader = ({ pageName = "landing" }) => {
  const navRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const hoverRef = useRef<NodeJS.Timeout | null>(null);

  const location = useLocation();

  const signInCode = RefCode.code();

  const handleGithubSignIn = () => {
    logEvent("Login", "public-view", {
      url: location.pathname,
      source: pageName,
    });
    GithubClient.signIn(signInCode);
    return false;
  };

  const letsChat = () => {
    window.open("https://zcal.co/tublian/tos", "_blank");
    return false;
  };

  const LoginBtn = ({ pagename = "landing" }) => {
    if (pagename === "customers") {
      return (
        <StyledLoginBtn pagename={pagename} onClick={letsChat}>
          Let's talk
        </StyledLoginBtn>
      );
    }

    return (
      <StyledLoginBtn pagename={pagename} onClick={handleGithubSignIn}>
        {githubSvg} Login
      </StyledLoginBtn>
    );
  };

  const handleMouseEnter = (event: any) => {
    if (hoverRef.current) {
      clearTimeout(hoverRef.current);
      hoverRef.current = null;
    }
    logHeaderEvents("Developers' dropdown");
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    if (hoverRef.current) {
      clearTimeout(hoverRef.current);
    }
    hoverRef.current = setTimeout(() => {
      setAnchorEl(null);
    }, 500); // Delay can be adjusted as needed
  };

  const logHeaderEvents = (pageName: string) => {
    logEvent(pageName, "public-view", {
      sourceComponent: "landingPageHeader",
    });
  };

  const showNavbar = () => {
    if (navRef.current) {
      (navRef.current as any).classList.toggle(classes.responsiveNav);
    }
  };

  const classes = useStyles();

  return (
    <Header>
      <a href="/">
        <Logo
          alt="Tublian Logo"
          src={pageName === "customers" ? logoDark : logo}
        />
      </a>
      <LinkBox ref={navRef}>
        <DevDropdown
          pagename={pageName}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Developers
          {anchorEl ? <ArrowUp2 size={18} /> : <ArrowDown2 size={18} />}
        </DevDropdown>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom"
          style={{
            zIndex: 1001,
          }}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 6],
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                rootBoundary: "viewport",
              },
            },
          ]}
        >
          <ClickAwayListener onClickAway={handleMouseLeave}>
            <DropdownMenu
              id="export-panel"
              onMouseEnter={() => {
                if (hoverRef.current) {
                  clearTimeout(hoverRef.current);
                  hoverRef.current = null;
                }
              }}
              onMouseLeave={handleMouseLeave}
              pagename={pageName}
            >
              <NavLink
                to="/become-a-creator"
                onClick={() => logHeaderEvents("Become a Creator")}
                style={{
                  borderRadius: "10px 10px 0 0",
                  // background:
                  //   pageName === "creators" ? "#303136" : "transparent",
                }}
              >
                <Typography component="h6">Become a Creator</Typography>
                <Typography>Every developer has a digital story</Typography>
              </NavLink>
              <NavLink
                to="/discover-opportunities"
                onClick={() => logHeaderEvents("Discover Opportunities")}
                style={{
                  borderRadius: 0,
                  // background:
                  //   pageName === "opportunities" ? "#303136" : "transparent",
                }}
              >
                <Typography component="h6">Discover Opportunities</Typography>
                <Typography>Where ambition ignites opportunities</Typography>
              </NavLink>
              <NavLink
                to="/tublian-os"
                onClick={() => logHeaderEvents("Tublian OS")}
                style={{
                  borderRadius: "0 0 10px 10px",
                  // background:
                  //   pageName === "tublianOS" ? "#303136" : "transparent",
                }}
              >
                <Typography component="h6">Tublian OS</Typography>
                <Typography>Unleash your creativity</Typography>
              </NavLink>
            </DropdownMenu>
          </ClickAwayListener>
        </Popper>

        <Links
          to="/customers"
          pagename={pageName}
          onClick={() => logHeaderEvents("discover-opportunities-page")}
        >
          Customers
        </Links>

        <CloseBtn onClick={showNavbar} />
      </LinkBox>

      <Column2>
        <LoginBtn pagename={pageName} />
        <MenuBtn pagename={pageName} onClick={showNavbar} />
      </Column2>
    </Header>
  );
};

export default NewHeader;

const Header = styled("header")(({ theme }) => ({
  maxWidth: "1400px",
  display: "flex",
  padding: "0px 64px 0 64px",
  justifyContent: "space-between",
  alignItems: "center",
  height: 64,
  width: "100%",
  [theme.breakpoints.down(900)]: {
    padding: "0px 16px 0px 16px",
  },
}));

const Logo = styled("img")(({ theme }) => ({
  [theme.breakpoints.down(900)]: {
    width: "92px",
  },
}));

const LinkBox = styled("nav")(({ theme }) => ({
  [theme.breakpoints.down(900)]: {
    position: "fixed",
    zIndex: 999,
    top: 0,
    left: 0,
    height: "380px",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 28,
    backgroundColor: "#111111",
    // transition: "1s",
    transform: "translateY(-380px)",
  },
}));

const Links = styled(NavLink)<{ pagename: string }>(({ theme, pagename }) => ({
  marginRight: 28,
  textDecoration: "none",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "1.5rem",
  color: pagename === "customers" ? "#E1AA14" : "#FEFEFE",
  "&:hover": {
    color: "#97802C",
    textDecoration: "none",
  },
  [theme.breakpoints.down(970)]: {
    fontSize: "0.875rem",
    marginRight: "15px",
  },
  [theme.breakpoints.down(9000)]: {
    marginRight: 0,
  },
}));

const DevDropdown = styled("button")<{ pagename: string }>(
  ({ theme, pagename }) => ({
    display: "inline-flex",
    background: "transparent",
    alignItems: "center",
    gap: "4px",
    marginRight: 28,
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: "1.5rem",
    color: pagename === "customers" ? "#121212" : "#E1AA14",
    "&:hover": {
      color: "#97802C",
      textDecoration: "none",
    },
    [theme.breakpoints.down(970)]: {
      fontSize: "0.875rem",
      marginRight: "15px",
    },
    [theme.breakpoints.down(900)]: {
      color: "#FEFEFE",
      marginRight: 0,
    },
  })
);

const MenuBtn = styled(HambergerMenu)<{ pagename: string }>(
  ({ theme, pagename }) => ({
    display: "none",
    color: pagename === "customers" ? "#121212" : "#FEFEFE",
    [theme.breakpoints.down(900)]: {
      display: "block",
      cursor: "pointer",
    },
  })
);

const CloseBtn = styled(Close)(({ theme }) => ({
  display: "none",
  color: "#FEFEFE",
  position: "absolute",
  top: 0,
  left: 0,
  margin: 16,
  [theme.breakpoints.down(900)]: {
    display: "block",
    cursor: "pointer",
  },
}));

const Column2 = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(900)]: {
    display: "flex",
    gap: "32px",
    alignItems: "center",
  },
}));

const StyledLoginBtn = styled("button")<{ pagename: string }>(
  ({ pagename }) => ({
    border: "1px solid #FDD649",
    background: "transparent",
    cursor: "pointer",
    padding: "6px 10px",
    transition: "transform 0.2s",
    borderRadius: 20,
    textDecoration: "none",
    color: pagename === "customers" ? "#121212" : "#FDD649",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    // marginRight: 28,
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& > :not(:last-child)": {
      marginRight: 28,
    },
    "&:hover": {
      transform: "scale(1.02)",
      color: "#97802C",
      border: "1px solid #97802C",
    },
  })
);

const DropdownMenu = styled(Box)<{ pagename: string }>(({ pagename }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start !important",
  border: pagename === "customers" ? "1px solid #E0E1E6" : "1px solid #303136",
  backgroundColor: pagename === "customers" ? "#FCFCFD" : "#19191B",
  borderRadius: "10px",
  width: "355px",
  boxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.25)",
  "& a": {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    cursor: "pointer",
    width: "100%",
    padding: "8px 20px",
    textDecoration: "none",
    color: pagename === "customers" ? "#121212" : "#FEFEFE",
    "&:hover": {
      background:
        pagename === "customers" ? "#EFF0F3 !important" : "#303136 !important",
    },
    "& h6": {
      fontWeight: 700,
      fontSize: "1.25rem",
      lineHeight: "2rem",
    },
    "& p": {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
    },
  },
}));
