import { ReactNode } from "react";
import { Box, styled, Typography } from "@mui/material";

const MainLayout = ({ children, pageHeader, isPublic }: WrapperProps) => {
  return (
    <MainWrapper isPublic={isPublic}>
      <MainContainer>
        <PageHeader>{pageHeader}</PageHeader>
        <Content className="main-container__content">{children}</Content>
      </MainContainer>
    </MainWrapper>
  );
};

export default MainLayout;

interface WrapperProps {
  children: ReactNode;
  pageHeader: string;
  isPublic?: boolean;
  hasBot?: boolean;
}

interface MainWrapperProps {
  isPublic?: boolean;
}

const MainWrapper = styled(Box)<MainWrapperProps>(({ theme, isPublic }) => ({
  padding: "0px 0 0 225px",
  backgroundColor: "#121212",
  [theme.breakpoints.down(960)]: {
    padding: isPublic ? "0 0 0 0" : "0 0 0 85px",
  },
  [theme.breakpoints.down(769)]: {
    padding: "56px 0 0 0px",
  },
}));

const PageHeader = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  color: "#FEFEFE",
  padding: "14px 40px",
  borderBottom: "1px solid #1e1e1e",
  [theme.breakpoints.down(1350)]: {
    padding: "20px 0px 20px 20px",
  },

  [theme.breakpoints.down(769)]: {
    borderBottom: "none",
    padding: "0px 0px 0px 20px",
    fontSize: "1.125rem",
    lineHeight: "1.875rem",
  },
}));

const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "8px 16px 0 10px",
  position: "relative",
  maxWidth: 1400,
  border: "1px solid #1e1e1e",
  borderRadius: "20px 20px 0 0",
  backgroundColor: "#121212",
  minHeight: "calc(100vh - 8px)",
  [theme.breakpoints.down(769)]: {
    display: "block",
    position: "static",
    border: "none",
    margin: "0px",
    minHeight: "auto",
  },
}));

const Content = styled("div")(({ theme }) => ({
  padding: "20px 40px 40px 40px",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  [theme.breakpoints.down(1350)]: {
    padding: "20px",
  },
  [theme.breakpoints.down(768)]: {
    display: "block",
  },
}));
