import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import JustLoading from "../Common/JustLoading";
import ProfileEndpoint from "../../libs/ProfileEndpoint";
import { challengeData } from "../Challenges/ChallengeCard/challengeData";
import { userProfile } from "../../libs/UserProfile";
import ChallengeCard from "../Challenges/ChallengeCard";

export default function IndividualChallenge({
  isPublic,
}: {
  isPublic: boolean;
}) {
  const { challengeId } = useParams<{ challengeId: string }>();
  const [challenge, setChallenge] = useState({});

  const fetchChallenge = async () => {
    const userId = userProfile(localStorage).userId();
    const response = await ProfileEndpoint.getChallengeStatus(challengeId);
    updateChallengeData(challengeId, userId, response.status, isPublic);
  };

  const updateChallengeData = (
    challengeId: string,
    userId: string,
    status: string,
    isPublic: boolean
  ) => {
    const data = challengeData(challengeId, userId);
    setChallenge({
      id: challengeId,
      userStatus: status ?? "not started",
      isPublic: isPublic,
      // userStatus: "started",
      ...data,
    });
  };

  useEffect(() => {
    if (!isPublic) {
      fetchChallenge();
    } else {
      updateChallengeData(challengeId, "username", "not started", isPublic);
    }
  }, [challengeId, isPublic]); // eslint-disable-line

  return challenge && Object.keys(challenge).length > 0 ? (
    <ChallengeCard challenge={challenge} />
  ) : (
    <Box
      style={{
        backgroundColor: "#121212",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <JustLoading />
    </Box>
  );
}
