import { FC } from "react";
import useTubliChatBotStyles from "./tubliChatBotStyles";
import ChatIdlePopup from "./ChatIdlePopup";
import { IOnClose } from "./tubliChatBotResources";
import ChatWindow from "./ChatWindow";
import ChatHistory from "./ChatHistory";
import { ClickAwayListener, Paper } from "@mui/material";
import { useChatBotContext } from "../../Context/ChatBotContext";

const RenderChatView: FC<IOnClose> = ({ onClose }) => {
  const { chatBotData } = useChatBotContext();

  switch (chatBotData?.chatBotView) {
    case "idle popup":
      return <ChatIdlePopup onClose={onClose} />;
    case "chat view":
      return <ChatWindow onClose={onClose} />;
    case "history view":
      return <ChatHistory onClose={onClose} />;
    default:
      return null;
  }
};

const ChatBotView: FC<IOnClose> = ({ onClose }) => {
  const classes = useTubliChatBotStyles();
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Paper className={classes.chatWindowPopper}>
        <RenderChatView onClose={onClose} />
      </Paper>
    </ClickAwayListener>
  );
};

export default ChatBotView;
