import { Box, styled } from "@mui/material";
import CoursePresentationCard from "./CoursePresentationCard";
import { useLearnContext } from "./LearnContext";

const RecentRoadmapsView = () => {
  const { recentInteractedRoadmaps, getPhaseProgress } = useLearnContext();

  if (!recentInteractedRoadmaps || !recentInteractedRoadmaps?.[0]?.phase)
    return null;

  return (
    <MainContent>
      <Box sx={{ width: "100%" }}>
        <RoadmapContent>
          <Box className="roadmapPhases">
            {recentInteractedRoadmaps?.map(
              ({ phase, recentPathway, roadmapImage, phaseIndex }) => {
                return (
                  <CoursePresentationCard
                    key={phase.id}
                    course={{
                      id: phase.id,
                      learningPrompt: recentPathway?.prompt || "",
                      phaseRoadmapId: recentPathway?.roadmapId || "",
                      phaseType: "normal",
                      imageUrl: roadmapImage || undefined,
                      courseName: phase.phaseTitle,
                      description: phase.overview,
                      stacks: phase.tags,
                      courseTopics: phase.tags,
                      structure: phase.sections.map((s) => s.title),
                      sections: new Map(
                        phase.sections.map((s, j) => [j + 1, s])
                      ),
                    }}
                    sectionNumber={recentPathway?.progress?.lastAccessedSection}
                    completedCnt={getPhaseProgress(recentPathway).completed}
                    sectionsCnt={getPhaseProgress(recentPathway).total}
                    btnText={`Phase ${phaseIndex + 1}`}
                    imageUrl={roadmapImage}
                    viewOnly={false}
                  />
                );
              }
            )}
          </Box>
        </RoadmapContent>
      </Box>
    </MainContent>
  );
};

export default RecentRoadmapsView;

const MainContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  "&>div": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down(860)]: {
      width: "100%",
    },
    [theme.breakpoints.down(736)]: {
      width: "100%",
    },
  },
  "& .creditStatWrap": {
    margin: "8px 0 12px",
  },
}));

const RoadmapContent = styled(Box)({
  "& .roadmapPhases": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "8px 0 0",
  },
  "& .resumeBtn": {
    marginTop: "12px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
});
