import { Dispatch, SetStateAction } from "react";
import CopilotEndpoint from "../../../libs/CopilotEndpoint";
import { userProfile } from "../../../libs/UserProfile";
import ProfileEndpoint from "../../../libs/ProfileEndpoint";
import { JobListing } from "../../Copilot/Playground/types";

const userCache = userProfile(localStorage);

// Hard-coded variables
export const minCharCnt = 2; // Require at least 2 characters
export const minMsgLineCnt = 2; // Max of 2 characters per incoming message
export const minChatBoxWidth = 388;
export const maxChatBoxWidth = 800;

// Mark-up texts for chat suggestions
export const welcomeMsgs = [
  "Got any question? I would be happy to help.",
  "What would you like to know?",
];
export const suggestedQueries = [
  "Learn about open-source",
  "How to create a Github pull request?",
  "What are Large Language Models",
];

// Types

export type ITublibotAvatar = {
  size?: number;
  type?: "bot" | "user";
};

export type IInputError = {
  error: boolean;
  errMsg: string;
};

export type IChatHistoryElement = {
  conversationId: string;
  queries: string[];
  responses: string[];
};

export type IChatAnswerData = {
  answer: string;
  conversationId: string;
  query: string;
};

export type ChatBotViewType =
  | "idle"
  | "idle popup"
  | "chat view"
  | "history view";

export type Message = {
  // text: string | string[];
  text: any;
  type: string;
  isLoading?: boolean;
  isTruncated?: boolean;
};

// Interfaces
export interface IOnClose {
  onClose: () => void;
}
export interface ChatBotData {
  chatBoxDimensions: {
    width: number;
    height?: number; //Height is currently constant (100vh)
  };
  chatOpen: boolean;
  chatBotView: ChatBotViewType;
  chatBotHistory: IChatHistoryElement[];
  chatBotMessage: string | null | undefined;
  currentConversationId: string;
  messagesList: Message[];
  loadingHistory: boolean;
  inputError: IInputError;
}

export type SetChatBotData = Dispatch<SetStateAction<ChatBotData>>;

// Functions

// For fetching chatbot creply to sent message
export const fetchTubliChatBotAnswer = async (
  query: string,
  chatId?: string
) => {
  try {
    // throw new Error("Simulated error");
    const response = await CopilotEndpoint.getChatBotReply(
      query,
      userCache?.userId(),
      userCache?.profilePrompt,
      chatId
    );
    // console.log("🚀 ~ file: tubliChatBotResources.ts:95 ~ response:", response);
    return response;
  } catch (error) {
    console.error("Error encountered", error);
  }
};

// For fetching chatbot-user chat history
export const fetchTubliChatBotChatHistory = async (
  setChatBotData: SetChatBotData
) => {
  try {
    setChatBotData((prevData) => ({
      ...prevData,
      loadingHistory: true,
    }));
    const res = await CopilotEndpoint.getChatBotHistory(userCache.userId());
    // console.log("🚀 ~ file: tubliChatBotResources.ts:80 ~ res:", res);
    setChatBotData((prevData) => ({
      ...prevData,
      loadingHistory: false,
      chatBotHistory: res?.history,
    }));
  } catch (error) {
    console.error("Error encountered", error);
  }
};

// For toggling truncation of chat bot reply
export const handleSeeMoreClick = (
  index: any,
  setChatBotData: SetChatBotData
) => {
  setChatBotData((prevData) => {
    const updatedMessagesList = prevData.messagesList.map((message, i) => {
      if (i === index && message.isTruncated) {
        return {
          ...message,
          isTruncated: false,
        };
      } else {
        return {
          ...message,
          isTruncated: true,
        };
      }
    });

    return {
      ...prevData,
      messagesList: updatedMessagesList,
    };
  });
};

// For resuming a previous chat from history
export const handleResumeChat = (
  chat: IChatHistoryElement,
  setChatBotData: SetChatBotData
) => {
  const queries = chat.queries;
  const parsedResponses = chat?.responses?.map(
    (res) => JSON.parse(res)?.response
  );

  const mergedMsgs: Message[] = [];

  const minLen = Math.min(queries.length, parsedResponses.length);

  for (let i = 0; i < minLen; i++) {
    mergedMsgs.push({ text: queries[i], type: "outgoing" });
    mergedMsgs.push({
      text: parsedResponses[i],
      type: "incoming",
      isTruncated: parsedResponses[i].length > minMsgLineCnt,
    });
  }

  if (queries.length > parsedResponses.length) {
    for (let i = minLen; i < queries.length; i++) {
      mergedMsgs.push({ text: queries[i], type: "outgoing" });
    }
  } else if (queries.length < parsedResponses.length) {
    for (let i = minLen; i < parsedResponses.length; i++) {
      mergedMsgs.push({
        text: parsedResponses[i],
        type: "incoming",
        isTruncated: parsedResponses[i].length > minMsgLineCnt,
      });
    }
  }

  setChatBotData((prevData) => ({
    ...prevData,
    chatBotView: "chat view",
    currentConversationId: chat.conversationId,
    messagesList: mergedMsgs,
  }));
};

// For sending message to bot
export const handleSendMsg = (
  chatBotData: ChatBotData,
  setChatBotData: SetChatBotData
) => {
  const chatId = chatBotData?.currentConversationId || undefined;
  // console.log("🚀 ~ file: tubliChatBotResources.ts:120 ~ chatId:", chatId);

  const query = chatBotData?.chatBotMessage;
  if (query && query.length >= minCharCnt) {
    const newOutgoingMessage = { text: query, type: "outgoing" };
    const newLoadingMessage = {
      text: "...",
      type: "incoming",
      isLoading: true,
    };

    setChatBotData((prevData) => ({
      ...prevData,
      messagesList: [
        ...prevData.messagesList,
        newOutgoingMessage,
        newLoadingMessage,
      ],
      chatBotMessage: "",
      inputError: { error: false, errMsg: "" },
    }));

    fetchTubliChatBotAnswer(query, chatId)
      .then((replyDetails) => {
        // const answer = replyDetails.answer;
        const parsedResponse = JSON.parse(replyDetails.bot);
        const answer = parsedResponse.response;
        // console.log("🚀 ~ answer:", answer);
        const isTruncated = answer.length > minMsgLineCnt;
        setChatBotData((prevData) => ({
          ...prevData,
          messagesList: prevData.messagesList.map((msg) =>
            msg === newLoadingMessage
              ? { text: answer, type: "incoming", isTruncated }
              : msg
          ),
          currentConversationId: replyDetails?.conversationId || undefined,
        }));
      })
      .catch((err) => {
        setChatBotData((prevData) => ({
          ...prevData,
          messagesList: prevData.messagesList.map((msg) =>
            msg === newLoadingMessage
              ? {
                text: ["Oops! Something went wrong. Please try again."],
                type: "error",
                isTruncated: false,
              }
              : msg
          ),
        }));
        console.log("Error", err);
      });
  } else {
    setChatBotData((prevData) => ({
      ...prevData,
      inputError: {
        error: true,
        errMsg: "No query message detected",
      },
    }));
    document.getElementById("chatBotMessage")?.focus();
    setTimeout(
      () =>
        setChatBotData((prevData) => ({
          ...prevData,
          inputError: {
            error: false,
            errMsg: "",
          },
        })),
      3000
    );
  }
};

// For resizing chat window
export const handleMouseDown = (
  e: React.MouseEvent<HTMLDivElement>,
  chatBotData: ChatBotData,
  setChatBotData: SetChatBotData
) => {
  e.preventDefault();
  const startX = e.clientX;
  const startWidth = chatBotData?.chatBoxDimensions.width;

  function handleMouseMove(e: MouseEvent) {
    const newWidth = startWidth - (e.clientX - startX);

    if (newWidth >= minChatBoxWidth && newWidth <= maxChatBoxWidth) {
      setChatBotData((prevData) => ({
        ...prevData,
        chatBoxDimensions: { width: newWidth },
      }));
    }
  }

  function handleMouseUp() {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  }

  document.addEventListener("mousemove", handleMouseMove);
  document.addEventListener("mouseup", handleMouseUp);
};

// Tublibot Explorer

export type TublibotReturnedMsg = {
  content: any;
  role: "user" | "tublibot";
  type?: "jobList" | "jobAnalysis" | any; // temporary
};
export interface TublibotExplorerData {
  loading: boolean;
  tublibotContext: TublibotExplorerMessage[]; // content is as received
  messagesList: TublibotReturnedMsg[]; // content is modified
}

export type SetTublibotExplorerData = Dispatch<
  SetStateAction<TublibotExplorerData>
>;

type Role = "system" | "user" | "assistant";

interface ToolCall {
  function: {
    arguments: string;
    name: string;
  };
  id: string;
  type: string;
}

export interface TublibotExplorerMessage {
  content: string | null;
  role: Role;
  tool_calls?: ToolCall[];
  tool_call_id?: string;
}

interface FetchJobsparams {
  location?: string;
  isRemote?: boolean;
  jobQuery?: string;
  stack?: string[];
  verbose?: boolean;
}

export type TublibotSource = "playground" | "project";

function sortJobsByStacks(jobs: JobListing[], stacks: string[]): JobListing[] {
  return jobs.sort((a, b) => {
    const aTitleMatches = a.title
      ? stacks.some((stack) =>
        a.title.toLowerCase().includes(stack.toLowerCase())
      )
      : false;
    const bTitleMatches = b.title
      ? stacks.some((stack) =>
        b.title.toLowerCase().includes(stack.toLowerCase())
      )
      : false;

    if (aTitleMatches && !bTitleMatches) {
      return -1;
    } else if (!aTitleMatches && bTitleMatches) {
      return 1;
    } else {
      const aDescriptionMatches = a.description
        ? stacks.some((stack) =>
          a.description.toLowerCase().includes(stack.toLowerCase())
        )
        : false;
      const bDescriptionMatches = b.description
        ? stacks.some((stack) =>
          b.description.toLowerCase().includes(stack.toLowerCase())
        )
        : false;

      if (aDescriptionMatches && !bDescriptionMatches) {
        return -1;
      } else if (!aDescriptionMatches && bDescriptionMatches) {
        return 1;
      } else {
        return 0;
      }
    }
  });
}

function googleJobDefaultSearchQuery(isRemote: boolean, stack?: string[]) {
  const baseQuery = isRemote ? "remote software engineer" : "software engineer";
  const stackQuery = stack?.length ? ` ${stack.join(", ")}` : "";
  const q =
    stackQuery?.length > 0 ? baseQuery + " with " + stackQuery : baseQuery;

  // console.log("🚀 ~ googleJobDefaultSearchQuery ~ q:", q);
  return q;
}

// Using named parameters because of the nature and usage of the parameters
export const fetchJobs = async (params: FetchJobsparams) => {
  let sortedByStacks: JobListing[] = [];

  const { location, jobQuery, stack, isRemote = false, verbose = false } = params;
  // console.log("🚀 ~ fetchJobs ~ params:", params);

  // const isRemote =
  //   !location ||
  //   location.toLowerCase() === "remote" ||
  //   jobQuery?.toLowerCase().includes("remote") ||
  //   false;

  const geographicLocation =
    location && location !== "remote" ? location : undefined;

  const ip = await ProfileEndpoint.getUserLocationOrDefault();
  const query = jobQuery || googleJobDefaultSearchQuery(isRemote, stack);

  let result = await ProfileEndpoint.getGoogleJobs(
    query,
    ip,
    geographicLocation
  );

  if ((!result.jobs || result.jobs.length === 0) && verbose) {
    result = await ProfileEndpoint.getJobs(
      userCache.userId(),
      stack?.join(", ")
    );
  }

  // Try to show jobs with the langauges in title or description first
  if (stack) {
    sortedByStacks = sortJobsByStacks(result.jobs, stack);
    return sortedByStacks;
  }

  return result.jobs;
};

export function getJobSearchPhrases(
  userLangs = ["JavaScript", "Python"]
): string[] {
  let actions = [
    "Fetch remote jobs in",
    "Get local jobs in",
    "Search for local opportunities in",
    "Look for remote full-time roles in",
    "Check for open remote roles in",
    "Seek remote work in",
    "Explore remote positions in",
  ];

  let jobSearchPhrases = userLangs.map((lang) => {
    if (actions.length === 0) return `I want remote jobs using ${lang}`;
    const actionIndex = Math.floor(Math.random() * actions.length);
    const action = actions.splice(actionIndex, 1)[0];
    return `${action} ${lang}`;
  });

  return jobSearchPhrases;
}

export function getProjectSearchPhrases(
  userLangs = ["JavaScript", "Python"]
): string[] {
  const actions = [
    "Fetch me open-source projects in",
    "Get open-source projects in",
    "Search for open-source projects in",
    "Look for open-source projects in",
    "Explore open-source projects in",
  ];

  const shuffledLangs = userLangs.sort(() => Math.random() - 0.5);

  const shuffledActions = actions.sort(() => Math.random() - 0.5);

  const jobSearchPhrases = shuffledLangs.map((lang) => {
    const action = shuffledActions.pop() || actions[0];
    return `${action} ${lang}`;
  });

  return jobSearchPhrases;
}

export const jobSliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1090,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1.7,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
