import { useState } from "react";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { styled } from "@mui/material";

const SlidingSelect: React.FC<SlidingSelectProps> = ({
  options,
  value,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(value);

  const handleArrowClick = (direction: "forward" | "back") => {
    const currentIndex = options.findIndex(
      (option) => option.value === selectedOption
    );
    let newIndex;

    if (direction === "forward" && currentIndex < options.length - 1) {
      newIndex = currentIndex + 1;
    } else if (direction === "back" && currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else {
      return;
    }
    const newValue = options[newIndex].value;
    setSelectedOption(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <StyledBox>
      <Box sx={{ width: "28px", height: "28px" }}>
        {selectedOption !== options[0].value && (
          <ArrowBackIos onClick={() => handleArrowClick("back")} />
        )}
      </Box>
      <span>
        {options.find((option) => option.value === selectedOption)?.label}
      </span>
      <Box sx={{ width: "28px", height: "28px" }}>
        {selectedOption !== options[options.length - 1].value && (
          <ArrowForwardIosIcon onClick={() => handleArrowClick("forward")} />
        )}
      </Box>
    </StyledBox>
  );
};

export default SlidingSelect;

interface Option {
  label: string;
  value: any;
}

interface SlidingSelectProps {
  options: Option[];
  value: any;
  onChange?: (value: any) => void;
}

const StyledBox = styled(Box)(({ theme }) => ({
  width: "228px",
  height: "36px",
  backgroundColor: "#292929",
  padding: "8px",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#FEFEFE",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  fontWeight: 500,
  "& svg": {
    padding: "8px",
    width: "28px",
    height: "28px",
    background: "#1E1E1E",
    borderRadius: "50%",
  },
  [theme.breakpoints.down(768)]: {
    width: "100%",
  },
}));
