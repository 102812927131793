import { Box, TextareaAutosize, Typography, styled } from "@mui/material";
import CustomButton from "../../Common/CustomButton";
import { useLearnContext } from "./LearnContext";
import { userProfile } from "../../../libs/UserProfile";
import { logEvent } from "../../../libs/amplitude";
import { useState } from "react";

const CourseFeedBack = () => {
  const [reaction, setReaction] = useState<Reaction>({
    "Don't like this content": false,
    "Don't like the layout": false,
    "Content not correct": false,
  });

  const selectedReactions = Object.keys(reaction).filter(
    (key) => reaction[key]
  );

  const { popupStateData, setPopupStateData, updatePopupStateData } =
    useLearnContext();
  const handleTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updatePopupStateData("feedbackData", {
      ...popupStateData.feedbackData,
      feedbackMsg: e.target.value,
    });
  };

  const toggleReaction = (key: string) => {
    setReaction((prevReaction) => ({
      ...prevReaction,
      [key]: !prevReaction[key],
    }));
  };

  const handleFeedback = () => {
    const { feedbackData, course, activeContent } = popupStateData;
    const { feedbackMsg } = feedbackData;

    // console.log("Data to log", {
    //   reaction: selectedReactions, // array of feedbacks
    //   feedbackMsg, // feedback message
    //   course, // phase disliked
    //   activeContent, //content disliked
    // });

    logEvent("8020", userProfile(localStorage).userId(), {
      action: "feedback",
      reaction: selectedReactions, // array of feedbacks
      feedbackMsg, // feedback message
      course, // phase liked
      activeContent, //content disliked
    });

    setPopupStateData({
      isActive: false,
      feedbackData: {},
      activeContent: undefined,
      popupMode: "",
    });
  };

  const handleClose = () => {
    setPopupStateData({
      isActive: false,
      feedbackData: {},
      activeContent: undefined,
      popupMode: "",
    });
  };

  return (
    <FeedBackWrap>
      <Typography component="h2">Additional feedback</Typography>

      <Box className="feedbackBody">
        <ReactionsWrap>
          {Object.entries(reaction).map(([key, value]) => (
            <StyledTypography
              key={key}
              variant="body1"
              onClick={() => toggleReaction(key)}
              isTrue={value}
            >
              {key}
            </StyledTypography>
          ))}
        </ReactionsWrap>
        <StyledTextarea
          value={popupStateData?.feedbackData?.feedbackMsg || ""}
          onChange={handleTextarea}
          placeholder="Additional feedback"
        />
      </Box>

      <Box className="feedbackFooter">
        <CustomButton type="tertiary" size="medium" onClick={handleClose}>
          Close
        </CustomButton>
        <CustomButton
          type="primary"
          size="medium"
          disabled={selectedReactions.length === 0}
          onClick={handleFeedback}
        >
          Send Feedback
        </CustomButton>
      </Box>
    </FeedBackWrap>
  );
};

export default CourseFeedBack;

type Reaction = Record<string, boolean>;

const FeedBackWrap = styled(Box)(({ theme }) => ({
  border: "1px solid #414141",
  backgroundColor: "#1E1E1E",
  borderRadius: 20,
  width: 715,
  // height: 315,
  color: "#FEFEFE",
  position: "relative",
  "& h2": {
    borderBottom: "1px solid #292929",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    gap: "28px",
    fontWeight: 700,
    fontSize: "1.25rem",
    lineHeight: "2rem",
  },
  "& .feedbackBody": {
    padding: "14px 20px 24px",
    "& textarea": {
      background: "#1E1E1E",
      width: "100%",
      // height: "139px",
      padding: "16px 20px",
      borderRadius: "20px",
      border: "1px solid #292929",
      color: "#707070",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem",
    },
    [theme.breakpoints.down(768)]: {
      padding: "20px",
    },
  },
  "& .feedbackFooter": {
    paddingRight: 20,
    paddingBottom: 21,
    display: "flex",
    justifyContent: "flex-end",
    fontStyle: "normal",
    color: "#B7B7B7",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    gap: "20px",
    [theme.breakpoints.down(768)]: {
      gap: 26,
    },
  },
  [theme.breakpoints.down(768)]: {
    width: 356,
    // height: 303,
    "& h2": {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
  },
  [theme.breakpoints.down(390)]: {
    width: "90vw",
  },
}));

const StyledTextarea = styled(TextareaAutosize)({
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: "1.25rem",
  resize: "none",
});

const ReactionsWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: "16px",
  margin: "0 0 40px",
  [theme.breakpoints.down(768)]: {
    gap: "12px",
    margin: "0 0 20px",
  },
}));

const StyledTypography = styled(Typography)<{ isTrue: boolean }>(
  ({ theme, isTrue }) => ({
    padding: "8px 12px",
    borderRadius: "10px",
    backgroundColor: isTrue ? "#292929" : "transparent",
    border: isTrue ? "1px solid #414141" : `1px solid #292929`,
    color: "#FEFEFE",
    cursor: "pointer",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1.25rem",
  })
);
