import { makeStyles } from "@mui/styles";
import { Theme, Typography, Button } from "@mui/material";
import SignInPopover from "./Welcome/WelcomeSignInPopover";
import logo from "../../images/new-logo.svg";
import "./OnboardingReset.css";
import usePopoverState from "../Common/popover-hook";

const useBaseStyles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#121212",
    padding: theme.spacing(6, 12),
    "& > *": {
      paddingRight: theme.spacing(1.5),
    },
    // ["@media (max-width:769px)"]: {
    [theme.breakpoints.down(769)]: {
      padding: theme.spacing(1, 2),
    },
  },
  headerText: {
    ".onboarding h4&": {
      color: theme.palette.text.primary,
      fontFamily: "Source Code Pro",
      fontWeight: 700,
    },
  },
  logo: {
    width: "3vw",
  },
  signIn: {
    padding: 0,
    marginLeft: "auto",
  },
}));

export default function OnboardingHeaderBase(props: {
  signIn?: boolean;
  classes?: any;
}) {
  const classes = useBaseStyles(props);
  const popoverState = usePopoverState();

  return (
    <header className={`onboarding ${classes.header}`}>
      <img className={classes.logo} src={logo} alt="logo" />
      <Typography
        className={classes.headerText}
        color="textPrimary"
        component="h4"
        variant="h5"
      >
        Tublian
      </Typography>
      {props.signIn && (
        <div className={classes.signIn}>
          <Button onClick={popoverState.onOpen}>
            <Typography component="span" variant="h6">
              Sign In
            </Typography>
          </Button>
          <SignInPopover
            open={popoverState.open}
            onClose={popoverState.onClose}
          />
        </div>
      )}
    </header>
  );
}

const useOnboardingStyles = makeStyles((theme: Theme) => ({
  header: {
    justifyContent: "center",
  },
}));

export const OnboardingHeader = ({ signIn }: { signIn?: boolean }) => {
  const classes = useOnboardingStyles();

  return <OnboardingHeaderBase signIn={signIn} classes={classes} />;
};

export const WelcomeHeader = ({ signIn }: { signIn?: boolean }) => {
  return <OnboardingHeaderBase signIn={signIn} />;
};

const usePopoverStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: "transparent",
    justifyContent: "center",
  },
  headerText: {
    ".onboarding h4&": {
      fontSize: "2rem",
    },
  },
  logo: {
    height: 63,
    width: 63,
  },
}));

export const PopoverHeader = () => {
  const classes = usePopoverStyles();

  return <OnboardingHeaderBase classes={classes} />;
};
