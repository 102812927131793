export const PATHWAY_ID = "oct2023-os-internship";


export const isInternshipCompleted = (progress: any) => {
  return progress && progress.status === true;
}
export const weekOneLearningObjectives = [
  "Learn what is OpenSource",
  "Why OpenSource is important for software industry",
  "Learn how beginners can contribute to OpenSource",
  "Bascis of Git & Github",
  "Make your first contribution",
];

export const weekTwoLearningObjectives = [
  "Structure of Open source Project",
  "Open source Communities",
  "Using AI Copilot for Open source"
];

export const weekThreeLearningObjectives = [
  "Role of a Open source maintainer",
  "Work required to run and maintain Open Source"
];

export const weekFourLearningObjectives = [
  "Working with Legacy codebase",
  "Making code contributions"
];

export const learningResourcesWeekOne = [
  {
    id: "week11",
    title: "What is Open Source?",
    thumbnail:
      "https://cdn.hashnode.com/res/hashnode/image/stock/unsplash/EI1y2s9rK-s/upload/80690dd0ca3b8e7b180a39102398cab8.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    link: "https://devlog.tublian.com/what-is-open-source",
    description:
      "Open source software is software with source code that anyone can inspect, modify, and enhance",
    status: "incomplete",
  },
  {
    id: "week12",
    title: "Why Contritute to Open Source?",
    thumbnail:
      "https://cdn.hashnode.com/res/hashnode/image/stock/unsplash/IUY_3DvM__w/upload/1917899f5ae56466c641b9cc6386f159.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    link: "https://devlog.tublian.com/open-source-for-emerging-developers",
    description: "Open source was once a bonus; now it's essential",
    status: "incomplete",
  },
  {
    id: "week13",
    title: "How to Contribute to Open Source?",
    thumbnail: "https://opensource.guide/assets/images/illos/contribute.svg",
    link: "https://www.freecodecamp.org/news/a-practical-guide-to-start-opensource-contributions/",
    description: "A Beginner's Handbook to Help You Start Contributing",
    status: "incomplete",
  },
  {
    id: "week14",
    title: "Learning Git/Github",
    thumbnail:
      "https://www.digitalocean.com/_next/static/media/intro-to-cloud.d49bc5f7.jpeg",
    link: "https://www.digitalocean.com/community/cheatsheets/how-to-use-git-a-reference-guide",
    description: "How To Use Git: A Reference Guide",
    status: "incomplete",
  },
  {
    id: "week15",
    title: "How to create a Pull Request on Github",
    thumbnail:
      "https://www.digitalocean.com/_next/static/media/intro-to-cloud.d49bc5f7.jpeg",
    link: "https://www.digitalocean.com/community/tutorials/how-to-create-a-pull-request-on-github",
    description:
      "Open-source projects that are hosted in public repositories benefit from contributions made by the broader developer community through pull requests, which request that a project accept changes you have made to its code repository",
    status: "incomplete",
  },
  {
    id: "week17",
    title: "A Checklist for Crafting an Effective Pull Request",
    thumbnail: "https://hashnode.com/utility/r?url=https%3A%2F%2Fcdn.hashnode.com%2Fres%2Fhashnode%2Fimage%2Fstock%2Funsplash%2F0Wra5YYVQJE%2Fupload%2Fd0bb29b201c5816165d95d118efbc823.jpeg%3Fw%3D1200%26h%3D630%26fit%3Dcrop%26crop%3Dentropy%26auto%3Dcompress%2Cformat%26format%3Dwebp%26fm%3Dpng",
    link: "https://devlog.tublian.com/a-checklist-for-crafting-an-effective-pull-request",
    description:
      "Mastering Pull Requests: A Guide to Success in Open Source Contributions.",
    status: "incomplete",
  },
  {
    id: "week16",
    title: "Tublian Masterclass: How to contribute to Open Source",
    resourceType: "video",
    thumbnail: "https://i.ytimg.com/vi/eqCNEG4V4T8/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGHIgXyg6MA8=&rs=AOn4CLA0K9VM1cfjf0VOJqquCm_QtB5uYw",
    link: "https://www.youtube.com/watch?v=eqCNEG4V4T8&t=6s",
    description:
      "Tobiloba Adedeji explained the importance of Open Source and how to get started.",
    status: "incomplete",
  },
];

export const learningResourcesWeekTwo = [
  {
    id: "week21",
    title: "Structure of an Open Source project",
    thumbnail:
      "https://cdn.hashnode.com/res/hashnode/image/stock/unsplash/P_qvsF7Yodw/upload/3419ec767993098584e9fa8238b668e3.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    link: "https://devlog.tublian.com/the-structure-of-an-open-source-project",
    description:
      "To grasp the essence of an Open source project, we must understand its dynamics",
    status: "incomplete",
  },
  {
    id: "week22",
    title: "Open Source Communities",
    thumbnail:
      "https://cdn.hashnode.com/res/hashnode/image/stock/unsplash/Zyx1bK9mqmA/upload/239161c98bd0db12fd145b3e12e1d28d.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    link: "https://devlog.tublian.com/open-source-communities",
    description:
      "Came for the language, stayed for the community",
    status: "incomplete",
  },
  // {
  //   id: "week23",
  //   title: "How to pick OpenSource projects?",
  //   thumbnail:
  //     "https://opensource.com/sites/default/files/lead-images/OSDC_Resource_Main_Page.png",
  //   link: "https://opensource.com/resources/what-open-source",
  //   description:
  //     "Open source software is software with source code that anyone can inspect, modify, and enhance",
  //   status: "incomplete",
  // },
  {
    id: "week24",
    title: "AI Copilot for OpenSource developers",
    thumbnail:
      "https://cdn.hashnode.com/res/hashnode/image/stock/unsplash/vkfrFrAIO4o/upload/a1bd2a7765ed3e93cb3d148651cd8789.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    link: "https://devlog.tublian.com/ai-copilot-for-open-source-contributors",
    description: "Make Open Source accessible for all emerging developers",
    status: "incomplete",
  },
];

export const learningResourcesWeekThree = [
  {
    id: "week31",
    title: "Role of a Maintainer",
    thumbnail:
      "https://www.fosslife.org/sites/default/files/styles/wide_background/public/2021-06/2021_06_23_maintainer_image-by-wilfried-pohnke-from-pixabay_1600x1000.png?h=2aa300aa&itok=Hw3Bf7Aw",
    link: "https://www.fosslife.org/whats-open-source-software-maintainer",
    description:
      "The role of open source software maintainer is a difficult and often thankless one. A recent Tidelift survey, for example, found that 46 percent of open source project maintainers aren't even paid. Nonetheless, the work maintainers do is critical to ensure the long-term usability, security, and reliability of open source software",
    status: "incomplete",
  },
  {
    id: "week32",
    title: "Work behind Open Source",
    thumbnail:
      "https://cdn.hashnode.com/res/hashnode/image/stock/unsplash/CyPHeY3qQds/upload/2c4597955cff46e4859f34bf8f89368e.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    link: "https://devlog.tublian.com/understanding-the-work-behind-open-source-a-guide-for-new-contributors",
    description:
      "The role of open source software maintainer is a difficult and often thankless one. A recent Tidelift survey, for example, found that 46 percent of open source project maintainers aren't even paid. Nonetheless, the work maintainers do is critical to ensure the long-term usability, security, and reliability of open source software",
    status: "incomplete",
  },
];

export const learningResourcesWeekFour = [
  {
    id: "week41",
    title: "Learning a New & unfamiliar codebase",
    resourceType: "video",
    thumbnail: "https://i.ytimg.com/vi/MlGAU_Gv2cA/maxresdefault.jpg",
    link: "https://www.youtube.com/watch?v=MlGAU_Gv2cA",
    description:
      "Learn a few ways to jump into a new code base quickly without feeling intimidated.",
    status: "incomplete",
  },
  {
    id: "week42",
    title: "Working Effectively with Legacy Code • Michael Feathers & Christian Clausen • GOTO 2023",
    resourceType: "video",
    thumbnail:
      "https://i.ytimg.com/vi/P_6eDL1aqtA/maxresdefault.jpg",
    link: "https://www.youtube.com/watch?v=P_6eDL1aqtA",
    description:
      "Michael Feathers   Working Effectively with Legacy Code",
    status: "incomplete",
  },
  {
    id: "week43",
    title: "Contributing to a Large Open Source Code Base as a Beginner.",
    resourceType: "video",
    thumbnail:
      "https://i.ytimg.com/vi/_5ktGQIWLjk/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGHIgSigzMA8=&rs=AOn4CLD2-N3vZgjW0H_vB9sM-1WPrYkKVQ",
    link: "https://www.youtube.com/watch?v=_5ktGQIWLjk",
    description:
      "Have you ever had any difficulty getting started with a large code base? Be it Open Source or on your New Job?",
    status: "incomplete",
  },
];

export const weeklyPathwayData = [
  {
    week: 1,
    title: "Learn about Open Source",
    header: `This week, we will learn the basics of Open source & make our first contribution.`,
    learningResources: learningResourcesWeekOne,
    category: "first contribution",
    aim: "Make your first contribution",

    description:
      "Time to make your first Open source contribution. Pick one of the issues, follow the guidelines, and contribute.",
    learningObjectives: weekOneLearningObjectives,
    theme: "First contribution"
  },
  {
    week: 2,
    title: "Open source community",
    header: "This week, we will focus on the Open source community, how these projects are structured, and how you should pick your Open source projects.",
    learningResources: learningResourcesWeekTwo,
    category: "documentation fix",
    aim: "Boy Scouting Rule: Make the project better",
    description: "There are many ways to contribute to an Open source project. Here are some tasks that require help beyond just code. Pick the task you like.",
    learningObjectives: weekTwoLearningObjectives,
    theme: "Open source Community"
  },
  {
    week: 3,
    title: "Become a helper",
    header: "Behind every thriving Open Source project is a dedicated team of maintainers, contributors, and a community. Understanding how Open Source projects are maintained is essential.",
    learningResources: learningResourcesWeekThree,
    category: "triage",
    aim: "It's not just about the code",
    description: "Help triage and resolve issues. Pick the task you like.",
    learningObjectives: weekThreeLearningObjectives,
    theme: "Resolve Issues"
  },
  {
    week: 4,
    title: "Make code contributions",
    header: "Working with legacy codebase. How to contribute to an existing Open Source project",
    learningResources: learningResourcesWeekFour,
    category: "code changes",
    aim: "Make code contributions",
    description: "Fix or add new features. Pick the task you like.",
    learningObjectives: weekFourLearningObjectives,
    theme: "Make changes"
  },
];

export const pathWayTopics = [
  "internship-projects",
  "backend",
  "frontend",
  "library",
  "framework",
  "ai",
  "python",
  "flutter",
  "Java",
  "dotnet",
  "c",
  "rust",
  "php",
];
