import { useEffect } from "react";
import { ourPlanOne } from "../DevelopersPage/DevelopersPageData";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import animationConfig from "../Streetcred/AnimationConfig";
import { Box, styled } from "@mui/material";
import { howSvg } from "../../UserHome/Base/SVG";
import { useTheme } from "@mui/system";

interface OurPlanProps {
  img: string;
  header: string;
  subheader: string;
  alt?: string;
  id: string;
}

const OurPlanBoxOne = ({
  item,
  index,
}: {
  item: OurPlanProps;
  index: number;
}) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        ...animationConfig.animate3,
        transition: {
          delay: animationConfig.delays[index],
          ease: "easeIn",
        },
      });
    }
    if (!inView) {
      animation.start(animationConfig.initial3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <motion.div
      ref={ref}
      initial={animationConfig.initial3}
      animate={animation}
      whileHover={{ scale: 1.03 }}
    >
      <Unlock key={index} id={item.id}>
        <div className="imageBox">
          <img
            alt={item.alt}
            src={item.img}
            loading="lazy"
            // style={{ width: "100%", height: "100%" }}
          />
        </div>
        <TextBox>
          <h4>{item.header}</h4>
          <p>{item.subheader}</p>
        </TextBox>
      </Unlock>
    </motion.div>
  );
};

const OurPlan = () => {
  const theme = useTheme();
  return (
    <>
      <Header>
        <button>
          <span>{howSvg}</span>How
        </button>
        <h2>Empower Developers. Democratize Software</h2>
        <p>
          Create an open market for software development by helping developers
          build credibility, discover opportunities, and scale their
          innovations.
        </p>
      </Header>
      <UnlockBox>
        {ourPlanOne.map((m, i) => (
          <Box
            key={i}
            sx={{
              width: "32%",
              [theme.breakpoints.down(1025)]: {
                width: "478px",
              },
              [theme.breakpoints.down(720)]: {
                width: "100%",
              },
            }}
          >
            <OurPlanBoxOne item={m} index={i} />
          </Box>
        ))}
      </UnlockBox>
    </>
  );
};

export default OurPlan;

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  // marginTop: 20,

  "&>button": {
    color: "#fefefe",
    background: "linear-gradient(to bottom, #222325 0%, #383A3D 100%)",
    padding: "4px 20px",
    display: "flex",
    gap: 8,
    alignItems: "center",
    border: "1px solid #222325",
    borderRadius: 20,
  },
  "&>h2": {
    margin: "auto",
    width: 831,
    fontSize: "48px",
    textAlign: "center",
    lineHeight: "72px",
    color: "#fefefe",
    fontWeight: 700,
    padding: "28px 0 20px 0",
    [theme.breakpoints.down(1025)]: {
      fontSize: 40,
      lineHeight: "51.04px",
      width: "783px",
    },
    [theme.breakpoints.down(800)]: {
      fontSize: 30,
      lineHeight: "45px",
      width: "100%",
    },
  },
  "&>p": {
    width: 739,
    margin: "auto",
    textAlign: "center",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "32px",
    color: "#fefefe",
    paddingBottom: 80,
    [theme.breakpoints.down(1025)]: {
      width: "783px",
    },
    [theme.breakpoints.down(800)]: {
      fontSize: 14,
      lineHeight: "20px",
      width: "100%",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
}));

const UnlockBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  width: "100%",
  gap: 26,
  marginBottom: 80,
  [theme.breakpoints.down(1025)]: {
    flexDirection: "column",
    alignItems: "center",
  },
  [theme.breakpoints.down(800)]: {
    marginBottom: 20,
  },
}));

const Unlock = styled("div")(({ theme, id }) => ({
  // width: 344,
  height: 555,
  zIndex: 999,
  borderRadius: 20,
  backgroundColor: "#19191B",
  overflow: "hidden",
  [theme.breakpoints.down(1163)]: {
    height: 590,
  },
  "&> .imageBox": {
    display: "flex",
    justifyContent: "center",
    height: 352,
    padding: id === "1" ? "20px 0px 0 0px" : id === "2" ? "34px 0 0 20px" : 0,
    [theme.breakpoints.down(420)]: {
      width: "100%",
    },
  },
}));

const TextBox = styled("div")(({ theme }) => ({
  padding: "0 20px 20px 20px",
  marginTop: 20,
  // [theme.breakpoints.down(1131)]: {
  //   padding: "0 250px 20px 20px",
  // },
  // [theme.breakpoints.down(950)]: {
  //   padding: "0 20px 20px 20px",
  // },
  "&>h4": {
    color: "#FEFEFE",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "32px",
    marginBottom: "14px",
  },
  "&>p": {
    color: "#FEFEFE",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: "24px",
  },
}));
