import { FC } from "react";
import { Box, Typography } from "@mui/material";
import CustomAvatar from "../../UserHome/Base/HeaderComponents/CustomAvatar";
import vidThumb from "../../../images/video-thumbnail.png";
import ytPlay from "../../../images/youtube-play.png";
import linkIcon from "../../../images/pinnedIcon.svg";
import { useStyles } from "./style";

interface ResourcesCardProps {
  avatarUrl: string;
  thumbnailUrl: string;
  title: string;
  link: string;
  description: string;
  type: string;
}

const ResourcesCard: FC<ResourcesCardProps> = ({
  avatarUrl,
  thumbnailUrl,
  title,
  link,
  description,
  type,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.resourceCardWrap}>
      <Box className={classes.thumbnail}>
        <img
          src={thumbnailUrl || vidThumb}
          alt="thumbnail"
          className={classes.thumbnailImg}
        />
        {/* <img
          src={ytPlay}
          alt="thumbnail"
          onClick={() => window.open(link, "_blank")}
        /> */}
        <img
          style={
            type !== "youtube"
              ? {
                  width: 72,
                  height: 52,
                  background: "#292929",
                  borderRadius: 10,
                }
              : {}
          }
          src={type === "youtube" ? ytPlay : linkIcon}
          alt="thumbnail"
          onClick={() => window.open(link, "_blank")}
        />
      </Box>
      <Box className={classes.detailsWrap}>
        <Box className={classes.avatar}>
          <CustomAvatar src={avatarUrl} size={32} />
        </Box>
        <Box className={classes.details}>
          <Typography
            className={classes.resourceTitle}
            onClick={() => window.open(link, "_blank")}
          >
            {title}
          </Typography>
          <Typography className={classes.desc}>{description}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ResourcesCard;
