import { useStyles } from "./DeveloperPageStyles";
import Slider from "react-slick";
import {
  TestimonialSliderTemplate,
  sliderSettings,
} from "../TestimonialSliderTemplate";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { testimonialsData } from "../TestimonialSliderTemplate";
import { styled } from "@mui/material";

const DevsTestimonial = () => {
  const classes = useStyles();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        transition: {
          duration: 0.5,
          delay: 0.25,
        },
      });
    }
    if (!inView) {
      animation.start({ y: 50 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);
  return (
    <section>
      <div ref={ref}>
        <motion.div animate={animation}>
          <Header>
            <h2>What Devs Are Saying About Us</h2>
          </Header>
        </motion.div>
      </div>

      <div className={classes.testimonials}>
        <Slider {...sliderSettings}>
          {testimonialsData.map((testimonial, index) => (
            <div className={classes.testimonialBox} key={index}>
              <TestimonialSliderTemplate
                testimonial={testimonial}
                newLayout={true}
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default DevsTestimonial;

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "80px",
  [theme.breakpoints.down(800)]: {
    marginTop: "20px",
  },
  "&>h2": {
    margin: "auto",
    width: 831,
    fontSize: "48px",
    textAlign: "center",
    lineHeight: "72px",
    color: "#fefefe",
    fontWeight: 700,
    padding: "0 0 80px 0",
    [theme.breakpoints.down(1025)]: {
      fontSize: 40,
      lineHeight: "51.04px",
      width: "783px",
    },
    [theme.breakpoints.down(800)]: {
      fontSize: 30,
      lineHeight: "45px",
      width: "100%",
      marginTop: "20px",
    },
  },
}));
