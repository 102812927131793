
const TextUtils = {
  createTimestamp(isoDateString) {
    const parseDate = tdate => {
      let system_date = new Date(tdate)
      var user_date = new Date()

      let diff = Math.floor((user_date - system_date) / 1000)
      if (diff < 59) { return diff + "s" }
      if (diff <= 3540) { return Math.round(diff / 60) + "m" }
      if (diff <= 86400) { return Math.round(diff / 3600) + "h" }
      if (diff < 604800) { return Math.round(diff / 86400) + "d" }
      return system_date.toString().substring(4, 10)
    }
    return parseDate(isoDateString)
  },
  makeOneLine(multiLineString) {
    if (multiLineString) {
      const oneline = multiLineString.replace(/\n/g, ' ').replace(/  +/g, ' ').replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '');
      return oneline
    }
    return multiLineString;
  },
  capsFirstLetter(str) {
    if (!str || str.length === 0 || typeof str !== 'string') return "";
    return str.charAt(0).toUpperCase() + str.slice(1) || "";
  },
  mmddyyyy(isoDateString) {
    const d = new Date(isoDateString);
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var dt = d.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return month + "/" + dt + "/" + year;
  },
  time(isoDateString) {
    const d = new Date(isoDateString);
    return d.toLocaleTimeString();
  },
  truncate(input, length) {
    if (input && input.length > length) {
      return input.substring(0, length) + '...';
    }
    return input;
  },
  isNotEmpty(input) {
    return input && input.trim().length > 0;
  },
  widgetHasData(obj) {
    return (obj && Object.keys(obj).length === 0 && obj.constructor === Object) === false;
  },
  turnIntoMonthAndDay(monthNumber, day) {
    if (monthNumber < 1) {
      return "-";
    }
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    return `${months[monthNumber - 1]} ${day}`;

  },
  extractUserFrom8020Url(url) {
    const pattern = /roadmap-([^-\s]+)/;
    const match = url.match(pattern);
    return match && match[1] ? match[1] : undefined;
  },
};

export default TextUtils;