import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useCourseSectionSync = (
  activeSectionId: number,
  setActiveSectionId: (sectionId: number) => void
): void => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlSection = searchParams.get("section");

    if (urlSection) {
      const parsedSection = parseInt(urlSection, 10);
      if (activeSectionId !== parsedSection) {
        setActiveSectionId(parsedSection);
      }
    }
  }, [location.search, activeSectionId, setActiveSectionId]);
};

export default useCourseSectionSync;
