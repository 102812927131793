import { Theme, emphasize } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: "#121212",
    minHeight: "100vh",
    paddingRight: 18,
    // [theme.breakpoints.down(740)]: {
    //   "& .MuiButton-label": {
    //     fontSize: "0.685rem !important",
    //   },
    // },
    [theme.breakpoints.down(700)]: {
      paddingRight: 0,
    },
  },
  backBtn: {
    justifySelf: "flex-start",
    "& button": {
      paddingLeft: "0px !important",
    },
    marginBottom: 28,
  },
  headerWrap: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.25rem",
    lineHeight: 2,
    fontWeight: 500,
    // marginBottom: 20,
    gap: 14,
    [theme.breakpoints.down(700)]: {
      // alignItems: "flex-start",
      margin: "auto",
      marginBottom: 18,
      width: "100%",
    },
  },
  title: {
    fontSize: "1.25rem",
    lineHeight: 2,
    fontWeight: 500,
    color: "#FEFEFE",
    "& .statusText": {
      display: "inline-flex",
      fontSize: "0.875rem",
      lineHeight: 1.25,
      fontWeight: 500,
      color: "#B7B7B7",
    },
    [theme.breakpoints.down(700)]: {
      fontSize: "1.125rem",
      width: "calc(100% - 54px)",
      maxWidth: 406,
      // overflow: "hidden",
      // textOverflow: "ellipsis",
      // whiteSpace: "nowrap",
    },
  },
  contentWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 40,
    maxWidth: 1500,
    [theme.breakpoints.down(1024)]: {
      gap: 20,
    },
    [theme.breakpoints.down(860)]: {
      gap: 10,
    },
    [theme.breakpoints.down(700)]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: 40,
    },
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 317px)",
    [theme.breakpoints.down(1024)]: {
      width: "calc(100% - 297px)",
    },
    [theme.breakpoints.down(860)]: {
      width: "calc(100% - 231px)",
    },
    [theme.breakpoints.down(700)]: {
      width: "100%",
    },
  },
  asideContent: {
    width: "100%",
    backgroundColor: "#1E1E1E",
    borderRadius: 20,
    padding: 20,
    [theme.breakpoints.down(700)]: {
      padding: 0,
      backgroundColor: "transparent",
    },
  },
  asideWrap: {
    width: 277,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(700)]: {
      width: "100%",
    },
  },
  asideText: {
    color: "#888",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.25,
    "&:not(:last-child)": {
      marginBottom: 16,
    },
  },
  medals: {
    maxWidth: 19,
  },
  actionRowDesktop: {
    display: "flex",
    width: "100%",
    borderRadius: 20,
    "& .MuiOutlinedInput-input": {
      padding: 12,
    },
    "&>div": {
      display: "flex",
      alignItems: "flex-start",
      gap: 12,
    },
    [theme.breakpoints.down(700)]: {
      "&>div": {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  },
  actionRowMobile: {
    display: "none",
    [theme.breakpoints.down(700)]: {
      display: "flex",
      "& div button span.MuiButton-label": {
        fontSize: "0.875rem !important",
      },
    },
  },
  submissionLinkWrap: {
    width: 314,
    [theme.breakpoints.down(700)]: {
      width: 259,
    },
  },
  submissionLink: {
    background: "transparent",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.25,
    color: "#696969",
    flex: 1,
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #707070",
        borderRadius: 20,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FEFEFE",
      },
    },
    [theme.breakpoints.down(1280)]: {
      minWidth: "100%",
    },
    [theme.breakpoints.down(700)]: {
      minWidth: "100%",
    },
  },
  errorOutline: {
    borderColor: "red !important",
  },
  notchedOutline: {
    borderColor: "#707070",
  },
  description: {
    color: "#B7B7B7",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: 1.5,
    marginTop: 24,
  },
  emphDetails: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    // [theme.breakpoints.down(860)]: {
    //   fontSize: "0.75rem !important",
    // },
    "& span": {
      color: "#FEFEFE",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: 1.5,
      [theme.breakpoints.down(860)]: {
        fontSize: "0.75rem !important",
      },
    },
  },
  status: {
    display: "inline-block",
    borderRadius: "50%",
    margin: "0 8px 0 12px",
    width: 10,
    height: 10,
  },
  ongoing: {
    backgroundColor: "#4DED3B",
  },
  ended: {
    backgroundColor: "#F43E5C",
  },
  healthWrap: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    [theme.breakpoints.down(700)]: {
      width: 153,
    },
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    padding: 20,
    width: "100%",
    border: "1px solid #888",
    borderRadius: 20,
    marginBottom: 20,
    [theme.breakpoints.down(700)]: {
      marginBottom: 0,
      border: "none",
      backgroundColor: "#1E1E1E",
    },
  },
  bodySegment: {
    width: "100%",
    "& div *": {
      wordBreak: "break-word",
    },
    "& .slick-slide": {
      borderRadius: 20,
    },
    "& .slick-slider": {
      "& button": {
        maxWidth: 48,
      },
      [theme.breakpoints.down(700)]: {
        marginBottom: 40,
      },
    },
    "& .slick-track": {
      width: "100%",
      "&>div": {
        width: "308px !important",
        margin: "0 24px 0 0",
        background: "transparent",
        boxShadow: "none",
        "--webkit-box-shadow": "none",
      },
    },
    "& .slick-dots li button:before": {
      top: "20px !important",
      borderRadius: "50%",
      width: "12px !important",
      height: "12px !important",
      backgroundColor: "#333 !important",
    },
    "& .slick-dots li.slick-active button:before": {
      backgroundColor: "#878787 !important",
      color: "#878787 !important",
    },
    display: "flex",
    flexDirection: "column",
    marginBottom: 28,
    gap: 20,
    "& h5": {
      color: "#FEFEFE",
      fontWeight: 700,
      fontSize: "1.25rem",
      lineHeight: 2,
    },
    "& div": {
      color: "#CFCFCF",
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.5,
      "& ul": {
        padding: 0,
        marginLeft: 28,
      },
    },
    "& a": {
      color: "#FDD649",
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.5,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  emphasizedBodySegment: {
    backgroundColor: "#1E1E1E",
    borderRadius: 10,
    padding: "14px 24px 14px 14px",
  },
  fixButton: {
    fontSize: "1rem",
    backgroundColor: "#FDD649",
    color: "#121212",
    borderRadius: 30,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    "&:hover": {
      backgroundColor: emphasize("#FEE38B"),
    },
    "&:disabled": {
      backgroundColor: "#292929 !important",
      color: "#696969",
    },
    [theme.breakpoints.down(700)]: {
      fontSize: "0.875rem !important",
    },
  },
  fixSecButton: {
    fontSize: "1rem",
    backgroundColor: "transparent",
    color: "#FDD649",
    borderRadius: 30,
    border: "1px solid #FDD649",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    "&:hover": {
      backgroundColor: "#25231A",
      border: "1px solid #FEE38B",
      color: "#FEE38B",
    },
    "&:active": {
      backgroundColor: "#302C19",
      border: "1px solid #FEEFC3",
      color: "#FEEFC3",
    },
    "&:disabled": {
      backgroundColor: "transparent",
      border: "1px solid #696969",
      color: "#696969",
    },
  },
  header: {
    fontWeight: 700,
    fontSize: "1.125rem",
    lineHeight: 1.875,
    color: "#FEFEFE",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  divider: {
    backgroundColor: "#414141",
    marginTop: 20,
    marginBottom: 20,
  },
  button: {
    fontSize: "1rem",
    backgroundColor: "#FDD649",
    color: "#121212",
    borderRadius: 30,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    "&:hover": {
      backgroundColor: emphasize("#FEE38B"),
    },
    "&:disabled": {
      backgroundColor: "transparent",
      border: "1px solid #696969",
      color: "#696969",
    },
  },
  resourceCardWrap: {
    display: "flex",
    flexDirection: "column",
    gap: 28,
    width: 308,
    // height: 299,
    height: 290,
    backgroundColor: "#292929",
    borderRadius: 20,
    padding: 20,
    [theme.breakpoints.down(405)]: {
      width: 300,
    },
  },
  thumbnail: {
    position: "relative",
    width: "100%",
    // height: 161,
    height: 130,
    // backgroundColor: "#D9D9D9",
    backgroundColor: "transparent",
    borderRadius: 10,
    "& img:first-of-type": {
      maxHeight: 161,
      height: 127.13,
      // maxWidth: 226,
      width: "100%",
    },
    "& img:last-of-type": {
      position: "absolute",
      top: 54.73,
      left: 96.06,
      cursor: "pointer",
    },
  },
  thumbnailImg: {
    borderRadius: 10,
  },
  detailsWrap: {
    display: "flex",
    gap: 7,
  },
  avatar: {},
  details: {
    display: "flex",
    flexDirection: "column",
    gap: 14,
    "& *": {
      fontSize: "0.875rem",
      lineHeight: 1.25,
    },
  },
  resourceTitle: {
    fontWeight: 700,
    color: "#FEFEFE",
    cursor: "pointer",
    maxWidth: 187,
    // Clamp to 2 lines
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
  desc: {
    fontWeight: 500,
    color: "#888",
    // Clamp to 2 lines
    maxWidth: 187,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
}));
