import { useState } from "react";
import { styled, Box, Tab, Tabs } from "@mui/material";

const StyledTabLabel = styled(Tab)(({ theme }) => ({
  width: "auto",
  [theme.breakpoints.down(700)]: {
    "& *": {
      fontSize: "0.875rem",
    },
  },
}));

const StyledTabValue = styled(Box)({
  textTransform: "capitalize",
  "& .projectsCount": {
    backgroundColor: "#F26663",
    color: "#FEFEFE",
    padding: "2px 6px",
    borderRadius: "5px",
    marginLeft: "5px",
    fontWeight: 700,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
});

function TabPanel({ children, value, index }: any) {
  return <Box>{value === index && <Box>{children}</Box>}</Box>;
}

const CustomTabs = ({ tabsData }: { tabsData: ITabData[] }) => {
  const [tabValue, setTabValue] = useState(tabsData.find(e => e.show)?.value);
  const handleTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const renderTabs = () => {
    return tabsData.filter(t => t.show).map((tab) => (
      <StyledTabLabel
        key={tab.value}
        value={tab.value}
        sx={{
          minWidth: "160px",
          "@media (max-width: 700px)": {
            minWidth: "unset",
          },
        }}
        label={
          <StyledTabValue
            sx={{
              color: tabValue === tab.value ? "#FDD649" : "#888",
            }}
          >
            {tab.label}
          </StyledTabValue>
        }
      />
    ));
  };

  const renderTabPanels = () => {
    return tabsData.map((tab) => (
      <TabPanel key={tab.value} value={tabValue} index={tab.value}>
        {tab.content}
      </TabPanel>
    ));
  };

  return (
    <Box
      sx={{
        "& button:hover": {
          // backgroundColor: "#25231A",
          color: "#FEE38B",
        },
      }}
    >
      <Box
        sx={{
          marginBottom: "20px",
          borderBottom: "1px solid #888",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabs}
          TabIndicatorProps={{
            style: { backgroundColor: "#FDD649" },
          }}
        >
          {renderTabs()}
        </Tabs>
      </Box>

      {renderTabPanels()}
    </Box>
  );
};

export default CustomTabs;

interface ITabData {
  value: number;
  label: React.ReactNode;
  content: React.ReactNode;
  show: boolean;
}
