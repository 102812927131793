import { FC, useState, useRef, useEffect } from "react";
import { Box, Typography, styled } from "@mui/material";
import { useLearnContext } from "./LearnContext";
import { ContentItem, LearningObjective } from "../Common/LearningTypes";
import CourseLearningCard from "./CourseLearningCard";
import CustomButton from "../../Common/CustomButton";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const CourseLearningContent: FC<IProps> = ({ obj, roadmapId, index }) => {
  const { activeSectionId, learningProgress } = useLearnContext();
  const [expandedStates, setExpandedStates] = useState<boolean[]>([]);
  const [isOverflowing, setIsOverflowing] = useState<boolean[]>([]);
  const summaryRefs = useRef<Array<HTMLDivElement | HTMLSpanElement | null>>(
    []
  );

  useEffect(() => {
    const checkOverflow = () => {
      const newIsOverflowing = obj?.summary?.map((_, i) => {
        const ref = summaryRefs.current[i];
        if (ref) {
          return ref.scrollHeight > ref.clientHeight;
        }
        return false;
      });
      setIsOverflowing(newIsOverflowing);
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [obj?.summary]);

  const handleExpandClick = (j: number) => {
    setExpandedStates((prevState) => {
      const newState = [...prevState];
      newState[j] = !newState[j];
      return newState;
    });
  };

  const learningContent = (
    learningProgress?.progress[activeSectionId]?.content || []
  )?.slice(index * 2, index * 2 + 2);

  return (
    <Box>
      <ObjectiveText>{obj.learningObjective}</ObjectiveText>

      <LearningCardWrap>
        {(learningContent as ContentItem[])?.map((content, i) => {
          if (!content.id) {
            console.log(
              "Undefined content",
              learningProgress?.progress[activeSectionId]
            );

            return null;
          }

          return (
            <CourseLearningCard
              key={content.id}
              id={content.id}
              title={content.title}
              link={content.link}
              description={content.snippet}
              thumbnail={content.thumbnail}
              resourceType={content.resourceType}
              status={content.status || false}
              bonusContent={
                learningProgress?.progress[activeSectionId]?.bonusContent || []
              }
              cardIndex={index * 2 + i}
              roadmapId={roadmapId}
            />
          );
        })}
      </LearningCardWrap>

      <SummaryWrap>
        {obj?.summary?.map((s, j) => {
          const isExpanded = expandedStates[j] || false;

          return (
            <Box key={j}>
              <SummaryText
                ref={(el) => (summaryRefs.current[j] = el)}
                expanded={isExpanded}
              >
                {s}
              </SummaryText>
              {isOverflowing[j] && (
                <Box margin="2px 0 0">
                  <CustomButton
                    type="tertiary"
                    size="small"
                    postImg={isExpanded ? <ExpandLess /> : <ExpandMore />}
                    onClick={() => handleExpandClick(j)}
                  >
                    {isExpanded ? "Show Less" : "Show More"}
                  </CustomButton>
                </Box>
              )}
            </Box>
          );
        })}
      </SummaryWrap>
    </Box>
  );
};

export default CourseLearningContent;

interface IProps {
  obj: LearningObjective;
  roadmapId: string;
  index: number;
}

const LearningCardWrap = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  gap: "20px",
  flexWrap: "wrap",
  marginBottom: "20px",
  "&>div": {
    maxWidth: "calc(50% - 20px)",
    minWidth: "240px",
  },
  [theme.breakpoints.down(1100)]: {
    "&>div": {
      maxWidth: "100%",
    },
  },
}));

const ObjectiveText = styled(Typography)(({ theme }) => ({
  color: "#FEFEFE",
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: "1.5rem",
  flexBasis: "100%",
  maxWidth: "80%",
  marginBottom: "14px",
  [theme.breakpoints.down(768)]: {
    maxWidth: "100%",
  },
}));

const SummaryWrap = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  maxWidth: "100%",
  textOverflow: "clip",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  [theme.breakpoints.down(768)]: {
    maxWidth: "100%",
  },
}));

const SummaryText = styled(Typography)<{ expanded: boolean }>(
  ({ expanded }) => ({
    color: "#FEFEFE",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
    overflowWrap: "break-word",
    WebkitLineClamp: expanded ? "unset" : 2,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
  })
);
