import { makeStyles } from "@mui/styles";
import { ChatBotData } from "./tubliChatBotResources";
import { Theme } from "@mui/material";

const useTubliChatBotStyles = makeStyles((theme: Theme) => {
  return {
    tublibot: {
      border: "1px solid transparent",
      background:
        "linear-gradient(#121212, #121212) padding-box, linear-gradient(109.15deg, #FBDA61 0%, rgba(255, 90, 205, 0.84) 86.08%) border-box",
    },
    chatBtn: {
      position: "relative",
      width: 60,
      height: 60,
      margin: "0 18px 18px 0",
      borderRadius: "50%",
      padding: 10,
      cursor: "pointer",
      "& svg": {
        position: "absolute",
        color: "#FEFEFE",
        "&:first-of-type": {
          transform: "scaleX(-1)",
          right: 8,
          top: 7,
        },
        "&:last-of-type": {
          left: 8,
          bottom: 8,
        },
      },
      [theme.breakpoints.down(700)]: {
        width: 40,
        height: 40,
        padding: 0,
        margin: "0 16px 0 0",
        "& svg": {
          width: 13.333,
          height: 13.333,
          position: "absolute",
          "&:first-of-type": {
            transform: "scaleX(-1)",
            right: "6.67px !important",
            top: "6px !important",
          },
          "&:last-of-type": {
            left: "6.67px !important",
            bottom: "6.67px !important",
          },
        },
      },
    },
    //Chat Window
    chatWindowPopper: {
      position: "absolute",
      zIndex: 9999,
      width: "100%",
      display: "flex",
      flexDirection: "row-reverse",
      background: "transparent",
      [theme.breakpoints.down(700)]: {
        display: "flex",
        alignItems: "center",
      },
    },
    chatWindowPaper: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      width: "100%",
      marginBottom: 12,
      border: "1px solid transparent",
      background:
        "linear-gradient(#292929, #292929) padding-box, linear-gradient(109.15deg, #FBDA61 0%, rgba(255, 90, 205, 0.84) 86.08%) border-box",
      "& .chatDivider": {
        background: "linear-gradient(109deg, #FBDA61 0%, #FF5ACD 86.08%) !important",
      },
      "& .chatHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 20px",
        "& div": {
          display: "flex",
          alignItems: "center",
          gap: 10,
        },
        "& p": {
          fontSize: "1rem",
          lineHeight: "1.5rem",
          fontWeight: 500,
        },
        "& svg": {
          color: "#FDD649",
        },
      },
      "& .chatBody": {
        display: "flex",
        flexDirection: "column",
        gap: 20,
        padding: "16px 20px",
        flex: 1,
        overflowY: "auto",
        "& *": {
          fontSize: "0.875rem",
          fontWeight: 500,
          lineHeight: "1.25rem",
        },
        "& .outgoingMsgsWrap": {
          display: "flex",
          textAlign: "left",
          alignSelf: "flex-end",
          justifyContent: "flex-end",
          gap: 6,
          width: "65%",
        },
        "& .isLoadingMsgsWrap": {
          display: "flex",
          textAlign: "left",
          alignSelf: "flex-start",
          gap: 6,
          width: 80,
        },
        "& .incomingMsgsWrap": {
          display: "flex",
          textAlign: "left",
          alignSelf: "flex-start",
          gap: 6,
          width: "91%",
        },
      },
      "& .chatHistoryBody": {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        padding: "16px 0",
        overflowY: "auto",
        "& .chatHistoryWrap": {
          display: "flex",
          flexDirection: "column",
          gap: 8,
          cursor: "pointer",
          "& .chatHistoryCard": {
            display: "flex",
            alignItems: "center",
            gap: 8,
            padding: "0 20px",
            "&>div": {
              width: "100%",
              "& .details": {
                display: "flex",
                justifyContent: "space-between",
                color: "#B7B7B7",
                "& p": {
                  fontSize: "0.75rem",
                  fontWeight: 400,
                  lineHeight: "1rem",
                },
              },
              "& p": {
                maxWidth: 288,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 1,
              },
            },
          },
        },
      },
      "& .chatFooter": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 14,
        gap: 14,
        borderTop: "1px solid #414141",
        width: "100%",
      },
      [theme.breakpoints.down(700)]: {
        width: "100%",
        marginBottom: 0,
        height: "calc(100vh -  49px)",
        borderRadius: 0,
      },
    },
    chatIdleWindowPaper: {
      gap: 8,
      padding: 20,
      height: 120,
      width: 238,
      cursor: "pointer",
      borderRadius: 10,
      marginRight: 18,
    },
    //Chat screen
    outgoingMessage: {
      display: "flex",
      background: "#137DE7",
      color: "#FEFEFE",
      padding: 8,
      borderRadius: "5px 0 5px 5px",
      textAlign: "left",
      alignSelf: "flex-end",
      flex: 1,
      // maxWidth: 183,
    },
    incomingMessage: {
      display: "flex",
      flexDirection: "column",
      border: "1px solid transparent",
      background:
        "linear-gradient(#414141, #414141) padding-box, linear-gradient(109.15deg, #FBDA61 0%, rgba(255, 90, 205, 0.84) 86.08%) border-box",
      padding: 8,
      borderRadius: "0 5px 5px",
      color: "#FEFEFE",
      textAlign: "left",
      alignSelf: "flex-start",
      flex: 1,
      gap: 4,
      "& .error": {
        color: "#f44336",
      },
      "& li": {
        marginLeft: 8,
      },
    },
    typingIndicator: {
      display: "flex",
      gap: 4,
    },
    dot: {
      borderRadius: "50%",
      width: 8,
      height: 8,
      "&:first-of-type": {
        background: "#FEFEFE",
        animation: `$loadingAnimation 1s infinite 0.33s`,
      },
      "&:nth-of-type(2)": {
        background: "#B7B7B7",
        animation: `$loadingAnimation 1s infinite 0.66s`,
      },
      "&:last-of-type": {
        background: "#696969",
        animation: `$loadingAnimation 1s infinite 1s`,
      },
    },
    "@keyframes loadingAnimation": {
      "0%": {
        transform: "scale(1)",
        opacity: 1,
      },
      "50%": {
        transform: "scale(1.2)",
        opacity: 0.2,
      },
      "100%": {
        transform: "scale(1.1)",
        opacity: 1,
      },
    },
    seeMoreButton: {
      background: "none",
      border: "none",
      cursor: "pointer",
      fontSize: "0.875rem",
      color: "#FDD649",
      textAlign: "right",
    },
    chatSuggestions: {
      display: "flex",
      gap: 6,
      "&>div": {
        display: "flex",
        flexDirection: "column",
        gap: 8,
      },
      "& .welcomeMsgs": {
        display: "flex",
        flexDirection: "column",
        gap: 4,
      },
      "& .suggestedQueriesWrap": {
        display: "flex",
        gap: 6,
        alignItems: "flex-start",
        flexWrap: "wrap",
        "& .suggestedQueries": {
          display: "flex",
          border: "1px solid #414141",
          padding: 4,
          color: "#B7B7B7",
          cursor: "pointer",
        },
      },
    },
    leftResizeHandle: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      width: 10,
      cursor: "ew-resize",
      [theme.breakpoints.down(700)]: {
        display: "none",
      },
    },
  };
});

export const chatBotViewStyles = (chatBotData: ChatBotData) => {
  let wrapStyles: any = {
    width: "100%",
    // height: "100%",
    position: "relative",
    flex: 1,
  };

  wrapStyles =
    chatBotData?.chatBotView === "idle popup"
      ? { ...wrapStyles, display: "flex", flexDirection: "column-reverse" }
      : wrapStyles;

  return wrapStyles;
};

export default useTubliChatBotStyles;
