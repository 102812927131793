import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe } from "@stripe/stripe-js";
import EightyTwentyCheckoutForm from "./EightyTwentyCheckoutForm";
import { Dispatch, FC, SetStateAction } from "react";
import { EightyTwentyPaymentDetails } from "./BuyCredits";

// import { stripeAppearance } from "../CustomerOnboardingResources";
const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const EightyTwentyCheckoutWrap: FC<IProps> = ({
  price,
  priceId,
  credits,
  currency,
  currencyId,
  setPaymentDetails,
}: IProps) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "payment",
        appearance: stripeAppearance,
        loader: "always",
        amount: price * 100,
        currency: currencyId,
      }}
    >
      <EightyTwentyCheckoutForm
        credits={credits}
        currency={currency}
        currencyId={currencyId}
        setPaymentDetails={setPaymentDetails}
        price={price}
        priceId={priceId}
      />
    </Elements>
  );
};

export default EightyTwentyCheckoutWrap;

type IProps = {
  price: number;
  priceId: string;
  credits: number;
  currency: string;
  currencyId: string;
  setPaymentDetails: Dispatch<SetStateAction<EightyTwentyPaymentDetails>>;
};

const stripeAppearance: Appearance = {
  theme: "night",
  labels: "floating",
  rules: {
    ".Tab": {
      border: "1px solid #292929",
      borderRadius: "10px",
      padding: "18px 20px",
      color: "#5F6368",
      backgroundColor: "#1E1E1E",
      boxShadow: "none",
    },
    ".Tab:hover": {
      color: "#FEFEFE",
    },
    ".Tab--selected": {
      border: "1px solid #FDD649",
      backgroundColor: "transparent",
      color: "#FEFEFE",
    },
    ".Tab--selected:hover": {
      border: "1px solid #FDD649",
      backgroundColor: "transparent",
      color: "#FEFEFE",
    },
    ".Input": {
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "0px",
      borderBottom: "1px solid #888",
      boxShadow: "none",
      color: "#FEFEFE",
    },
    ".Input:focus": {
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "0px",
      borderBottom: "1px solid #FEFEFE",
      boxShadow: "none",
    },
    ".Label--resting": {
      color: "#888",
      WebkitTextFillColor: "#888",
    },
    ".Label--floating": {
      color: "#FEFEFE",
      WebkitTextFillColor: "#FEFEFE",
    },
    ".Icon": {
      // fill: "#FEFEFE",
    },
    ".Label--empty": {
      color: "#888",
      WebkitTextFillColor: "#888",
    },
    ".Input--invalid": {
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "0px",
      borderBottom: "1px solid #F26663",
      boxShadow: "none",
      color: "#F26663",
    },
    ".AccordionItem": {
      color: "#FEFEFE",
      backgroundColor: "#1E1E1E",
      boxShadow: "none",
      borderRadius: "20px",
    },
  },
  variables: {
    // fontFamily: "Space Grotesk",
    colorPrimary: "#FEFEFE",
    colorBackground: "#1E1E1E",
    colorText: "#888",
    colorDanger: "#F26663",
    colorTextPlaceholder: "#888",

    colorIcon: "#FEFEFE",
    logoColor: "dark",
    iconColor: "#888",
    tabLogoColor: "dark",
    blockLogoColor: "dark",
    tabLogoSelectedColor: "light",
    tabIconColor: "#888",
    tabIconSelectedColor: "#FEFEFE",
    iconChevronDownColor: "#FEFEFE",

    iconCheckmarkColor: "#FEFEFE",

    iconCardErrorColor: "#F26663",
    iconCardCvcErrorColor: "#F26663",
  },
};
