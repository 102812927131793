const DateUtils = {
    queryDateFormat(date: Date) {
        return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`
    },

    getFormattedTime(date: Date) {
        return date.toLocaleTimeString();
    },
    getDate(formattedDate: string) {
        const date = parseInt(formattedDate.substr(6))
        const month = parseInt(formattedDate.substr(4, 2))
        const year = parseInt(formattedDate.substr(0, 4))

        return new Date(year, month - 1, date);
    },
    getFormattedDate(date: Date) {
        var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' } as const;
        return date.toLocaleDateString("en-US", options);
    },
    formatFeedDateForDisplay(feedDate: string) {
        const nth = (d: number) => {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        }
        const monthStr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const date = parseInt(feedDate.substr(6))
        const month = parseInt(feedDate.substr(4, 2))
        return `${date}${nth(date)} ${monthStr[month - 1]}, ${feedDate.substr(0, 4)}`;
    },

    previousDate(feedDate: string) {
        const date = parseInt(feedDate.substr(6))
        const month = parseInt(feedDate.substr(4, 2))
        const year = parseInt(feedDate.substr(0, 4))

        const previousDate = new Date(year, month - 1, date);
        previousDate.setDate(previousDate.getDate() - 1);
        return DateUtils.queryDateFormat(previousDate);
    },

    isOlderThanMonth(dateTime: string) {
        const timestamp = new Date().getTime() - (30 * 24 * 60 * 60 * 1000);
        return parseInt(dateTime) < timestamp;
    },

    isOlderThanXHours(dateTime: string | number, x: number) {
        let timestamp: number;
        
        if (typeof dateTime === 'number') {
            timestamp = dateTime; // If dateTime is already a number, use it as timestamp
        } else {
            timestamp = new Date(dateTime).getTime(); // Convert dateTime to timestamp
        }
        
        const xHoursAgo = new Date().getTime() - (x * 60 * 60 * 1000); // Timestamp of x hours ago
        return timestamp < xHoursAgo;
    }

};

export default DateUtils;