import { Box, Typography, styled } from "@mui/material";
import { useLearnContext } from "./Course/LearnContext";
import noCourse from "../../images/learning/no-course.png";

const EmptyRoadmapPhasesState = () => {
  const { loadingState, roadmapPhasesList } = useLearnContext();

  const showEmptyState =
    roadmapPhasesList?.roadmapState !== "failure" &&
    roadmapPhasesList?.roadmapState !== "incomplete" &&
    !loadingState.creatingRoadmaps &&
    !loadingState.loadingRoadmaps &&
    (!roadmapPhasesList ||
      !roadmapPhasesList.roadmap ||
      roadmapPhasesList.roadmap.phases.length === 0);

  if (!showEmptyState) {
    return null;
  }

  return (
    showEmptyState && (
      <NoCourse>
        <img src={noCourse} alt="No course" />
        <Box>
          <Typography component="h4">No Roadmap Phases Found</Typography>
          <Typography component="h6">
            Use your learning credits to create your own Roadmap and 10x your
            learning.
          </Typography>
        </Box>
      </NoCourse>
    )
  );
};

export default EmptyRoadmapPhasesState;

const NoCourse = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  gap: "6px",
  "& div": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  "& h4": {
    color: "#B7B7B7",
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: "1.5rem",
  },
  "& h6": {
    color: "#888",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1.25rem",
  },
});
