import { userProfile } from "../../../libs/UserProfile";
import { Feed } from "../../../models/feed";
import FT from "../../../libs/FT";

const currentUser = userProfile(localStorage);

const hashCode = function (str: string) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const userInfo = () => {
  return {
    avatarUrl: currentUser.userAvatar(),
    roles: currentUser.roles(),
  };
};

export const saveUserFeedEvent = async (feed: Feed | undefined) => {
  await FT.saveUserEvent(currentUser.userId(), feed?.id, "feed", {
    id: feed?.id,
    title: feed?.title,
    link: feed?.link,
    summary: feed?.summary ?? feed?.description,
    userInfo: userInfo(),
  });
};

export const save8020Event = async (
  roadmapId: string,
  learningPrompt: string,
  image?: string
) => {
  console.log(roadmapId, learningPrompt, image);
  await FT.saveUserEvent(currentUser.userId(), roadmapId, "eightyTwenty", {
    roadmapId: roadmapId,
    image: image,
    learningPrompt: learningPrompt,
    userInfo: userInfo(),
  });
};

export const saveUserProjectIssueEvent = async (
  issueId: number,
  repoId: number,
  title: string,
  url: string
) => {
  await FT.saveUserEvent(currentUser.userId(), issueId, "github", {
    id: issueId,
    repoId: repoId,
    title: title,
    link: url,
    userInfo: userInfo(),
  });
};

export const saveUserProjectEvent = async (
  repoId: number,
  repoName: string
) => {
  await FT.saveUserEvent(currentUser.userId(), repoId, "github", {
    repoId: repoId,
    repoName: repoName,
    userInfo: userInfo(),
  });
};



export const saveUserQuestionEvent = async (
  title: string,
  url: string,
  urlId: string,
  tags: string[],
  publishedDate: string,
  summary: string,
  profileUrl: string,
  estimatedScore: number
) => {
  const id = hashCode(url);
  await FT.saveUserEvent(currentUser.userId(), id, "sof", {
    title,
    url,
    id: urlId,
    tags,
    publishedDate,
    summary,
    profileUrl,
    estimatedScore,
    userInfo: userInfo(),
  });
};

export const saveUserConnectionEvent = async (otherUserId: string) => {
  const id = hashCode(otherUserId);
  await FT.saveUserEvent(currentUser.userId(), id, "connection", {
    otherUserId: otherUserId,
    userInfo: userInfo(),
  });
};

export const saveUserLearningEvent = async (learningObjective: string) => {
  const id = hashCode(currentUser.userId() + learningObjective);
  await FT.saveUserEvent(currentUser.userId(), id, "8020", {
    learningObjective: learningObjective,
  });
};

export const saveUserJdEvent = async (jd: string) => {
  const id = hashCode(jd);
  await FT.saveUserEvent(currentUser.userId(), id, "copilot.jd.raw", {
    jd: jd,
  });
};

export const saveUserJdSummaryEvent = async (summary: any) => {
  await FT.saveUserEvent(
    currentUser.userId(),
    Date.now(),
    "copilot.jd.summary",
    summary
  );
};

export const saveUserInterestedToPair = async (
  requestId: string | undefined,
  otherId: string,
  title: string,
  url: string,
  status: string
) => {
  await FT.saveUserEvent(currentUser.userId(), requestId, "pairingRequest", {
    otherId: otherId,
    title: title,
    link: url,
    status: status,
    userInfo: userInfo(),
  });
};

export const saveUserNewPairingRequest = async (
  issueId: number,
  repoId: number,
  title: string,
  url: string
) => {
  await FT.saveUserEvent(currentUser.userId(), issueId, "pairingRequest", {
    id: issueId,
    repoId: repoId,
    title: title,
    link: url,
    status: "requested",
    userInfo: userInfo(),
  });
};
