
export class GithubRepo {
    url: string;
    name: string;
    description: string;
    topics: string[];
    health: number;
    weeklyActivity: number;
    size: number;
    stars: number;
    languages: string[];
    contributing: string;
    documentation: string;
    license: string;
    prTemplate: string;
    readme: string;
    displayStats: boolean;
    displayNextSteps: boolean;
    avatarUrl?: string;
    growthRate?: number;
    readmeBlob?: any;
    contributingBlob?: any;
    contributors: any;
    tublianCategory?: string

    constructor(url: string,
        name: string,
        description: string,
        topics: string[],
        growthRate: number,
        avatarUrl: string,
        health: number,
        weeklyActivity: number,
        size: number,
        stars: number,
        languages: string[],
        contributing: string,
        documentation: string,
        license: string,
        prTemplate: string,
        readme: string,
        readmeBlob: any,
        contributingBlob: any,
        contributors: any,
        tublianCategory: string,
        displayStats: boolean = true,
        displayNextSteps: boolean = true,
    ) {
        this.url = url;
        this.name = name;
        this.description = description;
        this.topics = topics;
        this.growthRate = growthRate;
        this.avatarUrl = avatarUrl;
        this.health = health;
        this.weeklyActivity = weeklyActivity;
        this.size = size;
        this.stars = stars;
        this.languages = languages;
        this.contributing = contributing;
        this.documentation = documentation;
        this.license = license;
        this.prTemplate = prTemplate;
        this.readme = readme;
        this.displayStats = displayStats;
        this.displayNextSteps = displayNextSteps;
        this.readmeBlob = readmeBlob;
        this.contributingBlob = contributingBlob;
        this.contributors = contributors;
        this.tublianCategory = tublianCategory;

    }

    static createRepo(repoJson: any) {
        const languages = repoJson.languages
            .concat(repoJson.scripts)
            .concat(repoJson.others)
            .filter((l: string) => l.trim().length > 0);

        // const readmeBlog = repoJson.readmeBlob ? JSON.parse(repoJson.readmeBlob) : {};
        let readmeBlog = {};

        if (repoJson?.readmeBlob) {
            try {
                readmeBlog = JSON.parse(repoJson.readmeBlob);
            } catch (error) {
                console.error('Error parsing readmeBlob as JSON:', error);
                //   readmeBlog = { summary: repoJson.readmeBlob };
            }
        }


        // console.log(">>> key points1 ", repoJson.contributingBlob);
        // const contributingBlob = repoJson.contributingBlob
        // ? JSON.parse(repoJson.contributingBlob)
        // : {};
        let contributingBlob = {};

        if (repoJson?.contributingBlob) {
            try {
                contributingBlob = JSON.parse(repoJson.contributingBlob);
            } catch (error) {
                console.error('Error parsing contributingBlob as JSON:', error);
            }
        }
        const contributors = JSON.parse(repoJson.contributorsJson || "[]");
        const repo = new GithubRepo(
            repoJson.url,
            repoJson.fullName,
            repoJson.description,
            repoJson.topics,
            repoJson.growthRate,
            repoJson.avatarUrl,
            repoJson.health,
            repoJson.weeklyActivity,
            repoJson.size,
            repoJson.stars,
            languages,
            repoJson.contributing,
            repoJson.documentation,
            repoJson.license,
            repoJson.prTemplate,
            repoJson.readme,
            readmeBlog,
            contributingBlob,
            contributors,
            repoJson.tublianCategory);
        return repo;
    }

    readmeSummary() {
        return this.readmeBlob?.summary || "";
    }

    keyPointsForContributors() {
        return this.contributingBlob?.key_points || [];
    }

    setupForContribution() {
        const toString: (prop: any) => string = (prop: any) => {
            if (prop) {
                if (Array.isArray(prop)) {
                    return prop.join(". ");
                }
                return prop;
            }
            return "Not able to get setup information. Please explore the project documentation(links are on the sidebar)";
        }
        return toString(this.contributingBlob?.setup);
    }

    userName() {
        return this.name.split("/")[0];
    }

    repoName() {
        return this.name.split("/")[1];
    }

    repoLanguages() {
        return this.languages.join(", ")
    }

    repoActivity() {
        return this.weeklyActivity.toFixed(2) + " commits/wk"
    }

    healthStatus() {
        return this.health > 70 ? "good" : "bad"
    }

    repoTopics() {
        return this.topics.join(", ")
    }
}