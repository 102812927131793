import { Box } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CustomButton from "../../Common/CustomButton";

const ShowMoreButton = ({ showMore, showLess, isShowingAll }) => {
  const handleClick = () => {
    if (isShowingAll) {
      showLess();
    } else {
      showMore();
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
      <CustomButton
        type="tertiary"
        size="small"
        onClick={handleClick}
        postImg={
          isShowingAll ? (
            <ExpandLess htmlColor="#FDD649" />
          ) : (
            <ExpandMore htmlColor="#FDD649" />
          )
        }
      >
        {isShowingAll ? "Show Less" : "Show More"}
      </CustomButton>
    </Box>
  );
};

export default ShowMoreButton;
