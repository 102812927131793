import { Box, Typography, styled } from "@mui/material";
import ActiveChallenges from "./ActiveChallenges";
import PreviousChallenges from "./PreviousChallenges";
import {
  activeChallenges,
  previousChallenges,
} from "./ChallengePresentationData";
import CustomTabs from "../../Common/CustomTabs";

const ChallengesPresentation = ({ isPublic }: { isPublic: boolean }) => {
  const tabsData = [
    {
      value: 1,
      show: activeChallenges.length > 0,
      label: (
        <Typography>
          Active Challenges
          <ChallengeCount>{activeChallenges.length}</ChallengeCount>
        </Typography>
      ),
      content: (
        <ActiveChallengeList style={{ marginTop: 0, display: "flex", gap: 25 }}>
          <ActiveChallenges />
        </ActiveChallengeList>
      ),
    },
    {
      value: 2,
      show: previousChallenges.length > 1,
      label: (
        <Typography>
          Previous Challenges
          <ChallengeCount>{previousChallenges.length}</ChallengeCount>
        </Typography>
      ),
      content: (
        <PreviousChallengeList
          style={{ marginTop: 0, display: "flex", gap: 25 }}
        >
          <PreviousChallenges />
        </PreviousChallengeList>
      ),
    },
  ];

  return (
    <>
      <HeaderWrap style={{ margin: "0 0 28px" }}>
        <Subtitle>
          Compete with other software developers to win prizes and build
          streetcred
        </Subtitle>
      </HeaderWrap>
      <CustomTabs tabsData={tabsData} />
    </>
  );
};
export default ChallengesPresentation;

// interface PrimaryColumnProps {
//   isMobile: boolean;
// }

const ActiveChallengeList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.down(860)]: {
    width: "100%",
  },
  [theme.breakpoints.down(736)]: {
    width: "100%",
  },
  "& .curatedProjectsWrap": {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    maxWidth: 628,
    [theme.breakpoints.down(1024)]: {
      maxWidth: 586,
    },
    [theme.breakpoints.down(700)]: {
      maxWidth: "100%",
    },
  },
}));

const PreviousChallengeList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  // paddingTop: 28,
  [theme.breakpoints.down(860)]: {
    width: "100%",
  },
  [theme.breakpoints.down(736)]: {
    width: "100%",
  },
}));

const HeaderWrap = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: 28,
  gap: 18,
  [theme.breakpoints.down(700)]: {
    margin: "auto",
    marginBottom: 20,
    gap: 20,
    width: "100%",
    maxWidth: 460,
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: 500,
  color: "#888",
  [theme.breakpoints.down(700)]: {
    fontSize: "1rem",
  },
}));

const ChallengeCount = styled("span")({
  backgroundColor: "#F26663",
  color: "#FEFEFE",
  padding: "2px 6px",
  borderRadius: 5,
  marginLeft: 5,
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
});
