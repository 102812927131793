import { FC, useState } from "react";
import { Box, Chip, Typography, styled } from "@mui/material";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { useLearnContext } from "./Course/LearnContext";
import { RoadmapListItem } from "./Common/LearningTypes";
import CoursePresentationCard from "./Course/CoursePresentationCard";
import CustomButton from "../Common/CustomButton";
import CustomShareBtn from "../Common/CustomShareBtn.tsx";
import { userProfile } from "../../libs/UserProfile";
import TextUtils from "../../libs/TextUtils";
import { closeSvg, infoSvg } from "../UserHome/Base/SVG";
import Spinner from "../Common/Spinner";
import { logEvent } from "../../libs/amplitude";
import GithubClient from "../../libs/GithubClient";
import { RefCode } from "../../libs/refCode";

const SharedRoadmapPhasesList: FC<IProps> = ({
  collapsed = true,
  roadmapList,
  roadmapIndex,
  userId,
  viewOnly = false,
  parentComponent,
  isPublic = false,
}) => {
  const { roadmap, roadmapId, roadmapState } = roadmapList;
  const userCache = userProfile(localStorage);
  const userName = userId || userCache.userId();
  const signInCode = RefCode.code();

  const {
    loadingState,
    handleGetRoadmap,
    setPopupStateData,
    handleDeleteRoadmap,
    triggerSearchRoadmaps,
    setError,
  } = useLearnContext();

  const [roadmapRetries, setRoadmapRetries] = useState(0);

  const cloneRoadmap = () => {
    const { learningPrompt } = roadmap;
    // console.log("🚀 ~ cloneRoadmap:", learningPrompt);

    if (isPublic) {
      logEvent("8020", "public-view", {
        action: "Log In to clone roadmap",
        learningPrompt,
        originalOwner: userName,
        cloner: userCache.userId(),
      });
      
      GithubClient.signIn(signInCode);
      return false;
    }

    logEvent("8020", {
      action: "cloneRoadmap",
      learningPrompt,
      originalOwner: userName,
      cloner: userCache.userId(),
    });
    triggerSearchRoadmaps(learningPrompt);
  };

  const handleResumeFetch = async () => {
    if (roadmapRetries >= 2) {
      await handleGetRoadmap(roadmapId, userName); // 3rd and final try
      if (roadmapState === "failure") {
        handleDeleteRoadmap(roadmapId, false); // Delete if 3rd try fails
        setRoadmapRetries(0); // Reset retry count
        setError({
          error: true,
          errorMsg:
            "Unable to resume roadmap generation. Please try a different learning prompt",
        });
      }
    } else {
      setRoadmapRetries((prev) => prev + 1);
      handleGetRoadmap(roadmapId, userName);
    }
  };

  const showBtns =
    (roadmapIndex !== 0 || roadmapList.roadmapState !== "incomplete") &&
    parentComponent !== "roadmapView";

  const [expanded, setExpanded] = useState(!collapsed);

  const toggleRoadmap = () => {
    if (parentComponent !== "roadmapView") {
      setExpanded(!expanded);
    }
  };

  const handleDelete = (roadmap: RoadmapListItem) => {
    setPopupStateData({
      isActive: true,
      roadmap,
      feedbackData: {},
      popupMode: "delete",
    });
  };

  return (
    <Box sx={{ width: "100%" }} key={roadmapId}>
      <RoadmapTitle>
        <Typography onClick={toggleRoadmap}>
          {TextUtils.capsFirstLetter(roadmap?.learningPrompt)}
        </Typography>

        <Box className="titleBtns">
          {showBtns && (
            <>
              <Box className="shareBtn">
                <CustomShareBtn
                  url={`https://www.tublian.com/learn/${roadmapId}`}
                  title={roadmap.learningPrompt}
                  description={`I found this learning roadmap and I thought you would like it`}
                />
              </Box>
              <Box
                className="closeBtn"
                onClick={() => handleDelete(roadmapList)}
              >
                {closeSvg}
              </Box>
              {expanded ? (
                <KeyboardArrowUpOutlined
                  htmlColor="#FEFEFE"
                  onClick={toggleRoadmap}
                />
              ) : (
                <KeyboardArrowDownOutlined
                  htmlColor="#FEFEFE"
                  onClick={toggleRoadmap}
                />
              )}
            </>
          )}
        </Box>
      </RoadmapTitle>

      {expanded && (
        <>
          {/* <Divider /> */}
          <RoadmapContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
                border: "1px solid #1E1E1E",
                borderRadius: "15px",
                padding: "16px 20px",
                margin: "0 0 12px",
              }}
            >
              <Chip
                label="Prompt"
                variant="outlined"
                sx={{
                  background: "#1E1E1E",
                  padding: "4px 6px",
                  border: "none",
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  fontWeight: 400,
                }}
              />
              <Typography
                sx={{
                  color: "#B7B7B7",
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  lineHeight: "1.25rem",
                }}
              >
                {TextUtils.capsFirstLetter(roadmap?.learningPrompt)}
              </Typography>

              {loadingState.creatingRoadmaps &&
                (parentComponent === "roadmapView" || viewOnly) && (
                  <Spinner size={18} pryColor="#FEFEFE" secColor="#121212" />
                )}

              {!loadingState.creatingRoadmaps &&
                (parentComponent === "roadmapView" || viewOnly) && (
                  <GenerateWrap>
                    <Box>
                      {infoSvg}
                      <Typography>
                        Like the prompt? Create your own roadmap
                      </Typography>
                    </Box>
                    <CustomButton
                      type="grey"
                      style={{ padding: "4px 6px" }}
                      onClick={cloneRoadmap}
                    >
                      Create
                    </CustomButton>
                  </GenerateWrap>
                )}
            </Box>
            <Box className="roadmapPhases">
              {roadmap?.phases?.map((p, i) => (
                <CoursePresentationCard
                  key={i}
                  course={{
                    id: p.id,
                    learningPrompt: roadmap.learningPrompt,
                    phaseRoadmapId: roadmapId,
                    phaseType: "normal",
                    imageUrl: roadmap.image || undefined,
                    courseName: p.phaseTitle,
                    description: p.overview,
                    stacks: p.tags,
                    courseTopics: p.tags,
                    structure: p.sections.map((s) => s.title),
                    sections: new Map(p.sections.map((s, j) => [j + 1, s])),
                  }}
                  completedCnt={0}
                  sectionsCnt={3}
                  btnText={`Phase ${i + 1}`}
                  imageUrl={roadmap.image}
                  viewOnly={parentComponent === "roadmapView" ? true : viewOnly}
                />
              ))}
            </Box>
            {roadmapState === "incomplete" &&
              !loadingState.loadingRoadmaps &&
              !loadingState.creatingRoadmaps && (
                <Box className="resumeBtn">
                  <CustomButton
                    onClick={() => handleGetRoadmap(roadmapId, userName)}
                    type="primary"
                    size="medium"
                  >
                    Resume roadmap generation
                  </CustomButton>
                </Box>
              )}

            {roadmapState === "failure" &&
              !loadingState.loadingRoadmaps &&
              !loadingState.creatingRoadmaps && (
                <Box className="resumeBtn">
                  <CustomButton
                    onClick={handleResumeFetch}
                    disabled={roadmapRetries >= 2}
                    type="grey"
                    size="medium"
                  >
                    Restart roadmap generation
                  </CustomButton>
                </Box>
              )}
          </RoadmapContent>
        </>
      )}
    </Box>
  );
};

export default SharedRoadmapPhasesList;

type ParentComponent = "roadmapView" | "roadmapListView";

interface IProps {
  collapsed: boolean;
  roadmapList: RoadmapListItem;
  roadmapIndex: number;
  userId?: string;
  viewOnly?: boolean;
  parentComponent: ParentComponent;
  isPublic?: boolean;
}

const RoadmapTitle = styled(Box)(({ theme }) => ({
  padding: "16px",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "flex-start",
  cursor: "pointer",
  "& .titleBtns": {
    display: "flex",
    alignItems: "center",
    color: "#FEFEFE",
    gap: "20px",
  },
  "& .shareBtn": {
    display: "none",
    width: "18px",
    height: "18px",
    "& svg": {
      color: "#FEFEFE",
    },
  },
  "& .closeBtn": {
    display: "none",
    width: "18px",
    height: "18px",
  },
  "& p": {
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflowWrap: "break-word",
    overflow: "hidden",
    color: "#FEFEFE",
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: "1.5rem",
    width: "80%",
  },
  "&:hover": {
    "& .shareBtn": {
      display: "flex",
      alignItems: "center",
    },
    "& .closeBtn": {
      display: "flex",
      alignItems: "center",
    },
  },
  [theme.breakpoints.down(700)]: {
    "& .shareBtn": {
      display: "flex",
      alignItems: "center",
    },
    "& .closeBtn": {
      display: "flex",
      alignItems: "center",
    },
    "& p": {
      color: "#FEFEFE",
      fontSize: "0.875rem",
      fontWeight: 700,
      lineHeight: "1.25rem",
      width: "65%",
    },
  },
}));

const RoadmapContent = styled(Box)({
  padding: "16px",
  borderTop: "1px solid #1E1E1E",
  "& .roadmapPhases": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "8px 0 0",
  },
  "& .resumeBtn": {
    marginTop: "12px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
});

const GenerateWrap = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "14px",
  padding: "8px 12px",
  background: "#1E1E1E",
  borderRadius: "5px",
  marginTop: "8px",
  "& div": {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  "& svg": {
    color: "#FDD649",
    width: "18px",
    height: "18px",
  },
  "& p": {
    color: "#FEFEFE",
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: "20px",
  },
});
