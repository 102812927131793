import { ChangeEvent, FC, cloneElement, useEffect, useRef } from "react";
import {
  Box,
  Divider,
  Icon,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { arrowLeftSvg, editSvg, sendSvg } from "../../UserHome/Base/SVG";
import TublibotAvatar from "./TublibotAvatar";
import useTubliChatBotStyles from "./tubliChatBotStyles";
import { Close } from "@mui/icons-material";
import {
  IOnClose,
  fetchTubliChatBotChatHistory,
  handleMouseDown,
  handleSeeMoreClick,
  handleSendMsg,
  minCharCnt,
  minMsgLineCnt,
} from "./tubliChatBotResources";
import ChatSuggestions from "./ChatSuggestions";
import { useChatBotContext } from "../../Context/ChatBotContext";

const ChatWindow: FC<IOnClose> = ({ onClose }) => {
  const classes = useTubliChatBotStyles();
  const chatBodyRef = useRef<HTMLDivElement>(null);

  const { chatBotData, setChatBotData } = useChatBotContext();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const chatBotMessage = e.target.value;
    const inputError = {
      error: false,
      errMsg: "",
    };
    setChatBotData((prevData) => ({ ...prevData, inputError, chatBotMessage }));
  };

  const handleNewChat = () => {
    setChatBotData((prevData) => ({
      ...prevData,
      chatBotView: "chat view",
      chatBotMessage: "",
      currentConversationId: "",
      messagesList: [],
    }));
  };

  const handleHistoryClick = () => {
    fetchTubliChatBotChatHistory(setChatBotData);
    setChatBotData((prevData) => ({
      ...prevData,
      chatBotView: "history view",
    }));
  };

  useEffect(() => {
    // Scroll to the bottom when a message is sent and a reply is loading
    if (chatBotData?.messagesList?.length > 0) {
      chatBodyRef.current?.scrollTo(0, chatBodyRef.current.scrollHeight);
    }
  }, [chatBotData?.messagesList]);

  return (
    <Paper className={classes.chatWindowPaper}>
      <Box
        className={classes.leftResizeHandle}
        onMouseDown={(e: React.MouseEvent<HTMLDivElement>) =>
          handleMouseDown(e, chatBotData, setChatBotData)
        }
      />
      <Box className="chatHeader">
        <Box>
          {chatBotData?.chatBotHistory?.length > 0 && (
            <Box
              style={{ marginRight: 9, cursor: "pointer" }}
              onClick={handleHistoryClick}
            >
              {arrowLeftSvg}
            </Box>
          )}
          <TublibotAvatar size={32} />
          <Typography>Tublibot</Typography>
        </Box>
        <Box>
          <Box
            style={{ marginRight: 26, cursor: "pointer" }}
            onClick={handleNewChat}
          >
            {editSvg}
          </Box>
          <Close
            style={{ cursor: "pointer" }}
            htmlColor="#FDD649"
            onClick={onClose}
          />
        </Box>
      </Box>

      <Divider className="chatDivider" />

      <div className="chatBody" ref={chatBodyRef}>
        {chatBotData?.currentConversationId?.length === 0 && (
          <ChatSuggestions />
        )}
        {chatBotData?.messagesList?.map((message, i) => (
          <Box
            key={i}
            className={
              message.isLoading
                ? "isLoadingMsgsWrap"
                : message.type === "outgoing"
                ? "outgoingMsgsWrap"
                : "incomingMsgsWrap"
            }
          >
            {(message.type === "incoming" || message.type === "error") && (
              <TublibotAvatar />
            )}
            <Box style={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {message.type === "incoming" && (
                <Typography
                  style={{
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    lineHeight: "1rem",
                    color: "#B7B7B7",
                  }}
                >
                  Tublibot
                </Typography>
              )}
              <Box
                key={i}
                className={
                  message.type === "outgoing"
                    ? classes.outgoingMessage
                    : classes.incomingMessage
                }
              >
                {message.isLoading ? (
                  <Box className={classes.typingIndicator}>
                    <span className={classes.dot}></span>
                    <span className={classes.dot}></span>
                    <span className={classes.dot}></span>
                  </Box>
                ) : (
                  <>
                    {message.isTruncated && message.type !== "outgoing" ? (
                      <>
                        {message.text
                          .slice(0, minMsgLineCnt)
                          .map((line: string, i: number) => {
                            if (line.startsWith("- ")) {
                              return <li key={i}>{line.substring(2)}</li>;
                            } else if (line.trim() !== "") {
                              return <Typography key={i}>{line}</Typography>;
                            }
                            return null;
                          })}
                        <button
                          onClick={() => handleSeeMoreClick(i, setChatBotData)}
                          className={classes.seeMoreButton}
                        >
                          See More
                        </button>
                      </>
                    ) : message.text.length > minMsgLineCnt &&
                      message.type !== "outgoing" ? (
                      <>
                        {message.text.map((line: string, i: number) => {
                          if (line.startsWith("- ")) {
                            return <li key={i}>{line.substring(2)}</li>;
                          } else if (line.trim() !== "") {
                            return <Typography key={i}>{line}</Typography>;
                          }
                          return null;
                        })}
                        <button
                          onClick={() => handleSeeMoreClick(i, setChatBotData)}
                          className={classes.seeMoreButton}
                        >
                          See Less
                        </button>
                      </>
                    ) : (
                      <Typography className={message.type}>
                        {message.text}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </Box>
            {message.type === "outgoing" && <TublibotAvatar type="user" />}
          </Box>
        ))}
      </div>

      <Box className="chatFooter">
        <TextField
          autoComplete="off"
          variant="standard"
          fullWidth
          InputProps={{ disableUnderline: true, id: "chatBotMessage" }}
          placeholder="Write a message"
          value={chatBotData?.chatBotMessage}
          onChange={handleInputChange}
          error={chatBotData?.inputError?.error}
          label={chatBotData?.inputError?.errMsg || ""}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSendMsg(chatBotData, setChatBotData);
            }
          }}
        />
        <Icon
          onClick={() => handleSendMsg(chatBotData, setChatBotData)}
          style={{ cursor: "pointer" }}
        >
          {cloneElement(sendSvg, {
            style:
              !chatBotData.chatBotMessage ||
              chatBotData.chatBotMessage.length < minCharCnt
                ? { color: "#707070" }
                : { color: "#FDD649" },
          })}
        </Icon>
      </Box>
    </Paper>
  );
};

export default ChatWindow;
