import { featuresData } from "./DevelopersPageData";
import { Box, Typography, styled } from "@mui/material";
import { featuresSvg } from "../../UserHome/Base/SVG";

const BecomeFutureReadyCard = ({
  item,
  index,
}: {
  item: FutureReadyProps;
  index: number;
}) => {
  return (
    <div>
      <FutureReadyWrapper key={index} item={item}>
        <div className="flex1">
          {item.subImage && <ComingSoonBtn>Coming Soon</ComingSoonBtn>}
          <h1>{item.header}</h1>
          <p>{item.subheader}</p>
        </div>
        <div className="flex2">
          <div className="illustration">
            <img
              src={item.img}
              alt={item.alt}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </FutureReadyWrapper>
    </div>
  );
};

const Features = () => {
  return (
    <Wrapper>
      <div>
        <Header>
          <button>
            <span>{featuresSvg}</span>Features
          </button>
          <h2>Unleashing the Creator in Every Developer</h2>
          <FutureReadySubtext>
            Equipping Developers to Lead the Future: AI Doesn't Replace—It
            Empowers
          </FutureReadySubtext>
        </Header>
      </div>

      <FutureReadyContainer>
        {featuresData.map((m, i) => (
          <BecomeFutureReadyCard item={m} index={i} key={i} />
        ))}
      </FutureReadyContainer>
    </Wrapper>
  );
};

export default Features;

interface FutureReadyProps {
  img: string;
  header: string;
  subheader: React.ReactNode;
  alt?: string;
  id: number;
  subImage?: boolean;
}

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  paddingTop: 80,
  [theme.breakpoints.down(800)]: {
    paddingTop: 20,
  },
  "&>button": {
    color: "#fefefe",
    background: "linear-gradient(to bottom, #222325 0%, #383A3D 100%)",
    padding: "4px 20px",
    display: "flex",
    gap: 8,
    alignItems: "center",
    border: "1px solid #222325",
    borderRadius: 20,
  },
  "&>h2": {
    margin: "auto",
    width: 831,
    fontSize: "48px",
    textAlign: "center",
    lineHeight: "72px",
    color: "#fefefe",
    fontWeight: 700,
    padding: "28px 0 20px 0",
    [theme.breakpoints.down(1025)]: {
      fontSize: 40,
      lineHeight: "51.04px",
      width: "783px",
    },
    [theme.breakpoints.down(800)]: {
      fontSize: 30,
      lineHeight: "45px",
      width: "100%",
    },
  },
  "&>p": {
    width: 739,
    margin: "auto",
    textAlign: "center",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "32px",
    color: "#fefefe",
    paddingBottom: 115,

    [theme.breakpoints.down(1025)]: {
      width: "783px",
      paddingBottom: 60,
    },
    [theme.breakpoints.down(800)]: {
      fontSize: 14,
      lineHeight: "20px",
      width: "100%",
    },
  },
}));

const FutureReadyContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 100,
  overflowY: "auto",
  [theme.breakpoints.down(1025)]: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: 100,
    margin: "0 0",
  },

  [theme.breakpoints.down(600)]: {
    margin: "0",
    gap: 28,
  },
}));

const FutureReadySubtext = styled(Typography)(({ theme }) => ({
  width: 511,
  marginBottom: 100,
  [theme.breakpoints.down(900)]: {
    fontSize: 30,
    lineHeight: "45px",
    width: 240,
  },
}));

const Wrapper = styled("section")(({ theme }) => ({
  paddingBottom: "80px",
  [theme.breakpoints.down(800)]: {
    padding: "40px 0",
  },
}));

const FutureReadyWrapper = styled(Box)<{ item: FutureReadyProps }>(
  ({ theme, item }) => ({
    display: "flex",
    justifyContent: "space-between",
    color: "#fefefe",
    alignItems: "center",
    borderRadius: 20,
    gap: 40,
    overflow: "hidden",
    flexDirection: item.id === 2 ? "row-reverse" : "row",
    // height: "100vh",
    // scrollSnapAlign: "start",

    [theme.breakpoints.down(1025)]: {
      flexDirection: "column",
      gap: 28,
      height: "fit-content",
      width: "744px",
      margin: "auto",
    },

    [theme.breakpoints.down(800)]: {
      height: "fit-content",
      width: "100%",
      margin: "auto",
    },
    "& .flex1": {
      width: "50%",

      [theme.breakpoints.down(1025)]: {
        width: "100%",
      },

      "&>h1": {
        fontSize: 36,
        fontWeight: 700,
        lineHeight: "45.94px",
        marginBottom: 20,

        [theme.breakpoints.down(720)]: {
          fontSize: 24,
          lineHeight: "34px",
        },
      },

      "&>p": {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "24px",
        [theme.breakpoints.down(1025)]: {
          width: "675px",
        },
        [theme.breakpoints.down(800)]: {
          width: "100%",
        },
      },
    },

    "& .flex2": {
      width: "50%",
      [theme.breakpoints.down(1025)]: {
        width: "100%",

        justifyContent: "flex-end",
      },

      "&> .illustration": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        zIndex: 1,
        [theme.breakpoints.down(900)]: {
          height: "100%",
          width: "100%",
        },
      },
    },
  })
);

const ComingSoonBtn = styled("div")(({ theme }) => ({
  color: "#fefefe",
  background: "linear-gradient(to bottom, #222325 0%, #383A3D 100%)",
  padding: "4px 20px",
  display: "flex",
  gap: 8,
  alignItems: "center",
  border: "1px solid #222325",
  borderRadius: 20,
  width: "fit-content",
  marginBottom: 20,
}));
