import { CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/system";

const MainContainer = styled("main")({
  backgroundColor: "transparent",
});

const RowContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  width: "100%",
});

const ColumnContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexBasis: "100%",
  flex: "1",
  justifyContent: "center",
  alignItems: "center",
});

const MsgTypography = styled(Typography)({
  marginTop: "10px",
  marginBottom: "10px",
  color: "#F59C0B",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: 1.875,
});

const JustLoading = ({ msg, size = 120 }: { msg?: string; size?: number }) => (
  <MainContainer>
    <RowContainer>
      <ColumnContainer>
        <CircularProgress color="secondary" size={size} />
      </ColumnContainer>
    </RowContainer>
    {msg && (
      <RowContainer>
        <ColumnContainer>
          <MsgTypography variant="h2">{msg}</MsgTypography>
        </ColumnContainer>
      </RowContainer>
    )}
  </MainContainer>
);

export default JustLoading;
