import { Fragment, useState } from "react";
import { GithubIssue } from "../../../models/github-issue";
import CustomButton from "../../Common/CustomButton";
import { IGitHubIssueForList } from "../../Projects/types";
import { useLearnContext } from "./LearnContext";
import ProjectsCard from "../../Projects/ProjectsList/ProjectsCard";
import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { ExpandMore } from "@mui/icons-material";
import { IssuesTasksSkeleton } from "../Skeletons/Skeletons";

const IssuesLearningTasks = () => {
  const { searchResults, loadingState } = useLearnContext();
  const [maxTasksToShow, setmaxTasksToShow] = useState(3);

  const showMore = () => {
    setmaxTasksToShow(maxTasksToShow + 2);
  };

  const isLoadingTasks =
    loadingState.searchStatus === "not_searched" ||
    loadingState.searchStatus === "searching";

  function renderTasks() {
    return <>{isLoadingTasks ? <IssuesTasksSkeleton /> : renderTaskCards()}</>;
  }

  function renderTaskCards() {
    return searchResults?.slice(0, maxTasksToShow).map((issue) => (
      <Fragment key={issue.entityId}>
        <ProjectsCard
          type="pathway_task"
          language={getIssueTags(issue)}
          score={issue.estimatedCred}
          issue={issue.title}
          repoId={getIssueId(issue)}
          repoName={issue.repo.name}
          repoDescription={issue.repo.description}
          repoCategory={issue.repo.tublianCategory}
          githubUrl={issue.url}
          issueLink={`/project/${issue.repoId}/${getIssueId(issue)}`}
          projectRepoLink={`/project/${issue.repoId}`}
          status="incomplete"
        />
      </Fragment>
    ));
  }

  function renderLoadMoreButton() {
    return (
      <Box>
        {maxTasksToShow < (searchResults?.length || 0) && (
          <LoadMore>
            <CustomButton
              type="tertiary"
              size="small"
              postImg={<ExpandMore />}
              onClick={showMore}
            >
              Load more open source issues
            </CustomButton>
          </LoadMore>
        )}
      </Box>
    );
  }

  function getIssueTags(issue: IGitHubIssueForList | GithubIssue) {
    const repoCategory = issue.repo.tublianCategory;
    return repoCategory === "internship-projects"
      ? ["Tublian Pick", ...issue.repo.languages]
      : issue?.repo?.languages?.length > 0
      ? [...issue.repo.languages]
      : [];
  }

  function getIssueId(issue: IGitHubIssueForList | GithubIssue) {
    return "issueId" in issue ? issue.issueId : issue.id;
  }

  return (
    <>
      {renderTasks()}
      {renderLoadMoreButton()}
    </>
  );
};

export default IssuesLearningTasks;

const LoadMore = styled(Box)({
  display: "flex",
  // justifyContent: "center",
  marginTop: 6,
  "& button span div": {
    width: 24,
    height: 24,
  },
});
