import { styled, Typography, Box } from "@mui/material";
import amazon from "../../../images/landing/amazon.svg";
import discord from "../../../images/landing/discordCompany.svg";
import linkedin from "../../../images/landing/linkedinCompany.svg";
import google from "../../../images/landing/googleCompany.svg";
import youtube from "../../../images/landing/youtubeCompany.svg";
import github from "../../../images/landing/githubCompany.svg";
import boostSpeed from "../../../images/landing/boostSpeed.png";
import evolvingCredibility from "../../../images/landing/evolvingCredibility.svg";
import creatorIllustration from "../../../images/landing/creatorIllustration.png";
import buildAtScale from "../../../images/landing/buildAtScale.png";
import becomeCreator from "../../../images/landing/becomeCreator.png";
import discoverOpportunitiesIllustration from "../../../images/landing/discoverOpportunitiesIllustration.png";
import discoverOpportunities from "../../../images/landing/discoverOpportunities.png";
import buildAtScaleIllustration from "../../../images/landing/buildAtScaleIllustration.png";
import { Link } from "react-router-dom";
import { smallArrowRightSvg } from "../../UserHome/Base/SVG";

export const StayRelevantSubheader = () => (
  <>
    <Typography>
      The future doesn't just notice; it rewards. Skyrocket your career and
      seize opportunities generated by the AI revolution. Use Tublian's
      personalized AI copilot to learn, grow, and build online credibility.
      Stand out with Tublian Streetcred—a credit score for software developers
      determined by their work and skills, not by years of experience.
    </Typography>

    <LearnMoreLink style={{ marginTop: 20 }}>
      <Link to="/streetcred">
        Learn more
        <span>{smallArrowRightSvg}</span>
      </Link>
    </LearnMoreLink>
  </>
);

export const BuildSoftwareSubheader = () => (
  <>
    <Typography>
      Combine your Streetcred with Tublian AI agents to find matching projects
      and maximize your income. Tublian's autonomous AI agents constantly seek
      opportunities for you, allowing you to focus on what matters most: your
      passion and creativity.
    </Typography>

    <LearnMoreLink style={{ marginTop: 20 }}>
      <Link to="/discover-opportunities">
        Learn more
        <span>{smallArrowRightSvg}</span>
      </Link>
    </LearnMoreLink>
  </>
);

export const BuildStreetcredSubheader = () => (
  <>
    <Typography>
      As AI makes software cheaper and faster, the demand for software will
      increase. With Tublian OS, you can tackle multiple freelance projects,
      handle large-scale software development, and significantly boost your
      income. Tublian OS combines the power of multiple autonomous AI agents to
      simplify building and project management.
    </Typography>

    <LearnMoreLink style={{ marginTop: 20 }}>
      <Link to="/tublian-os">
        Learn more
        <span>{smallArrowRightSvg}</span>
      </Link>
    </LearnMoreLink>
  </>
);

export const companiesData = [
  {
    image: github,
    alt: "github",
  },
  {
    image: youtube,
    alt: "youtube",
  },
  {
    image: amazon,
    alt: "amazon",
  },
  {
    image: linkedin,
    alt: "linkedin",
  },
  {
    image: discord,
    alt: "discord",
  },

  {
    image: google,
    alt: "google",
  },
];

export const techJourneyEmpowermentData = [
  {
    img: boostSpeed,
    header: "Speed Matters",
    subheader: "To remain in demand, we must rapidly learn, create, and adapt",
    alt: "Boost Speed & Relevance",
    id: 1,
  },
  {
    img: evolvingCredibility,
    header: "Certifications Fall Short",
    subheader:
      "While AI dominates coding tests and certifications, developers must carve out a fresh path to credibility.",
    alt: "Evolving Credibility",
    id: 2,
  },
];

export const featuresData = [
  {
    img: creatorIllustration,
    header: "Become a Creator",
    subheader: <StayRelevantSubheader />,
    alt: "Become a Creator",
    id: 1,
  },

  {
    img: discoverOpportunitiesIllustration,
    subImage: true,
    header: "Discover Opportunities",
    subheader: <BuildSoftwareSubheader />,
    alt: "Discover Opportunities",
    id: 2,
  },
  {
    img: buildAtScaleIllustration,
    subImage: true,
    header: "Build At Scale",
    subheader: <BuildStreetcredSubheader />,
    alt: "Build At Scale",
    id: 3,
  },
];

export const ourPlanOne = [
  {
    img: becomeCreator,
    header: "Become a Creator",
    subheader:
      "Transforming developers into software Innovators. Harness AI to enhance their capabilities and seize the opportunities it creates.",
    alt: "Become a Creator",
    id: "1",
  },
  {
    img: discoverOpportunities,
    header: "Discover Opportunities",
    subheader:
      "Showcase your skills with Tublian Streetcred to win projects, or let the Tublian BizDev AI Agent find matching opportunities and generate winning proposals in minutes.",
    alt: "Discover Opportunities",
    id: "2",
  },
  {
    img: buildAtScale,
    header: "Build at Scale",
    subheader:
      "Empower Developers to build software at Scale. Use AI to boost efficiency, skills and performance.",
    alt: "Build at Scale",
    id: "3",
  },
];

const LearnMoreLink = styled(Box)(({ theme }) => ({
  "& a": {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    gap: 8,
    lineHeight: "20px",
    textDecoration: "none",
    fontWeight: 700,
    fontSize: 16,
    color: "#fefefe",

    "& span": {
      display: "flex",
    },

    "&:hover": {
      color: "#fefefe",
      textDecoration: "underline",
    },
  },
}));
