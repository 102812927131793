import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Divider, Link, SvgIcon, Typography, styled } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import CustomButton from "../../../Common/CustomButton";
import Spinner from "../../../Common/Spinner";
import { EightyTwentyPaymentDetails } from "./BuyCredits";
import { userProfile } from "../../../../libs/UserProfile";
import FT from "../../../../libs/FT";
import { useLearnContext } from "../LearnContext";
import stripeLogo from "../../../../images/onboarding/stripe.svg";
import { closeGradientSvg } from "../../../UserHome/Base/SVG";
import logo from "../../../../images/landing/latest_logo.svg";

const CheckoutFormHeader = ({
  setPaymentDetails,
}: {
  setPaymentDetails: Dispatch<SetStateAction<EightyTwentyPaymentDetails>>;
}) => {
  const handleCloseModal = () => {
    setPaymentDetails((prevData) => ({ ...prevData, isActive: false }));
  };

  useEffect(() => {
    const handleEscKey = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleCloseModal();
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
      }}
    >
      <img src={logo} style={{ width: "111px" }} alt="Tublian logo" />
      <SvgIcon
        sx={{
          width: "32px",
          height: "32px",
          cursor: "pointer",
        }}
        onClick={handleCloseModal}
      >
        {closeGradientSvg}
      </SvgIcon>
    </Box>
  );
};

const EightyTwentyPlanCheckoutDetails = ({
  price,
  credits,
  currency,
  setPaymentDetails,
}: {
  price: number;
  credits: number;
  currency: string;
  setPaymentDetails: Dispatch<SetStateAction<EightyTwentyPaymentDetails>>;
}) => {
  const handleCloseModal = () => {
    setPaymentDetails((prevData) => ({ ...prevData, isActive: false }));
  };

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "10px",
        border: "1px solid #414141",
        background: "#292929",
        marginBottom: "28px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "14px 20px",
        }}
      >
        <StyledBoldText sx={{ color: "#FEFEFE" }}>Credit</StyledBoldText>
        <Base800Dark1RemText
          sx={{ color: "#FDD649", cursor: "pointer" }}
          onClick={handleCloseModal}
        >
          Change Plan
        </Base800Dark1RemText>
      </Box>

      <StyledDivider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "13px",
          padding: "14px 20px",
        }}
      >
        <CreditAmount>{credits} credits</CreditAmount>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.125rem",
              fontWeight: 500,
              lineHeight: "1.875rem",
              color: "#FEFEFE",
            }}
          >
            Total:
          </Typography>

          <Typography
            sx={{
              fontSize: "0.875rem",
              fontWeight: 500,
              lineHeight: "1.25rem",
              color: "#B7B7B7",
            }}
          >
            <span
              style={{
                fontSize: "1.875rem",
                fontWeight: 700,
                lineHeight: "2.8125rem",
                color: "#FEFEFE",
              }}
            >
              {`${currency} ${price.toLocaleString()}`}
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const EightyTwentyCheckoutForm = ({
  price,
  priceId,
  credits,
  currency,
  currencyId,
  setPaymentDetails,
}: {
  price: number;
  priceId: string;
  credits: number;
  currency: string;
  currencyId: string;
  setPaymentDetails: Dispatch<SetStateAction<EightyTwentyPaymentDetails>>;
}) => {
  // eslint-disable-next-line
  const stripe = useStripe();
  const elements = useElements();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const { setError } = useLearnContext();

  const handleError = (err: any) => {
    console.error("Error encountered during payment:", err);
    setError({ error: true, errorMsg: "Error encountered, please try again." });
    setPaymentDetails((prevData) => ({ ...prevData, isActive: false }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoadingPayment(true);

    if (!stripe || !elements) return;

    try {
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        // Immediate error on trying to submit
        throw new Error(submitError.message);
      }

      // Create the payment intent
      const res = await FT.createPaymentIntent(priceId, currencyId);
      console.log("🚀 ~ handleSubmit ~ res:", res);

      const { clientSecret } = res;
      console.log("🚀 ~ handleSubmit ~ clientSecret:", clientSecret);

      const return_url = `${window.location.origin}/learn`;

      // Confirm the Intent using the details collected by the Payment Element
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url,
        },
      });

      if (error) {
        throw new Error(error.message);
      }
    } catch (err) {
      handleError(err);
      setPaymentDetails((prevData) => ({ ...prevData, isActive: false }));
    } finally {
      setLoadingPayment(false);
    }
  };

  return (
    <MainWrap>
      <Box>
        <MainContentWrap>
          <CheckoutFormHeader setPaymentDetails={setPaymentDetails} />
          <form onSubmit={handleSubmit}>
            <EightyTwentyPlanCheckoutDetails
              price={price}
              credits={credits}
              currency={currency}
              setPaymentDetails={setPaymentDetails}
            />
            <Box>
              <StyledBoldText sx={{ color: "#FEFEFE", marginBottom: "8px" }}>
                Payment Method
              </StyledBoldText>
              <Base800Dark1RemText sx={{ marginBottom: "20px" }}>
                Choose how you’d like to pay.
              </Base800Dark1RemText>

              <PaymentElement
                options={{
                  layout: "accordion",
                  defaultValues: {
                    billingDetails: {
                      // email: "Hi",
                      name: userProfile(localStorage).userId(),
                    },
                  },
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
              <Base800Dark1RemText sx={{ marginTop: "28px" }}>
                By clicking below, you agree to our{" "}
                <Link href="/terms" target="_blank">
                  Terms
                </Link>
                ,{" "}
                <Link href="/privacy" target="_blank">
                  Privacy Policy
                </Link>
                . Tublian will charge you {`${currency}${price}`} (plus Tax).
              </Base800Dark1RemText>
            </Box>
            <Box sx={{ "& button": { width: "100% !important" } }}>
              <CustomButton
                size="medium"
                type="primary"
                disabled={!stripe || !elements}
                nativeType="submit"
              >
                {loadingPayment ? <Spinner /> : `Pay ${currency}${price}`}
              </CustomButton>
            </Box>
          </form>
        </MainContentWrap>

        <FooterWrap>
          <img src={stripeLogo} alt="stripe" />
          <span />
          <Base800Dark1RemText>Powered by Stripe.com</Base800Dark1RemText>
        </FooterWrap>
      </Box>
    </MainWrap>
  );
};

export default EightyTwentyCheckoutForm;

const MainWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "60px 0",
  height: "100vh",
  width: "100vw",
  overflowY: "scroll",
}));

const MainContentWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "548px",
  borderRadius: "20px",
  border: "1px solid #292929",
  background: "#1E1E1E",
  padding: "20px",
}));

const FooterWrap = styled(Box)({
  display: "flex",
  marginTop: "16px",
  gap: "4px",
  alignItems: "center",
  "& span": {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    background: "#414141",
  },
});

const Base800Dark1RemText = styled(Typography)({
  color: "#CFCFCF",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "1.5rem",
  "& a": {
    textDecoration: "none",
    color: "#FDD649",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: "#FDD649",
    },
  },
});

const StyledBoldText = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: 700,
  lineHeight: "2rem",
});

const StyledDivider = styled(Divider)({
  borderColor: "#414141",
});

const CreditAmount = styled("span")({
  fontWeight: 700,
  fontSize: "1.125rem",
  lineHeight: "normal",
  color: "#FEFEFE",
  borderRadius: "10px",
  padding: "3px 20px",
  background:
    "linear-gradient(#414141, #414141) padding-box, linear-gradient(109.15deg, #fbda61 0%, #ff5acd 86.08%) border-box",
  border: "1px solid transparent",
});
