import React, { FC } from "react";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

const DetailedCustomTooltip: FC<IProps> = ({
  title,
  placement = "top-start",
  offset = [0, 0],
  hasArrow = true,
  children,
}) => {
  return (
    <StyledTooltip
      title={title}
      placement={placement}
      arrow={hasArrow}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset,
              },
            },
          ],
        },
      }}
    >
      {children}
    </StyledTooltip>
  );
};

export default DetailedCustomTooltip;

interface IProps {
  title: string | React.ReactNode;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
  offset?: [number, number] | undefined;
  hasArrow?: boolean | undefined;
  children: React.ReactElement;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#7F5DDD",
    margin: "0 0 0 8px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#7F5DDD",
    borderRadius: "15px",
    padding: "16px",
    maxWidth: "421px",
    color: "#FEFEFE",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    "& h4": {
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      marginBottom: "12px",
    },
    "& span": {
      fontWeight: 700,
    },
  },
}));
