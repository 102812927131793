// ChatBotContext.tsx
import { createContext, useContext, useState, useMemo, ReactNode } from "react";
import {
  ChatBotData,
  SetTublibotExplorerData,
  TublibotExplorerData,
  SetChatBotData,
} from "../Common/TubliChatBot/tubliChatBotResources";

const ChatBotContext = createContext<ChatBotContextType | undefined>(undefined);

export const ChatBotProvider = ({ children }: { children: ReactNode }) => {
  const [tublibotExplorerData, setTublibotExplorerData] =
    useState<TublibotExplorerData>({
      loading: false,
      tublibotContext: [],
      messagesList: [],
    });

  const [chatBotData, setChatBotData] = useState<ChatBotData>({
    chatBoxDimensions: {
      width: 388,
    },
    chatOpen: false,
    chatBotView: "idle popup",
    chatBotHistory: [],
    chatBotMessage: null,
    currentConversationId: "",
    messagesList: [],
    loadingHistory: false,
    inputError: {
      error: false,
      errMsg: "",
    },
  });

  const contextValue: ChatBotContextType = useMemo(
    () => ({
      chatBotData,
      setChatBotData,
      tublibotExplorerData,
      setTublibotExplorerData,
    }),
    [chatBotData, tublibotExplorerData]
  );

  return (
    <ChatBotContext.Provider value={contextValue}>
      {children}
    </ChatBotContext.Provider>
  );
};

export const useChatBotContext = () => {
  const context = useContext(ChatBotContext);
  if (!context) {
    throw new Error("useChatBotContext must be used within a ChatBotProvider");
  }
  return context;
};

interface ChatBotContextType {
  chatBotData: ChatBotData;
  setChatBotData: SetChatBotData;
  tublibotExplorerData: TublibotExplorerData;
  setTublibotExplorerData: SetTublibotExplorerData;
}
