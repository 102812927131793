import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GithubClient from "../../../libs/GithubClient";
import TwitterClient from "../../../libs/TwitterClient";
import { userProfile } from "../../../libs/UserProfile";

export default function useAuthProvider() {
    const { search } = useLocation();
    const { provider: providerType } = queryString.parse(search);
    const [provider, setProvider] = useState<AuthProvider>();

    useEffect(() => {

        const Provider = getProvider(providerType as string);
        if(Provider) setProvider(new Provider(search));

    }, [search, providerType])

    return provider;
}

export const getAuthProvider = (providerType: string) => {
    const Provider = getProvider(providerType);
    if(!Provider) return null;

    return new Provider('');
}

const getProvider = (providerType: string) => {
    switch(providerType) {
        case 'github':
            return GitHubAuthProvider
        case 'twitter':
            return TwitterAuthProvider
        default:
            return null
    }
}


class BaseAuthProvider {
    user: any

    constructor() {
        this.user = userProfile(localStorage);
    }

    accessTokenValid(res: TokenResponse) {
        return res.jwt ? true : false;
    }

    setUserInfo(res: TokenResponse) {
        this.user.setToken(res.jwt);
        this.user.setProviderId(res.providerId);
        this.user.setProvider(res.provider);
        this.user.setUserAvatar(res.avatar);
        
    }

    getProviderId(res: TokenResponse) {
        return res.providerId;
    }
}

class GitHubAuthProvider extends BaseAuthProvider implements AuthProvider {
    inviteCode: string
    code: string

    constructor(search: string) {
        super();
        const { state, code } = queryString.parse(search);
        this.inviteCode = state as string
        this.code = code as string
        // console.log(code);
    }

    async getAccessToken() {
        return await GithubClient.accessToken(this.code);
    }

    async lookUpUser() {
        const user = await GithubClient.currentUserInfo();
        return {
            name: user.name,
            bio: user.bio,
            public_repos: user.public_repos
        }
    }
}


class TwitterAuthProvider extends BaseAuthProvider implements AuthProvider {
    inviteCode: string
    oauth_token: string
    oauth_verifier: string

    constructor(search: string) {
        super();
        const { oauth_token, oauth_verifier, code } = queryString.parse(search);
        this.inviteCode = code as string
        this.oauth_token = oauth_token as string
        this.oauth_verifier = oauth_verifier as string
    }

    async getAccessToken() {
        return await TwitterClient.accessToken(this.oauth_token, this.oauth_verifier);
    }

    async lookUpUser() {
        const users = await TwitterClient.lookup(this.user.providerId());
        return {
            name: users[0].name,
            bio: users[0].description
        };
    }
}

interface AuthProvider {
    inviteCode: string,
    getAccessToken(): Promise<{
        token: any;
        jwt: string;
        scope: any;
}>
    accessTokenValid(body: {jwt: string}): boolean
    setUserInfo(body: any): void
    getProviderId(body: any) : string
    lookUpUser() : Promise<UserResponse>
}

interface TokenResponse {
    providerId: string,
    avatar: string,
    provider: string,
    jwt: string
}

interface UserResponse {
    name: string,
    bio: string
}