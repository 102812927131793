import { useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { useLearnContext } from "./Course/LearnContext";
import CustomButton from "../Common/CustomButton";
import { logEvent } from "../../libs/amplitude";
import { userProfile } from "../../libs/UserProfile";
import DateUtils from "../../libs/DateUtils";
import deleteImg from "../../images/learning/delete-roadmap.png";

const DeleteRoadmap = ({ redirectFxn }: { redirectFxn?: any }) => {
  const userCache = userProfile(localStorage);
  const [deleting, setDeleting] = useState(false);
  const { popupStateData, setPopupStateData, handleDeleteRoadmap, setError } =
    useLearnContext();

  const { popupMode } = popupStateData || {};
  const { roadmapId, createdAt } = popupStateData?.roadmap || {};

  const refundable =
    createdAt !== undefined && !DateUtils.isOlderThanXHours(createdAt, 2); // Non-refundable after 2 hours

  const deleteMsg = `This action will permanently erase your roadmap, along with all associated progress, tasks, and achievements. ${
    refundable ? "You will receive a refund of 1 credit upon deletion." : ""
  } This action cannot be undone.`;

  const handleDelete = async () => {
    if (popupMode === "delete" && roadmapId) {
      setDeleting(true);

      if (redirectFxn) {
        await handleDeleteRoadmap(roadmapId, refundable);
      } else {
        handleDeleteRoadmap(roadmapId, refundable);
      }

      logEvent("8020", userCache.userId(), {
        action: "delete",
        roadmapId,
        refundable,
      });

      setDeleting(false);
      setPopupStateData({
        isActive: false,
        feedbackData: {},
        popupMode: "",
      });
      redirectFxn?.();
    } else {
      setPopupStateData({
        isActive: false,
        feedbackData: {},
        popupMode: "",
      });

      setDeleting(false);
      setError({ error: true, errorMsg: "Unable to delete roadmap" });
    }
  };

  const handleCancel = () => {
    setPopupStateData({
      isActive: false,
      feedbackData: {},
      popupMode: "",
    });
  };

  return (
    <DeleteRoadmapWrap>
      <img src={deleteImg} alt="Delete Roadmap" />
      <Box>
        <Typography component="h3">Delete Roadmap</Typography>
        <Typography>{deleteMsg}</Typography>
        <Box className="btnRow">
          <CustomButton
            type="primary"
            size="medium"
            onClick={handleDelete}
            disabled={deleting}
          >
            {deleting ? "Deleting..." : "Delete"}
          </CustomButton>
          <CustomButton
            type="tertiary"
            size="medium"
            onClick={handleCancel}
            disabled={deleting}
          >
            Cancel
          </CustomButton>
        </Box>
      </Box>
    </DeleteRoadmapWrap>
  );
};

export default DeleteRoadmap;

const DeleteRoadmapWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "space-between",
  textAlign: "center",
  borderRadius: "20px",
  padding: "50px 20px 20px",
  width: 423,
  height: 482,
  border: "1px solid #292929",
  background: "#1E1E1E",
  "& img": {
    width: 306,
    alignSelf: "center",
  },
  "&>div": {
    display: "flex",
    flexDirection: "column",
    "& h3": {
      color: "#FEFEFE",
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "2rem",
      marginBottom: 12,
    },
    "& p": {
      color: "#B7B7B7",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
      marginBottom: 20,
    },
    "& .btnRow": {
      display: "flex",
      flexDirection: "column",
      gap: 8,
    },
  },
  [theme.breakpoints.down(700)]: {
    width: 357,
  },
  [theme.breakpoints.down(390)]: {
    width: "100%",
  },
}));
