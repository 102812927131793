import Slider from "react-slick";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const ButtonContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  zIndex: 1,
});

const ButtonRoot = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#292929 !important",
  border: "1px solid #414141 !important",
  color: "#FEFEFE",
  width: "42px",
  height: "42px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, .8)",
  },
}));

const PrevButton = ({ onClick }) => {
  if (onClick) {
    return (
      <ButtonContainer sx={{ left: 0 }}>
        <ButtonRoot onClick={onClick} size="medium">
          <NavigateBeforeIcon />
        </ButtonRoot>
      </ButtonContainer>
    );
  }
  return null;
};

const NextButton = ({ onClick }) => {
  if (onClick) {
    return (
      <ButtonContainer sx={{ right: 0 }}>
        <ButtonRoot onClick={onClick} size="medium">
          <NavigateNextIcon />
        </ButtonRoot>
      </ButtonContainer>
    );
  }
  return null;
};

const defaultConfig = [
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const FeedSlider = ({
  slidesToShow,
  responsive = defaultConfig,
  showDots = false,
  children,
}) => {
  const settings = {
    dots: showDots,
    infinite: false,
    adaptiveHeight: false,
    speed: 500,
    slidesToShow: slidesToShow || 3,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    responsive,
  };
  return <Slider {...settings}>{children}</Slider>;
};

export default FeedSlider;
