import { FC } from "react";
import { Box, Typography, styled } from "@mui/material";
import TextUtils from "../../libs/TextUtils";
import CustomButton from "./CustomButton";

const FeedBack: FC<IProps> = ({
  title = "Feedback",
  inputPlaceholder = "Please type your feedback here",
  cancelBtnName,
  proceedBtnName,
  textareaValue,
  textareaHandler,
  cancelBtnHandleClick,
  proceedBtnHandleClick,
}) => {
  return (
    <FeedBackWrap>
      <Typography component="h2">{TextUtils.capsFirstLetter(title)}</Typography>
      <Box className="feedbackBody">
        <textarea
          value={textareaValue}
          onChange={textareaHandler}
          placeholder={inputPlaceholder}
        />
      </Box>
      <Box className="feedbackFooter">
        {cancelBtnName && (
          <CustomButton
            type="tertiary"
            size="medium"
            onClick={cancelBtnHandleClick}
          >
            {cancelBtnName}
          </CustomButton>
        )}
        {proceedBtnName && (
          <CustomButton
            type="primary"
            size="medium"
            disabled={!textareaValue || textareaValue.length === 0}
            onClick={proceedBtnHandleClick}
          >
            {proceedBtnName}
          </CustomButton>
        )}
      </Box>
    </FeedBackWrap>
  );
};

export default FeedBack;

interface IProps {
  title?: string;
  inputPlaceholder?: string;
  cancelBtnName?: string;
  proceedBtnName?: string;
  textareaValue: string;
  textareaHandler: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  cancelBtnHandleClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  proceedBtnHandleClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const FeedBackWrap = styled(Box)(({ theme }) => ({
  border: "1px solid #414141",
  backgroundColor: "#1E1E1E",
  borderRadius: 20,
  width: 715,
  height: 315,
  color: "#FEFEFE",
  position: "relative",
  "& h2": {
    borderBottom: "1px solid #292929",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    gap: "28px",
    fontWeight: 700,
    fontSize: "1.25rem",
    lineHeight: "2rem",
  },
  "& .feedbackBody": {
    padding: 20,
    "& textarea": {
      background: "#1E1E1E",
      width: "100%",
      height: "139px",
      padding: "16px 20px",
      borderRadius: "20px",
      border: "1px solid #292929",
      color: "#707070",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem",
    },
  },
  "& .feedbackFooter": {
    paddingRight: 20,
    paddingBottom: 21,
    display: "flex",
    justifyContent: "flex-end",
    fontStyle: "normal",
    color: "#B7B7B7",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    gap: "20px",
    position: "absolute",
    bottom: 0,
    right: 0,
    [theme.breakpoints.down(720)]: {
      gap: 26,
    },
  },
  [theme.breakpoints.down(720)]: {
    width: 356,
    height: 303,
    "& h2": {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    "& .feedbackBody": {
      "& textarea": {
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: "1.25rem",
      },
    },
  },
  [theme.breakpoints.down(390)]: {
    width: "100%",
  },
}));
