import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#F59C0B",
    },
    error: {
      main: "#FC5A5A",
    },
    mode: "dark",
    text: {
      secondary: "#B5B5BE",
      // hint: "#92929D",
    },
    divider: "#292932",
    background: {
      // paper: "#1C1C24",
      paper: "#1E1E1E",
      default: "#292932",
    },
  },
  typography: {
    fontFamily: ["Space Grotesk", "sans-serif"].join(","),
    fontFamilyMonospaced: [
      "Source Code Pro",
      "Fira Code",
      "Roboto",
      "monospace",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "unset",
          // backgroundImage: "none",
          boxShadow: "none",
        },
      },
    },
  },
});

export default function Provider({ children }: { children?: React.ReactNode }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

declare module "@mui/material/styles/createTypography" {
  interface Typography {
    fontFamilyMonospaced?: string;
  }

  interface TypographyOptions {
    fontFamilyMonospaced?: string;
  }
}
