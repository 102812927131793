import { Paper, Button, Dialog, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GitHubIcon from "@mui/icons-material/GitHub";
import { useParams } from "react-router-dom";
import GithubClient from "../../../libs/GithubClient";
import { PopoverHeader } from "../OnboardingHeader";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, .8)",
  },
  modalContainer: {
    backgroundColor: "transparent",
    // ["@media (max-width:769px)"]: {
    [theme.breakpoints.down(769)]: {
      margin: theme.spacing(2),
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4, 2.5),
    borderRadius: 20,
  },
  button: {
    borderRadius: 10,
    width: 320,
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1.25),
    },
  },
  twitterButton: {
    backgroundColor: "#50ABF1",
    "&:hover": {
      backgroundColor: "#50ABF1",
    },
  },
  githubButton: {
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
}));

export default function SignInPopover({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();
  const { code } = useParams<{ code: string }>();

  const handleGithubSignIn = () => {
    GithubClient.signIn(code);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{ className: classes.modalContainer, elevation: 0 }}
        BackdropProps={{ className: classes.backdrop }}
      >
        <PopoverHeader />
        <Paper className={classes.paper}>
          <Typography component="h6" variant="h6" paragraph>
            Sign In
          </Typography>
          <Button
            onClick={handleGithubSignIn}
            className={clsx(classes.button, classes.githubButton)}
            startIcon={<GitHubIcon />}
          >
            <Typography component="span" variant="h6">
              Continue with Github
            </Typography>
          </Button>
        </Paper>
      </Dialog>
    </>
  );
}
