import { FC, useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useLearnContext } from "./Course/LearnContext";
import SuggestedSearchesHeader from "../Projects/ProjectsList/SuggestedSearchesHeader";
import OverlayModal from "../Common/OverlayModal";
import StatusNotification from "../Common/StatusNotification";
import EmptyRoadmapState from "./EmptyRoadmapState";
import DeleteRoadmap from "./DeleteRoadmap";
import BannerCard from "./BannerCard";
import RoadmapSearch from "./RoadmapSearch";
import ReferFriend from "../Common/ReferFriend";
import queryString from "query-string";
import CustomToast from "../Common/CustomToast";
import FT from "../../libs/FT";
import { RoadmapCardSkeleton } from "./Skeletons/Skeletons";
import RenderRoadmapCategories from "./Course/RenderRoadmapCategories";
import { PaymentIntent } from "@stripe/stripe-js";
import { useAppContext } from "../../libs/contextLib";
import { QueryType, UserProfile } from "./Common/LearningTypes";
import EightyTwentyPaymentStatus from "./Course/BuyCredits/EightyTwentyPaymentStatus";
import Generate8020Internship from "./Course/Generate8020Internship";
import { userProfile } from "../../libs/UserProfile";

const LearningPresentation: FC<IProps> = ({ isPublic }) => {
  const location = useLocation();
  const history = useHistory();
  const userCache = userProfile(localStorage);

  const { user, setUser } = useAppContext() as unknown as {
    user: UserProfile;
    setUser: (user: UserProfile) => {};
  };

  const {
    error,
    learnQuery,
    roadmapList,
    loadingState,
    popupStateData,
    setError,
    setLearnQuery,
    fetchRoadmapList,
    getCreditStatus,
    triggerSearchRoadmaps,
    setPopupStateData,
    createSamplePrompts,
  } = useLearnContext();

  const [userQuerySearched, setUserQuerySearched] = useState(false);
  const { search } = useLocation();
  const { prompt } = queryString.parse(search);

  const showSuggestedSearches =
    !loadingState.loadingRoadmaps &&
    !loadingState.deletingRoadmap &&
    !loadingState.creatingRoadmaps;

  const showAIPrompts =
    userQuerySearched &&
    roadmapList[0]?.roadmap?.internalLearningPrompt?.length > 0 &&
    learnQuery;

  const enableAIInternship = () => {
    return userCache.reference() && userCache.reference() === "anambraTechies";
  };

  const handleCloseToast = () => {
    setPopupStateData((prev) => ({
      ...prev,
      toastData: {
        duration: 6000, // in Ïmiliseconds
        title: "",
        subtitle: "",
        actionMessage: "",
        action: undefined,
      },
      popupMode: "",
    }));
  };

  const paymentStatusModalAction = () => {
    if (popupStateData.paymentStatusData?.status === "error") {
      history.push("/8020/credits");
    } else {
      setPopupStateData((prev) => ({
        ...prev,
        isActive: false,
        paymentStatusData: {
          status: undefined,
          message: "",
        },
        popupMode: "",
      }));
    }
  };

  useEffect(() => {
    async function handlePaymentSuccess(paymentIntentId: string) {
      let paymentStatus;

      try {
        const res: PaymentIntent = await FT.getCreditPaymentInfo(
          "paymentIntentInfo",
          `paymentIntentId=${paymentIntentId}`
        );
        // console.log("🚀 ~ handlePaymentSuccess ~ res:", res);

        paymentStatus = res?.status || "failed";
      } catch (error) {
        console.error("Error handling payment success:", error);
        setError({
          error: true,
          errorMsg: "Error encountered! Please try again 1.",
        });
      }

      if (paymentStatus === "succeeded") {
        await getCreditStatus(); // Update credits

        // setPopupStateData((prev) => ({
        //   ...prev,
        //   toastData: {
        //     duration: 6000, // in miliseconds
        //     title: "Purchase Successful",
        //     subtitle: "Your credit purchase was successful",
        //     actionMessage: "",
        //     action: undefined,
        //   },
        //   popupMode: "toast",
        // }));

        setPopupStateData((prev) => ({
          ...prev,
          isActive: true,
          paymentStatusData: {
            status: "success",
            message: "Your credit purchase was successful",
          },
          popupMode: "paymentStatus",
        }));

        // console.log("🚀 ~ user:", user);

        if (user && !user.isAccountPro) {
          // Only call if not already pro
          const userResponse: UserProfile = await FT.getAuthorizedUser();
          // console.log("🚀 ~ userResponse:", userResponse);

          if (userResponse?.isAccountPro) {
            setUser(userResponse); // Make account pro
            // console.log("set");
          }
        }
      } else {
        console.error("Error encountered! Please try again.");
        // setError({
        //   error: true,
        //   errorMsg: "Error encountered! Please try again.",
        // });

        setPopupStateData((prev) => ({
          ...prev,
          isActive: true,
          paymentStatusData: {
            status: "error",
            message: "Error encountered! Please try again",
          },
          popupMode: "paymentStatus",
        }));
      }
    }

    const params = new URLSearchParams(location.search);
    const paymentIntentId = params.get("payment_intent");
    const redirectStatus = params.get("redirect_status");

    if (paymentIntentId) {
      if (redirectStatus === "succeeded") {
        handlePaymentSuccess(paymentIntentId);
        history.replace("/learn");
      } else {
        console.error("Payment failed!");
        setError({
          error: true,
          errorMsg: "Error encountered! Please try again.",
        });
      }
    }
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchRoadmapList(), getCreditStatus()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (roadmapList.length === 0) {
      fetchData();
    }
    if (prompt) {
      setLearnQuery(prompt.toString());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <PrimaryColumn>
      <CustomToast
        open={popupStateData.popupMode === "toast"}
        handleClose={handleCloseToast}
        duration={popupStateData.toastData?.duration}
        title={popupStateData.toastData?.title || ""}
        subtitle={popupStateData.toastData?.subtitle}
      />

      <OverlayModal isPreviewOpen={popupStateData.isActive || error.error}>
        {error.error && (
          <StatusNotification
            status="error"
            statusMessage={error.errorMsg}
            cancelBtnName="Close"
            cancelBtnHandleClick={() =>
              setError({ error: false, errorMsg: "" })
            }
          />
        )}
        {popupStateData.isActive && popupStateData.popupMode === "delete" && (
          <DeleteRoadmap />
        )}
        {popupStateData.isActive &&
          popupStateData.popupMode === "paymentStatus" && (
            <EightyTwentyPaymentStatus
              status={popupStateData.paymentStatusData?.status}
              message={popupStateData.paymentStatusData?.message}
              handleAction={paymentStatusModalAction}
            />
          )}
      </OverlayModal>

      <HeaderWrap>
        <BannerCard
          type="learning"
          beta={false}
          title="8020: Fastest Way to Learn Anything"
          paragraphs={[
            "An AI-powered personalized learning, tailored uniquely to your passions, existing knowledge, and skills for a journey that aligns perfectly with your individual goals.",
            "8020 is to learning what ChatGPT is to conversation: both tailor content to your needs.",
          ]}
        />
      </HeaderWrap>

      <MainContent>
        <Box sx={{ zIndex: 1 }}>
          <ReferFriend />

          <SearchBox>
            <RoadmapSearch
              query={learnQuery}
              setQuery={setLearnQuery}
              onSearch={() => {
                triggerSearchRoadmaps();
                setUserQuerySearched(true);
              }}
              disabled={
                loadingState.creatingRoadmaps || loadingState.loadingRoadmaps
              }
              errorHandler={(errorMsg: string) =>
                setError({ error: true, errorMsg })
              }
              placeholder="What do you want to learn?"
            />
          </SearchBox>

          {showSuggestedSearches && (
            <SuggestedSearchesWrap>
              <SuggestedSearchesHeader
                type={showAIPrompts ? "aiPrompts" : "queries"}
                setQuery={(query: string, queryType: QueryType): void => {
                  setLearnQuery(query);
                  if (showAIPrompts) {
                    triggerSearchRoadmaps(query, queryType);
                    setUserQuerySearched(showAIPrompts ? false : true);
                  }
                }}
                suggestedQueries={
                  showAIPrompts
                    ? [roadmapList[0].roadmap.internalLearningPrompt]
                    : createSamplePrompts()
                }
                disabled={
                  loadingState.loadingRoadmaps || loadingState.creatingRoadmaps
                }
              />
            </SuggestedSearchesWrap>
          )}
          {enableAIInternship() && (
            <Box margin="0 0 12px" width="100%">
              <Generate8020Internship reference={userCache.reference()} />
            </Box>
          )}

          {!loadingState.loadingRoadmaps && !loadingState.deletingRoadmap && (
            <RoadmapWrap>
              <RenderRoadmapCategories roadmapList={roadmapList} />
            </RoadmapWrap>
          )}

          <EmptyRoadmapState />

          {loadingState.loadingRoadmaps && <RoadmapCardSkeleton />}
        </Box>
      </MainContent>
    </PrimaryColumn>
  );
};
export default LearningPresentation;

interface IProps {
  isPublic: boolean;
}

const HeaderWrap = styled(Box)(({ theme }) => ({
  margin: "0 0 28px",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down(700)]: {
    margin: "auto",
    marginBottom: 20,
    gap: 20,
    width: "100%",
    alignItems: "center",
  },
}));

const PrimaryColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "16px",
});

const MainContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  "&>div": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down(1040)]: {
      width: "100%",
    },
    [theme.breakpoints.down(736)]: {
      width: "100%",
    },
  },
  "& .creditStatWrap": {
    margin: "8px 0 12px",
  },
}));

const SearchBox = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: "18px",
  [theme.breakpoints.down(1040)]: {
    width: "100%",
  },
  [theme.breakpoints.down(736)]: {
    width: "100%",
  },
}));

const SuggestedSearchesWrap = styled(Box)(({ theme }) => ({
  marginBottom: 28,
  width: "100%",
  [theme.breakpoints.down(1040)]: {
    width: "100%",
  },
}));

const RoadmapWrap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "20px",
});
