import { Button, styled } from "@mui/material";
import { ICustomButtonProps, getSizeStyles } from "./CustomBtnResources";

const NeutralButton = ({
  disabled,
  children,
  onClick,
  style,
  size = "large",
  nativeType,
  type = "primary",
  loading = false,
  loadingText = "Loading...",
  preImg,
  postImg,
}: ICustomButtonProps) => {
  const NeuButton = styled(Button)`
    && {
      text-transform: none;
      font-family: "Space Grotesk";
      border-radius: 30px;
      font-weight: 500;
      border: 1px solid #292929;
      color: #fefefe;
      width: auto;
      padding: ${getSizeStyles(size, type).padding};
      font-size: ${getSizeStyles(size, type).fontSize};
      line-height: ${getSizeStyles(size, type).lineHeight};
      &:hover {
        background-color: #171717;
        border: 1px solid #fefefe;
        color: #fefefe;
      }
      &:active {
        background-color: #171717;
        border: 1px solid #fefefe;
        color: #fefefe;
      }
      &:disabled {
        background-color: transparent;
        border: 1px solid #696969;
        color: #696969;
      }
    }
  `;

  return (
    <NeuButton
      type={nativeType}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {!loading && preImg && typeof preImg === "string" ? (
        <img
          src={preImg}
          alt="preImg"
          style={{ display: "flex", marginRight: 8 }}
          width={size === "large" || type === "onBoarding" ? 24 : 18}
          height={size === "large" || type === "onBoarding" ? 24 : 18}
        />
      ) : (
        !loading &&
        preImg && <div style={{ display: "flex", marginLeft: 8 }}>{preImg}</div>
      )}
      {!loading ? children : loadingText}
      {!loading && postImg && typeof postImg === "string" ? (
        <img
          src={postImg}
          style={{ display: "flex", marginLeft: 8 }}
          alt="postImg"
          width={size === "large" ? 24 : 18}
          height={size === "large" ? 24 : 18}
        />
      ) : (
        !loading &&
        postImg && (
          <div style={{ display: "flex", marginLeft: 8 }}>{postImg}</div>
        )
      )}
    </NeuButton>
  );
};

export default NeutralButton;
