import { FC, useRef, useState } from "react";
import { Transition } from "react-transition-group";
import { styled } from "@mui/system";
import { Snackbar } from "@mui/base/Snackbar";
import { SnackbarCloseReason } from "@mui/base/useSnackbar";
import { CheckCircleOutlineRounded, Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const CustomToast: FC<Props> = ({
  toastIcon,
  title,
  subtitle,
  actionMessage,
  actionLink,
  action,
  duration = 4000,
  open,
  handleClose,
}) => {
  const [exited, setExited] = useState(true);
  const nodeRef = useRef(null);

  const handleCloseToast = (_: any, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    handleClose && handleClose();
  };

  const handleOnEnter = () => {
    setExited(false);
  };

  const handleOnExited = () => {
    setExited(true);
  };

  return (
    <>
      <StyledSnackbar
        autoHideDuration={duration}
        open={open}
        onClose={handleCloseToast}
        exited={exited}
      >
        <Transition
          timeout={{ enter: 400, exit: 400 }}
          in={open}
          appear
          unmountOnExit
          onEnter={handleOnEnter}
          onExited={handleOnExited}
          nodeRef={nodeRef}
        >
          {(status) => (
            <SnackbarContent
              style={{
                transform: positioningStyles[status],
                transition: "transform 300ms ease",
              }}
              ref={nodeRef}
            >
              {toastIcon || <StyledCheck htmlColor="#1AFF8C" />}
              <Box className="snackbar-message">
                <Typography className="snackbar-title">{title}</Typography>
                {subtitle && (
                  <Typography className="snackbar-description">
                    {subtitle}
                  </Typography>
                )}
                {action && (
                  <a
                    href={actionLink}
                    style={{ color: "#FEFEFE" }}
                    onClick={action}
                  >
                    {actionMessage}
                  </a>
                )}
              </Box>
              <StyledCloseIcon onClick={handleCloseToast as any} />
            </SnackbarContent>
          )}
        </Transition>
      </StyledSnackbar>
    </>
  );
};

export default CustomToast;

type Props = {
  toastIcon?: any;
  title: string;
  subtitle?: string;
  actionMessage?: string;
  actionLink?: string;
  action?: () => void;
  duration?: number;
  open: boolean;
  handleClose: () => void;
};

const StyledSnackbar = styled(Snackbar)`
  position: fixed;
  z-index: 5500;
  display: flex;
  bottom: 16px;
  left: 16px;
  max-width: 400px;
  // min-width: 300px;
`;

const SnackbarContent = styled("div")(
  ({ theme }) => `
  display: flex;
  gap: 8px;
  overflow: hidden;
  background-color: #292929;
  border-radius: 8px;
  border: 1px solid #414141;
  padding: 0.75rem;
  color: #FEFEFE;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: start;
  position: relative;

  & .snackbar-message {
    flex: 1 1 0%;
    max-width: 100%;
  }

  & .snackbar-title {
    margin: 0;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-right: 0.5rem;
  }

  & .snackbar-description {
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: #CFCFCF;
  }

  & .snackbar-close-icon {
    cursor: pointer;
    flex-shrink: 0;
    padding: 2px;
    border-radius: 4px;

    &:hover {
      background: #414141;
    }
  }
  `
);

const positioningStyles = {
  entering: "translateX(0)",
  entered: "translateX(0)",
  exiting: "translateX(-500px)",
  exited: "translateX(-500px)",
  unmounted: "translateX(500px)",
};

const StyledCheck = styled(CheckCircleOutlineRounded)({
  width: "18px",
  height: "18px",
});

const StyledCloseIcon = styled(Close)({
  borderRadius: "50%",
  border: "1px solid #FEFEFE",
  padding: "4px",
  width: "18px",
  height: "18px",
});
