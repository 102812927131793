import { FC, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import CustomButton from "../../Common/CustomButton";

const sampleQueries = [
  "Javascript project that consist of typescript",
  "Python projects with numpy",
  "Projects using GPT",
];

const SuggestedSearchesHeader: FC<IProps> = ({
  type = "queries",
  suggestedQueries = sampleQueries,
  setQuery,
  disabled = false,
}) => {
  const [limit, setLimit] = useState(type === "queries" ? 3 : 2);

  if (type === "aiPrompts") {
    const showMore = () => {
      setLimit(limit + 3);
    };
    return (
      <SuggestedPromptWrap disabled={disabled}>
        <Typography>
          TublianBot Suggests This Prompt for Your Learning Goal:
        </Typography>
        <Box>
          {suggestedQueries?.slice(0, limit)?.map((query, i) => (
            <Typography
              key={i}
              component="h6"
              className="gradient_pill"
              onClick={() => !disabled && setQuery(query, "ai")}
            >
              {query}
            </Typography>
          ))}
        </Box>
        {limit < suggestedQueries?.length && (
          <CustomButton
            postImg={<KeyboardArrowDown />}
            type="tertiary"
            size="medium"
            onClick={showMore}
          >
            <Typography
              sx={{ fontSize: "0.75rem", fontWeight: 500, lineHeight: "1rem" }}
            >
              Show More
            </Typography>
          </CustomButton>
        )}
      </SuggestedPromptWrap>
    );
  }

  return (
    <SuggestedSearchesWrap disabled={disabled}>
      <Typography>Try asking</Typography>
      {suggestedQueries?.map((query, i) => (
        <Typography
          key={i}
          component="h6"
          className="gradient_pill"
          onClick={() => !disabled && setQuery(query)}
        >
          {query}
        </Typography>
      ))}
    </SuggestedSearchesWrap>
  );
};

export default SuggestedSearchesHeader;

type SuggestionType = "queries" | "aiPrompts";
interface IProps {
  type?: SuggestionType;
  suggestedQueries?: string[];
  setQuery: any;
  disabled?: boolean;
}

const SuggestedSearchesWrap = styled(Box)<{
  disabled: boolean;
}>(({ theme, disabled }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: 8,
  "& *": {
    fontWeight: 700,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  "&>p": {
    fontWeight: 500,
    marginRight: 8,
    color: "#888",
  },
  "& .gradient_pill": {
    padding: "2px 8px",
    borderRadius: 20,
    cursor: disabled ? "not-allowed" : "pointer",
    color: disabled ? "#888" : "#FEFEFE",
    border: disabled ? "1px solid #888" : "1px solid transparent",
    background: disabled
      ? ""
      : "linear-gradient(#1E1E1E, #1E1E1E) padding-box, linear-gradient(109.15deg, #FBDA61 0%, rgba(255, 90, 205, 0.84) 86.08%) border-box",
  },
}));

const SuggestedPromptWrap = styled(Box)<{
  disabled: boolean;
}>(({ theme, disabled }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: 8,

  "& *": {
    fontWeight: 700,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  "&>div": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "6px",
  },
  "&>p": {
    fontWeight: 500,
    marginRight: 8,
    color: "#888",
  },
  "& .gradient_pill": {
    padding: "2px 8px",
    borderRadius: 20,
    cursor: disabled ? "not-allowed" : "pointer",
    color: disabled ? "#888" : "#FEFEFE",
    border: disabled ? "1px solid #888" : "1px solid #1E1E1E",
  },
}));
