import { Dispatch, MouseEvent, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import { Box, ButtonBase, styled, Typography } from "@mui/material";
import UserAvatar from "../../Common/UserAvatar";
import {
  HighlightOffOutlined,
  PersonOutline,
  SettingsOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import Menu from "./Menu";
import IconMenuItem from "./IconMenuItem";
import { useAppContext } from "../../../libs/contextLib";
import FT from "../../../libs/FT";
import ProfileEndpoint from "../../../libs/ProfileEndpoint";
import { useMenuState } from "./menu-state-hook";
import { userProfile } from "../../../libs/UserProfile";
import { logEvent } from "../../../libs/amplitude";
import { caretDownSvg, caretUpSvg } from "../../UserHome/Base/SVG";

export default function UserAvatarMenu() {
  const menuState = useMenuState();
  const history = useHistory();
  const userCache = userProfile(localStorage);
  const { userHasAuthenticated, user, setUser } =
    useAppContext() as unknown as {
      userHasAuthenticated: Dispatch<SetStateAction<boolean>>;
      user: any;
      setUser: (user: any) => void;
    };

  const userSignOut = (event: MouseEvent<Element>) => {
    menuState.onClose(event);
    userCache.clear();
    userHasAuthenticated(false);
    history.push("/welcome");
  };

  const adminSignOut = async (
    event: MouseEvent<Element>,
    adminResponse: any
  ) => {
    menuState.onClose(event);
    userCache.setToken(adminResponse.jwt);
    userCache.setProvider(adminResponse.provider);
    userCache.setProviderId(adminResponse.providerId);
    userCache.setUserId(adminResponse.userId);
    setUser(await FT.getAuthorizedUser());
    history.push("/admin");
  };

  const editProfileForAuthorizedUsers = async (event: MouseEvent<Element>) => {
    const r = await ProfileEndpoint.generateTokenForEdit(userCache.userId());
    menuState.onClose(event);
    logEvent("ProfileEdit", userCache.userId());
    history.push(`/profile/edit/${r.token}`);
  };

  function signOut(event: MouseEvent<Element>) {
    if (userCache.provider() !== "dummy") {
      userSignOut(event);
    } else {
      (async () => {
        try {
          const adminResponse = await FT.unimpersonate();
          adminSignOut(event, adminResponse);
        } catch {
          userSignOut(event);
        }
      })();
    }
  }
  function adminLink(event: MouseEvent<Element>) {
    menuState.onClose(event);
    history.push("/admin");
  }
  function profileLink(event: MouseEvent<Element>) {
    menuState.onClose(event);
    logEvent("ProfileMenu", userCache.userId());
    history.push(`/profile/${userCache.userId()}`);
  }

  return (
    <>
      <StyledButtonBase
        ref={menuState.anchorRef}
        onClick={menuState.toggle}
        disableRipple
        sx={{
          "& .MuiAvatar-img": {
            border: menuState.open ? "1px solid #FDD649" : "none",
          },
        }}
      >
        <UserAvatar />
        {menuState.open ? (
          <StyledIcon>{caretUpSvg}</StyledIcon>
        ) : (
          <StyledIcon>{caretDownSvg}</StyledIcon>
        )}
      </StyledButtonBase>

      <Box style={{ zIndex: 99999999, position: "fixed", top: "0px" }}>
        {/* temp */}
        <Menu {...menuState}>
          {user?.isAdmin && (
            <IconMenuItem
              onClick={adminLink}
              icon={<SupervisorAccountOutlined />}
              primary={<StyledMenuText>Admin</StyledMenuText>}
            />
          )}

          <IconMenuItem
            onClick={profileLink}
            icon={<PersonOutline />}
            primary={<StyledMenuText>Profile</StyledMenuText>}
          />

          <IconMenuItem
            onClick={editProfileForAuthorizedUsers}
            icon={<SettingsOutlined />}
            primary={<StyledMenuText>Settings</StyledMenuText>}
          />

          <IconMenuItem
            onClick={signOut}
            icon={<HighlightOffOutlined color="error" />}
            primary={<StyledMenuText color="error">Sign Out</StyledMenuText>}
          />
        </Menu>
      </Box>
    </>
  );
}

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  "& .MuiAvatar-img": {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    padding: 0,
  },
  "& .MuiAvatar-root": {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    padding: 0,
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: "3px",
    paddingRight: "10px",
  },
}));

const StyledIcon = styled("span")(({ theme }) => ({
  color: "#FEFEFE",
  marginLeft: "4px",
  "& svg": {
    width: 14,
    height: 14,
  },
  [theme.breakpoints.between(769, 960)]: {
    display: "none",
  },
}));

const StyledMenuText = styled(Typography)({
  color: "#747474",
});
