import { styled } from "@mui/material";
// import Vimeo from "@u-wave/react-vimeo";
import dashboardIllustration from "../../../images/landing/dashboardIllustration.png";
// import playButton from "../../../images/landing/play_button.png";

const VideoSec = () => {
  return (
    <>
      <VideoWrapper>
        <Image
          src={dashboardIllustration}
          alt="Illustration of Tublian's dashboard"
        />
      </VideoWrapper>
    </>
  );
};

export default VideoSec;

const VideoWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  marginTop: 40,
  marginBottom: 120,
  objectFit: "cover",
  [theme.breakpoints.down(1024)]: { marginTop: 0, marginBottom: 100 },
  [theme.breakpoints.down(800)]: { marginBottom: 60 },
}));

const Image = styled("img")(({ theme }) => ({
  width: "100%",
  // position: "absolute",
  // cursor: "pointer",
  // [theme.breakpoints.down(600)]: {
  //   width: 80,
  //   height: 80,
  // },
}));

// const VideoFrame = styled("div")(({ theme }) => ({
//   height: 700,
//   margin: "160px 0 54px 0",
//   zIndex: 999,
//   position: "relative",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   // background: `url(${heroImg}) no-repeat center`,
//   backgroundSize: "cover",
//   width: "100%",
//   [theme.breakpoints.down(1025)]: {
//     marginTop: "112px",
//     marginBottom: "54px",
//   },
//   [theme.breakpoints.down(800)]: {
//     height: 300,
//     margin: "40px 0 0px 0",
//   },
// }));
