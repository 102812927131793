import { MenuItem, ListItemIcon, ListItemText, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  listItemIconRoot: {
    // color: theme.palette.text.hint,
    color: "#888",
    minWidth: 0,
  },
  listItemTextRoot: {
    // color: theme.palette.text.hint,
    color: "#888",
    padding: `0 ${theme.spacing(1)}px`,
  },
}));

export default function IconMenuItem({
  onClick,
  icon,
  primary,
  secondary,
  ...other
}: IProps) {
  const classes = useStyles(other);
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
        {icon}
      </ListItemIcon>
      <ListItemText
        classes={{ root: classes.listItemTextRoot }}
        primary={primary}
        secondary={secondary}
      />
    </MenuItem>
  );
}

interface IProps {
  onClick: React.MouseEventHandler<EventTarget>;
  icon: React.ReactNode;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  classes?: any;
}
