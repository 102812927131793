import { createContext, useContext, useState, useEffect, ReactNode, Dispatch } from 'react';
import FT from '../../../libs/FT';

const UserContext = createContext<IContextProps>({ user: { showWelcomeMessage: false, isAdmin: false, userId: '' }, setUser: () => { }, loading: true });

export const useUserContext = () => {
    return useContext(UserContext);
}

export const UserProvider = ({ children }: { children?: ReactNode }) => {
    const [user, setUser] = useState<any>({ showWelcomeMessage: false });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const u = await FT.getAuthorizedUser();
            // console.log(">> auth user ", u);
            setUser(u);
            setLoading(false);
        })();
    }, []); //eslint-disable-line

    return (
        <UserContext.Provider value={{ user, setUser, loading }}>
            {children}
        </UserContext.Provider>
    )
}

interface IContextProps {
    user: { showWelcomeMessage: boolean, isAdmin: boolean, userId: string },
    setUser: Dispatch<any>,
    loading: boolean
}