import React, { useRef, useState } from "react";
import YouTube from "react-youtube";
import { styled } from "@mui/material";
import osVideoImg from "../../../images/landing/osVideoImg.png";

const OsVideo = styled("div")(({ theme }) => ({
  maxWidth: "1072px",
  width: "1072px",
  margin: "auto",
  display: "flex",
  alignItems: "center",
  justifiContent: "center",
  height: "623px",
  [theme.breakpoints.down(1150)]: {
    width: "calc(100% - 40px)",
  },

  [theme.breakpoints.down(760)]: {
    width: "100%",
  },
}));

const TublianOsVideo: React.FC = () => {
  const [videoStarted, setVideoStarted] = useState(false);

  const onReady = (event: { target: { pauseVideo: () => void } }) => {
    event.target.pauseVideo();
    setVideoStarted(true);
  };

  const options = {
    width: "100%",
    height: "100%",
    playerVars: {
      autoplay: 0,
      controls: 0,
      rel: 0,
    },
  };

  const youtubeRef = useRef<any>();

  return (
    <OsVideo>
      {!videoStarted && (
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={osVideoImg}
          alt="Tublian Operating system"
        />
      )}
      <YouTube
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 40,
        }}
        videoId="cYMZ5uCP7Dc"
        opts={options}
        onReady={onReady}
        ref={youtubeRef}
      />
    </OsVideo>
  );
};

export default TublianOsVideo;
