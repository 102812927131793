import {useState, useRef, useEffect, RefObject, MouseEvent, MouseEventHandler, KeyboardEvent, KeyboardEventHandler} from 'react';

export const useMenuState = () : IMenuState => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        // console.log('clicked');
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleListKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return {
        anchorRef: anchorRef,
        open: open,
        toggle: handleToggle,
        onClose: handleClose,
        onListKeyDown: handleListKeyDown
    };
}

export interface IMenuState {
    anchorRef: RefObject<HTMLButtonElement>,
    open: boolean,
    toggle: MouseEventHandler,
    onClose: MouseEventHandler,
    onListKeyDown: KeyboardEventHandler
}