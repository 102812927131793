import { userProfile } from './UserProfile';

const twitter_auth_lambda_url = `${process.env.REACT_APP_FT_BACKEND_API_BASE}/auth/twitter`;
const twitter_lambda_url = `${process.env.REACT_APP_FT_BACKEND_API_BASE}/user/twitter`;
const twitter_lambda_headers = {
  "x-api-key": process.env.REACT_APP_FT_BACKEND_API_KEY,
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

const getHeaders = () => {
  const headers = { ...twitter_lambda_headers };

  const jwt = userProfile(localStorage).token();
  if(jwt) headers.Authorization = `Bearer ${jwt}`;

  return headers;
}

const Twitter = {
    async signIn(code) {
      try {
        var host = window.location.protocol + "//" + window.location.host;
        var body = await this.requestToken(code || '', host);
        var token = body.token;
        window.open("https://api.twitter.com/oauth/authorize?oauth_token=" + token, "_self");
      } catch(e) {
        console.log(e);
      }
    },
    async accessToken(token, oauth_verifier) {
        var response = await fetch(twitter_auth_lambda_url, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify({
              type: "access_token",
              verifier: oauth_verifier,
              token: token})
        });

        return response.json();
    },
    async post(status) {
      var response = await fetch(twitter_lambda_url, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ type: "post",  status: status})
      });
      return response.json();
    },
    async lookup(memberId) {
      var response = await fetch(twitter_lambda_url, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ type: "lookup",  screenName: memberId})
      });
      return response.json();
    },
    async requestToken(code, host) {
        var response = await fetch(twitter_auth_lambda_url, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify({
            type: "request_token",
            code: code,
            host: host
          })
        });
        return response.json();
    },

    async currentUserInfo() {
        var twitterInfo = await fetch(twitter_lambda_url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ type: "user_info" })
        });
        return twitterInfo.json();
    },
    async timeline() {
        var twitterInfo = await fetch(twitter_lambda_url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ type: "timeline" })
        });
        return twitterInfo.json();
    },
    getOriginalAvatarImage(avatarUrl) {
      try {
        const url = new URL(avatarUrl);
        const pathParts = url.pathname.split('/');
        const extension = pathParts[pathParts.length - 1].split('.').pop();
        const image = `${pathParts[pathParts.length - 1].split('_').slice(0, -1).join('_')}.${extension}`;

        url.pathname = `${pathParts.slice(0, -1).join('/')}/${image}`;
        // console.log(url)
        return url.href;
      } catch { /*ignored*/ }

      return avatarUrl;
    }
};

export default Twitter;