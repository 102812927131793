import { FC } from "react";
import { Box, Typography } from "@mui/material";
import ProgressBar from "../../../Common/progressBar";
import { IRepoStats } from "../../types";
import useStyles from "./style";
import ActivityStat from "./ActivityStat";

const RepoStats: FC<IRepoStats> = ({ commitRate, growthRate, health }) => {
  const classes = useStyles();
  const healthColor = health
    ? health > 50
      ? "#76F368"
      : health > 40
      ? "#FDDF49"
      : "#F26663"
    : "#F26663";

  return (
    <div className={classes.stats}>
      <div className={classes.healthWrap}>
        {commitRate !== undefined && (
          <Box className={classes.activityTag}>
            <Typography className={classes.statText}>Activity:</Typography>
            <ActivityStat activity={commitRate} type="activity_tag" />
          </Box>
        )}

        {commitRate !== undefined && (
          <Box className={classes.commitBar}>
            <Typography className={classes.statText}>PR Overview:</Typography>
            <Box style={{ display: "flex", gap: 6 }}>
              <ActivityStat
                activity={commitRate}
                growthRate={growthRate}
                type="commit_bar"
              />
            </Box>
          </Box>
        )}

        {health !== undefined && (
          <Box className={classes.healthBar}>
            <Typography className={classes.statText}>Health:</Typography>
            <Typography className={classes.statText}>
              {health > 50 ? "Good" : health > 40 ? "Okay" : "Bad"}
            </Typography>
            <Box className={classes.healthBarWrap}>
              <ProgressBar
                height={6}
                current={Math.min(health, 100)}
                max={100}
                progressColor={healthColor}
                borderRadius={10}
              />
            </Box>
          </Box>
        )}
      </div>
    </div>
  );
};

export default RepoStats;
