import "../../../css/App.css";
import {
  fetchAnswer,
  submitAnswer,
  submitTextAnswer,
} from "../../../service/Service";
import { useEffect, useState } from "react";
import Land from "../../../css/landing.module.css";
import { Box, Typography, styled } from "@mui/material";
import { Recorder } from "react-voice-recorder";
import "../../../css/audio.recorder.css";
import { Divider } from "@mui/material";
import JustLoading from "../../../../../Common/JustLoading";
import QnABtns from "./QnABtns";
import Spinner from "../../../../../Common/Spinner";
import {
  CheckCircleOutlined,
  Close,
  HighlightOffRounded,
} from "@mui/icons-material";

const QuestionAnswerCard = ({
  question,
  questionNumber, // Has to be unique
  showAnswer = true,
  answered,
  bgColor = "#292929",
  callback,
}) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gptAnswer, setGptAnswer] = useState({});
  const [result, setResult] = useState({});
  let maxTime = 60; //Audio recording maximum time of 1 minute

  const [recordingStatus, setRecordingStatus] = useState({
    mode: "text", // "text" | "voice"
    status: "idle", //idle, active, recording, paused, stopped, submitted
    uploading: false,
    seconds: 0,
  });

  const status = recordingStatus.status;
  const isIdle = status === "idle";

  const updateRecordingStatus = (key, newValue) => {
    setRecordingStatus((prevState) => ({ ...prevState, [key]: newValue }));
  };

  const handleButtonClick = (newStatus, selector) => {
    updateRecordingStatus("status", newStatus);
    document.querySelector(`._${questionNumber} .${selector}`).click();
  };

  const [audioState, setAudioState] = useState({
    audioDetails: {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    },
  });

  const fetchData = async (question) => {
    // console.log(">>> fetching answer ", question);
    setLoading(true);
    try {
      const res = await fetchAnswer(question);
      setGptAnswer(res.answer);
    } catch (error) {
      setError(true);
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  const renderAnswer = (answer) => {
    if (gptAnswer.answer) {
      return (
        <ResultsWrap>
          <Box className="content">
            <Typography className={Land.summary_header}>
              Key points to cover
            </Typography>
            <Box style={{ paddingTop: "10px" }}>
              <ul className={Land.summary_list}>
                {gptAnswer.answer.map((req, i) => (
                  <li key={i} style={{ marginBottom: "5px" }}>
                    {req}
                  </li>
                ))}
              </ul>
            </Box>
            <Typography className={Land.summary_header}>Explanation</Typography>

            <Typography className={Land.summary_header}>
              {gptAnswer.explanation}
            </Typography>
          </Box>
        </ResultsWrap>
      );
    }
    return <></>;
  };

  const handleAudioStop = (data) => {
    // console.log("Stopped", data);
    setAudioState({ audioDetails: data });
  };

  const handleAudioUpload = async (file, question) => {
    updateRecordingStatus("uploading", true);
    var fd = new FormData();
    fd.append("file", file, "answer.webm");
    fd.append("question", question);
    const r = await submitAnswer(fd);
    // console.log(">>>> response ", r);

    if (callback) {
      callback(r);
    }

    setResult(r);
    setRecordingStatus((prevState) => ({
      ...prevState,
      uploading: false,
      status: "submitted",
    }));
  };

  const handleTextAnswer = async (answer, question) => {
    updateRecordingStatus("uploading", true);
    const response = await submitTextAnswer(answer, question);
    // console.log("🚀 ~ handleTextAnswer ~ response:", response);

    if (callback) {
      callback(response);
    }

    setResult(response);
    setRecordingStatus((prevState) => ({
      ...prevState,
      uploading: false,
      status: "submitted",
    }));
  };

  const handleReset = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setAudioState({ audioDetails: reset });
    setResult({});
    setGptAnswer({});
    setRecordingStatus({
      mode: "text",
      status: "idle",
      uploading: false,
      seconds: 0,
    });
  };

  var callbackCounter = 0;

  const handleCountDown = (data) => {
    callbackCounter++;

    if (callbackCounter % 10 === 0) {
      setRecordingStatus((prevState) => ({
        ...prevState,
        seconds: prevState.seconds + 1,
      }));
    }

    if (recordingStatus.seconds === maxTime) {
      updateRecordingStatus("status", "stopped");
      document.querySelector("._1bSom")?.click();
    }
  };

  useEffect(() => {
    handleReset();
  }, [question]);

  return (
    <Box
      sx={{ background: `${bgColor} !important` }}
      className={Land.topic_card}
    >
      <StyledTitleWrap status={recordingStatus.status}>
        <StyledQuestionTitle idle={isIdle ? "true" : undefined} component="h4">
          {questionNumber}. {question}
        </StyledQuestionTitle>
        {!isIdle && (
          <Close
            htmlColor="#FEFEFE"
            sx={{ cursor: "pointer", width: "17px", height: "17px" }}
            onClick={() => {
              handleButtonClick("active", "_2gd2_");
              // updateRecordingStatus("status", "idle");
              handleReset();
            }}
          />
        )}
      </StyledTitleWrap>

      <QnABtns
        loading={loading}
        correctness={result.analysis?.correctness}
        recordingStatus={recordingStatus}
        // setRecordingStatus={setRecordingStatus}
        updateRecordingStatus={updateRecordingStatus}
        audioState={audioState}
        fetchData={fetchData}
        handleTextAnswer={handleTextAnswer}
        question={question}
        handleReset={handleReset}
        maxTime={maxTime}
        showAnswer={showAnswer}
        questionNumber={questionNumber}
        answered={answered}
      />

      <Box sx={{ display: "none" }}>
        <Divider className={Land.divider} />
        <Box className={`_${questionNumber}`}>
          <Recorder
            hideHeader
            record={true}
            title={"New recording"}
            audioURL={audioState.audioDetails.url}
            showUIAudio
            handleAudioStop={handleAudioStop}
            handleAudioUpload={(data) => handleAudioUpload(data, question)}
            handleReset={handleReset}
            handleCountDown={handleCountDown}
            mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
          />
        </Box>
      </Box>

      {result.analysis ? (
        <ResultsWrap>
          <TitleBox>
            <Typography variant="body1">Your Answer</Typography>
          </TitleBox>
          <Box className="content">
            <Typography style={{ color: "#B7B7B7" }}>
              {result.given_answer}
            </Typography>
            <Typography
              component="h4"
              style={{ margin: "20px 0 8px", fontWeight: 700 }}
              className={Land.topic_name}
              sx={{ display: "flex", alignItems: "center" }}
            >
              Your answer is
              {result.analysis?.correctness ? " correct" : " not correct"}
              {result.analysis?.correctness ? (
                <CheckCircleOutlined
                  sx={{ marginLeft: "10px" }}
                  htmlColor="#1AFF8C"
                />
              ) : (
                <HighlightOffRounded
                  sx={{ marginLeft: "10px" }}
                  htmlColor="#F26663"
                />
              )}
            </Typography>
            <Typography>{result.analysis?.explanation}</Typography>
          </Box>
        </ResultsWrap>
      ) : recordingStatus.uploading ? (
        <UploaderWrap>
          <Typography>Please wait while your answer is processed</Typography>
          <span className="spinner">
            <Spinner size={18} pryColor="#FEFEFE" secColor="#121212" />
          </span>
        </UploaderWrap>
      ) : null}

      {loading ? (
        <JustLoading />
      ) : Object.keys(gptAnswer)?.length > 0 &&
        recordingStatus.status === "active" ? (
        renderAnswer(gptAnswer)
      ) : null}
      {error && <p style={{ textAlign: "center" }}>Problem loading data</p>}
    </Box>
  );
};

export default QuestionAnswerCard;

const ResultsWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 12,
  marginTop: 20,
  maxHeight: 266,
  "& h4": {
    fontWeight: 700,
    fontSize: "0.875rem",
    lineHeight: 1.25,
  },
  "& p": {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.25,
    color: "#FEFEFE",
  },
  "& span.spinner": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .content": {
    maxHeight: 190,
    overflowY: "scroll",
    paddingRight: 20,
    scrollbarWidth: "thin",
    scrollbarColor: "#888 transparent",
    scrollbarHeight: 64,
    "-ms-scrollbar-height": 64,
    "&::-webkit-scrollbar": {
      width: 4,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      backgroundColor: "#888",
      height: 64,
      minHeight: 64,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  [theme.breakpoints.down(700)]: {
    maxHeight: 506,
    "& .content": {
      maxHeight: 430,
    },
  },
}));

const UploaderWrap = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 6,
  paddingLeft: "12px",
  "& p": {
    fontWeight: 500,
    fontSize: "0.75rem",
    lineHeight: "1rem",
    color: "#888",
  },
  "& span.spinner": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const TitleBox = styled(Box)({
  display: "flex",
  gap: 7,
  "& p": {
    color: "#FEFEFE",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: 1.5,
  },
});

const StyledTitleWrap = styled(Box)(({ theme, status }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "12px",
  border: status !== "idle" ? "1px solid #292929" : "",
  borderRadius: status !== "idle" ? "10px" : "0",
  padding: status !== "idle" ? "14px 20px" : "",
}));

const StyledQuestionTitle = styled(Typography)(({ theme, idle }) => ({
  fontSize: (idle = "true" ? "1rem" : "0.75rem"),
  width: (idle = "true" ? "100%" : "80%"),
  color: (idle = "true" ? "#FEFEFE" : "#B7B7B7"),
  lineHeight: (idle = "true" ? "1.5rem" : "1rem"),
  fontWeight: (idle = "true" ? 400 : 500),
}));
