import { FC, useEffect, useState } from "react";
import { Box, Typography, styled } from "@mui/material";

import CustomButton from "../../../Common/CustomButton";
import {
  ArrowBackOutlined,
  CheckCircleOutlineRounded,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import FT from "../../../../libs/FT";
import { CreditPaymentHistoryItem } from "../../Common/LearningTypes";
import DateUtils from "../../../../libs/DateUtils";
import Spinner from "../../../Common/Spinner";

const EightyTwentyPaymentHistory: FC<IProps> = ({ isPublic }) => {
  const [loading, setLoading] = useState(false);

  const [paymentHistory, setPaymentHistory] = useState<
    CreditPaymentHistoryItem[] | undefined
  >();
  const historyLimit = 5;
  const historyLimitIncrement = 2;
  const [historyDisplayLimit, setHistoryDisplayLimit] = useState(historyLimit);

  const showMoreHistory = () => {
    setHistoryDisplayLimit(historyDisplayLimit + historyLimitIncrement);
  };

  const showLessHistory = () => {
    setHistoryDisplayLimit(historyLimit);
  };

  const showMore = historyDisplayLimit < (paymentHistory?.length || 0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await FT.getCreditPaymentInfo("paymentHistoryInfo");

        console.log("🚀 ~ fetchData ~ historyRes:", res);
        if (res.error) {
          console.error("error", res.error);
          throw new Error(res.error);
        }
        setPaymentHistory(res);
      } catch (error) {
        console.error("error", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <PrimaryColumn>
      <Box>
        <CustomButton
          size="small"
          type="tertiary"
          onClick={() => window.history.back()}
        >
          <ArrowBackOutlined />
          Back
        </CustomButton>
        <SectionHeader sx={{ margin: "12px 0 0" }}>
          <StyledHeader>Payment History</StyledHeader>
          <StyledSubHeader>View all of your payment history</StyledSubHeader>
        </SectionHeader>
      </Box>

      <PaymentHistoryCardWrap>
        {loading && <Spinner />}
        {!loading && (!paymentHistory || paymentHistory.length === 0) && (
          <Box padding="8px 0">
            <Typography
              fontSize="0.875rem"
              fontWeight={500}
              lineHeight="1.25rem"
              color="#CFCFCF"
            >
              No payment history found
            </Typography>
          </Box>
        )}

        {!loading &&
          paymentHistory
            ?.slice(0, historyDisplayLimit)
            ?.map(({ currency, amount, created, status }, i) => {
              let currencyId =
                currency === "inr" ? "₹" : currency === "ngn" ? "₦" : "$";

              return (
                <PaymentHistoryCard key={i}>
                  <Box>
                    <StyledHeader>
                      {currencyId}
                      {amount / 100}
                    </StyledHeader>
                    <StyledSubHeader sx={{ fontWeight: 500 }}>
                      {DateUtils.getFormattedDate(new Date(created * 1000))}
                    </StyledSubHeader>
                  </Box>
                  <StyledSubHeader sx={{ fontWeight: 500, color: "#B7B7B7" }}>
                    {status === "succeeded" ? (
                      <StyledCheck htmlColor="#1AFF8C" />
                    ) : (
                      <StyledCloseIcon htmlColor="#FA8785" />
                    )}
                    {status === "succeeded" ? "Successful" : "Failed"}
                  </StyledSubHeader>
                </PaymentHistoryCard>
              );
            })}

        {paymentHistory && (paymentHistory?.length || 0) > historyLimit && (
          <CustomButton
            postImg={showMore ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            type="tertiary"
            size="medium"
            onClick={showMore ? showMoreHistory : showLessHistory}
            style={{ marginTop: "12px" }}
          >
            {showMore ? "Show More History" : "Show Less History"}
          </CustomButton>
        )}
      </PaymentHistoryCardWrap>
    </PrimaryColumn>
  );
};
export default EightyTwentyPaymentHistory;

interface IProps {
  isPublic: boolean;
}

const PrimaryColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: "20px",
});

const SectionHeader = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const StyledHeader = styled(Typography)<{ color?: string }>(
  ({ theme, color = "#FEFEFE" }) => ({
    color,
    fontWeight: 700,
    fontSize: "1.125rem",
    lineHeight: "1.875rem",
  })
);

const StyledSubHeader = styled(Typography)<{ color?: string }>(
  ({ theme, color = "#888" }) => ({
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color,
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  })
);

const PaymentHistoryCardWrap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
});

const PaymentHistoryCard = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 0",
  width: "100%",
  "&>div": {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
}));

const StyledCheck = styled(CheckCircleOutlineRounded)({
  width: "18px",
  height: "18px",
});

const StyledCloseIcon = styled(Close)({
  borderRadius: "50%",
  border: "1px solid #FA8785",
  padding: "4px",
  width: "18px",
  height: "18px",
});
