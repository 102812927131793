import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { RemoveRounded, TrendingDown, TrendingUp } from "@mui/icons-material";
import ProgressBar from "../../../Common/progressBar";
import { arrowDownSvg, arrowUpSvg } from "../../../UserHome/Base/SVG";
import useStyles from "./style";

interface ActivityStatProps {
  activity: number;
  growthRate?: number;
  type: "activity_tag" | "commit_bar";
}

const ActivityStat: FC<ActivityStatProps> = ({
  activity,
  growthRate,
  type,
}) => {
  const classes = useStyles();
  const activityColor =
    activity > 10 ? "#76F368" : activity > 7 ? "#FDDF49" : "#F26663";

  const growthRateColor = growthRate
    ? Math.round(growthRate) > 0
      ? "#76F368"
      : "#F26663"
    : "";

  const activityText = activity > 10 ? "high" : activity > 7 ? "medium" : "low";

  const activityIcon =
    activity > 10 ? (
      <TrendingUp />
    ) : activity > 7 ? (
      <RemoveRounded />
    ) : (
      <TrendingDown />
    );

  if (type === "activity_tag") {
    return (
      <span className={`pill ${activityText}`}>
        {activityIcon}
        <Typography>{activityText}</Typography>
      </span>
    );
  }

  if (type === "commit_bar") {
    return (
      <Box className={classes.commitBarWrap}>
        <Typography style={{ color: "#FEFEFE", marginRight: 4 }}>{`${Math.round(
          activity
        )} PRs/wk`}</Typography>

        {growthRate ? (
          Math.round(growthRate) > 0 ? (
            <Box
              style={{
                display: "flex",
                color: growthRateColor,
                marginRight: 2,
              }}
            >
              {arrowUpSvg}
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                color: growthRateColor,
                marginRight: 2,
              }}
            >
              {arrowDownSvg}
            </Box>
          )
        ) : null}

        <Typography style={{ color: growthRateColor, marginRight: 6 }}>{`${
          growthRate ? Math.round(growthRate) + "%" : ""
        }`}</Typography>

        <Box className={classes.activityWrap}>
          <ProgressBar
            height={6}
            current={Math.min(activity, 20)}
            max={20}
            progressColor={activityColor}
            borderRadius={10}
          />
        </Box>
      </Box>
    );
  }

  return null;
};

export default ActivityStat;
