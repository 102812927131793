import {
  Box,
  Icon,
  Skeleton,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { RefreshSvg, dislikeSvg } from "../../UserHome/Base/SVG";
import { ArrowRightAltOutlined } from "@mui/icons-material";

export const RoadmapCardSkeleton = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
      }}
    >
      {[...Array(3)].map((_, i) => (
        <Box
          key={i}
          width="100%"
          height="144px"
          sx={{
            backgroundColor: "#1E1E1E",
            borderRadius: "15px",
            padding: "16px 20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="84px"
            height="24px"
            sx={{
              backgroundColor: "#292929",
              padding: "4px 6px",
              color: "#FEFEFE",
              fontSize: "0.75rem",
              fontWeight: 400,
              lineHeight: "1rem",
              borderRadius: "28px",
            }}
          >
            User Prompt
          </Skeleton>
          <Skeleton
            animation="wave"
            variant="text"
            width="317px"
            height="24px"
            sx={{ margin: "8px 0 16px" }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width="145px"
            height="40px"
            sx={{ borderRadius: "30px" }}
          />
        </Box>
      ))}
    </Box>
  );
};

export const CreditsPackagesSkeleton = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "18px",
      }}
    >
      {[...Array(3)].map((_, i) => (
        <Box
          key={i}
          width="100%"
          height="235px"
          sx={{
            backgroundColor: "#1E1E1E",
            borderRadius: "20px",
            padding: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Skeleton
              animation="wave"
              variant="rounded"
              width="57px"
              height="30px"
              sx={{
                backgroundColor: "#696969",
                margin: "7.5px 0",
              }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="112px"
              height="30px"
              sx={{
                backgroundColor: "#292929",
                padding: "4px 6px",
                fontSize: "0.75rem",
                fontWeight: 400,
                lineHeight: "1rem",
                borderRadius: "81px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              margin: "14px 0 20px",
              gap: "8px",
            }}
          >
            {[1, 2, 3].map((_, i) => (
              <Skeleton
                animation="wave"
                key={`text-${i}`}
                variant="text"
                width="317px"
                height="20px"
              />
            ))}
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              width="104px"
              height="40px"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "30px",
                fontSize: "1rem",
                fontWeight: 500,
                lineHeight: "1.5rem",
                color: "#FEFEFE",
              }}
            >
              Purchase
            </Skeleton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const PaymentHistorySkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "20px 0 0",
        gap: "4px",
      }}
    >
      {[...Array(5)].map((_, i) => (
        <Box
          key={i}
          width="100%"
          height="68px"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              alignItems: "flex-start",
            }}
          >
            <Skeleton
              animation="wave"
              variant="text"
              width="61px"
              height="30px"
              sx={{
                backgroundColor: "#696969",
                margin: "5px 0",
              }}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="82px"
              height="20px"
              sx={{
                backgroundColor: "#292929",
                padding: "4px 6px",
                fontSize: "0.75rem",
                fontWeight: 400,
                lineHeight: "1rem",
                borderRadius: "4px",
              }}
            />
          </Box>

          <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <Skeleton
              animation="wave"
              variant="circular"
              width="18px"
              height="18px"
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="75px"
              height="20px"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "1rem",
                fontWeight: 500,
                lineHeight: "1.5rem",
                color: "#FEFEFE",
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const QuestionTasksSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "20px 0 0",
        gap: "20px",
      }}
    >
      {[...Array(2)].map((_, i) => (
        <Box
          key={i}
          width="100%"
          height="128px"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "column",
            background: "#1E1E1E",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height="24px"
              sx={{
                backgroundColor: "#696969",
                fontSize: "1rem",
                fontWeight: 400,
                lineHeight: "1.5rem",
                borderRadius: "8px",
                width: "100%",
              }}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="45%"
              height="24px"
              sx={{
                backgroundColor: "#696969",
                fontSize: "1rem",
                fontWeight: 400,
                lineHeight: "1.5rem",
                borderRadius: "8px",
              }}
            />
          </Box>

          <Skeleton
            animation="wave"
            variant="rounded"
            width="138px"
            height="36px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "30px",
              fontSize: "0.875rem",
              fontWeight: 500,
              lineHeight: "1.25rem",
              color: "#FEFEFE",
            }}
          >
            Answer Question
          </Skeleton>
        </Box>
      ))}
    </Box>
  );
};

export const IssuesTasksSkeleton = () => {
  const isMobile = useMediaQuery("(max-width: 700px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "20px 0 0",
        gap: "14px",
      }}
    >
      {[...Array(3)].map((_, i) => (
        <Box
          key={i}
          width="100%"
          height={isMobile ? "auto" : "180px"}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "column",
            background: "#1E1E1E",
            borderRadius: "20px",
            padding: "20px",
          }}
        >
          <Box width="100%" display="flex" flexDirection="column" gap="8px">
            {/* Header */}
            <Box
              display="flex"
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "8px",
              }}
            >
              <Box display="flex" gap="12px">
                {(isMobile ? [1] : [1, 2]).map((_, i) => (
                  <Skeleton
                    animation="wave"
                    key={i}
                    variant="text"
                    width="100px"
                    height="36px"
                    sx={{
                      border: "1px solid #696969",
                      backgroundColor: "#696969",
                      fontSize: "1rem",
                      fontWeight: 400,
                      lineHeight: "1.5rem",
                      borderRadius: "12px",
                    }}
                  />
                ))}
              </Box>

              <Box
                ml="auto"
                sx={{
                  "@media (max-width: 700px)": {
                    display: "none",
                  },
                }}
              >
                <Typography
                  sx={{
                    textDecoration: "none",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    color: "#FDD649",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    "&:hover": {
                      color: "#FEE38B",
                    },
                  }}
                >
                  View issue
                  <ArrowRightAltOutlined />
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                border: isMobile ? "1px solid #414141" : "",
                borderRadius: "10px",
              }}
            >
              <Box
                display="flex"
                gap="8px"
                fontSize="1rem"
                lineHeight="1.5rem"
                fontWeight={500}
                color="#888"
                sx={{
                  borderBottom: isMobile ? "1px solid #414141" : "",
                  padding: isMobile ? "12px" : "",
                }}
              >
                Repo:
                <Skeleton
                  animation="wave"
                  width="110px"
                  sx={{ backgroundColor: "#696969" }}
                />
              </Box>

              <Box
                sx={{
                  padding: isMobile ? "12px" : "",
                }}
              >
                <Box
                  fontSize="1rem"
                  lineHeight="1.5rem"
                  fontWeight={500}
                  color="#888"
                  marginBottom={isMobile ? "8px" : ""}
                >
                  <Skeleton
                    animation="wave"
                    sx={{
                      display: "flex",
                      width: "75%",
                      backgroundColor: "#696969",
                    }}
                  />
                </Box>

                <Box
                  display="flex"
                  gap="8px"
                  fontSize="1rem"
                  lineHeight="1.5rem"
                  fontWeight={500}
                  color="#888"
                >
                  Est. Score
                  <Skeleton
                    animation="wave"
                    width="40px"
                    sx={{ backgroundColor: "#696969" }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {isMobile && (
            <Skeleton
              animation="wave"
              width="100%"
              height="40px"
              variant="rectangular"
              sx={{
                borderRadius: "30px",
                marginTop: isMobile ? "36px" : "",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FDD649",
              }}
            >
              View Issue
            </Skeleton>
          )}
        </Box>
      ))}
    </Box>
  );
};

export const LearningContentSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "14px",
      }}
    >
      {/* objective */}
      <Skeleton
        animation="wave"
        variant="text"
        width="80%"
        height="30px"
        sx={{
          backgroundColor: "#696969",
          fontSize: "1.125rem",
          fontWeight: 700,
          lineHeight: "1.875rem",
          borderRadius: "8px",
          width: "100%",
        }}
      />

      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "left",
          gap: "20px",
        }}
      >
        {/* card */}
        {[...Array(2)].map((_, i) => (
          <Box
            key={i}
            height="375px"
            sx={{
              display: "flex",
              // alignItems: "flex-start",
              justifyContent: "space-between",
              flexDirection: "column",
              background: "#1E1E1E",
              borderRadius: "20px",
              width: "calc(50% - 20px)",
              "@media (max-width: 1110px)": {
                width: "100%",
              },
            }}
          >
            <Box width="100%" height="169px">
              <Skeleton // image thumbnail
                animation="wave"
                height="100%"
                width="100%"
                variant="rectangular"
                sx={{ borderRadius: "20px 20px 0 0" }}
              />
            </Box>

            <Box width="100%" padding="20px">
              <Box width="100%" margin="0 0 20px">
                {/* Descriptions */}
                <Skeleton
                  animation="wave"
                  key={i}
                  variant="text"
                  width="100%"
                  height="30px"
                  sx={{
                    backgroundColor: "#696969",
                    fontSize: "1.125rem",
                    fontWeight: 700,
                    lineHeight: "1.875rem",
                    borderRadius: "8px",
                    width: "100%",
                    marginBottom: "12px",
                  }}
                />
                <Box>
                  {[1, 2, 3].map((_, i) => (
                    <Skeleton
                      animation="wave"
                      key={i}
                      variant="text"
                      width="100%"
                      height="20px"
                      sx={{
                        backgroundColor: "#696969",
                        fontSize: "0.875rem",
                        fontWeight: 400,
                        lineHeight: "1.25rem",
                        borderRadius: "8px",
                        width: "100%",
                      }}
                    />
                  ))}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <Skeleton // Button
                  animation="wave"
                  variant="rounded"
                  width="138px"
                  height="36px"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "30px",
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    lineHeight: "1.25rem",
                    color: "#FEFEFE",
                  }}
                >
                  Mark as complete
                </Skeleton>
                <StyledRefresh>{RefreshSvg}</StyledRefresh>
                <DislikeWrap>
                  <DislikeBox>{dislikeSvg}</DislikeBox>
                </DislikeWrap>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>

      {/* summary */}
      <Box>
        {[...Array(4)].map((_, i) => (
          <Skeleton
            animation="wave"
            key={i}
            variant="text"
            width="80%"
            height="20px"
            sx={{
              backgroundColor: "#696969",
              fontSize: "0.875rem",
              fontWeight: 400,
              lineHeight: "1.25rem",
              borderRadius: "8px",
              width: "100%",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export const CourseSidebarSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" gap="4px">
      {[...Array(3)].map((_, i) => (
        <Skeleton
          key={i}
          animation="wave"
          variant="text"
          width="100%"
          height="36px"
          sx={{
            backgroundColor: "#292929",
            borderRadius: "8px",
          }}
        />
      ))}
    </Box>
  );
};

const DislikeWrap = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  cursor: "pointer",
  "& svg": {
    color: "#FEFEFE",
  },
  "& div": {
    cursor: "pointer",
  },
});

const DislikeBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "3px",
  borderRadius: 0,
  background: "",
  cursor: "pointer",
}));

const StyledRefresh = styled(Icon)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#FEFEFE",
  cursor: "pointer",
});
