import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: "inherit",
    textDecoration: "none",
  },
  container: {
    width: "100%",
    borderRadius: 20,
    backgroundColor: "#1E1E1E",
    [theme.breakpoints.down(700)]: {
      width: "100%",
      minWidth: "unset",
      maxWidth: "unset",
    },
  },
  root: {
    padding: 20,
  },
  icon: {
    width: 40,
    height: 40,
    backgroundColor: "#301E21",
    color: "#F43E5C",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    [theme.breakpoints.down(700)]: {
      width: 30,
      height: 30,
    },
  },
  iconImg: {
    width: 40,
    height: 40,
    backgroundColor: "#301E21",
    color: "#F43E5C",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    padding: 8,
    [theme.breakpoints.down(700)]: {
      width: 30,
      height: 30,
    },
  },
  tagWrap: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    [theme.breakpoints.down(700)]: {
      marginBottom: 4,
      "& *": {
        fontSize: "0.75rem",
      },
    },
  },
  lang: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "20px",
    padding: "4px 8px",
    color: "#FEFEFE",
    border: "1px solid #696969",
    borderRadius: 20,
    textTransform: "capitalize",
  },
  tublianPickTag: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "20px",
    padding: "4px 8px",
    color: "#00806C",
    border: "1px solid #00806C",
    borderRadius: 20,
    textTransform: "capitalize",
  },
  easyTag: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.25,
    padding: "4px 8px",
    color: "#2DB7F8",
    textAlign: "center",
    border: "1px solid #2DB7F8",
    borderRadius: 20,
    textTransform: "capitalize",
  },
  headerWrap: {
    marginBottom: 16,
    [theme.breakpoints.down(700)]: {
      marginBottom: 12,
    },
  },
  headerRow1: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(700)]: {
      alignItems: "flex-start",
    },
    "& .descItems": {
      display: "flex",
      alignItems: "center",
      gap: 12,
    },
  },
  headerSubtitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "20px",
    color: "#888",
    marginTop: 12,
  },
  btnTransparent: {
    textDecoration: "none",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.25,
    color: "#FDD649",
    display: "flex",
    alignItems: "center",
    gap: 8,
    "&:hover": {
      color: "#FEE38B",
    },
  },
  contentWrap: {
    display: "flex",
    flexDirection: "column",
    "& a": {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: "#FEFEFE",
      },
    },
  },
  footerWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: 16,
    [theme.breakpoints.down(700)]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  completeBtn: {
    [theme.breakpoints.down(700)]: {
      marginTop: 16,
    },
  },
  status: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "20px",
    padding: "4px 8px",
    color: "#FEFEFE",
    border: "1px solid #FACC14",
    borderRadius: 20,
    marginLeft: 16,
  },
  healthWrap: {
    display: "flex",
    gap: 12,
    [theme.breakpoints.down(1440)]: {
      gap: 10,
    },
    [theme.breakpoints.down(725)]: {
      gap: 16,
    },
    [theme.breakpoints.down(700)]: {
      flexDirection: "column",
      gap: 8,
    },
  },
  statText: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.25,
    color: "#888",
  },
  activityTag: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    minWidth: 135,
    "& p": {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.25,
    },
    "& .pill": {
      display: "flex",
      alignItems: "center",
      fontWeight: 400,
      lineHeight: 1.25,
      gap: 8,
      borderRadius: 20,
      padding: "2px 8px",
      "& p": {
        textTransform: "capitalize",
      },
      "& svg": {
        width: 12,
        height: 12,
      },
    },
    "& .high": {
      backgroundColor: "#273825",
      color: "#4ADE80",
    },
    "& .medium": {
      backgroundColor: "#393A1A",
      color: "#FDDF49",
    },
    "& .low": {
      backgroundColor: "#492727",
      color: "#FCA6A6",
    },
  },
  commitBarWrap: {
    display: "flex",
    alignItems: "center",
    // gap: 8,
    [theme.breakpoints.down(1200)]: {
      gap: 4,
    },
  },
  activityWrap: {
    width: 48,
    [theme.breakpoints.down(1200)]: {
      width: 29,
    },
  },
  healthBarWrap: {
    width: 49,
    [theme.breakpoints.down(1200)]: {
      width: 29,
    },
  },
  healthBar: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  commitBar: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    "& p": {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.25,
    },
  },
  row: {
    display: "flex",
    // alignItems: "center",
  },
  repoWrap: {
    marginBottom: 8,
    [theme.breakpoints.down(700)]: {
      borderBottom: "1px solid #414141",
      marginBottom: 12,
      paddingBottom: 12,
    },
  },
  issueName: {
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: 1.5,
    color: "#FEFEFE",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    [theme.breakpoints.down(390)]: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: "24px",
    },
  },
  repoDescWrap: {
    marginBottom: 12,
    height: 40,
    [theme.breakpoints.down(700)]: {
      marginBottom: "unset",
    },
  },
  repoDesc: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    color: "#CFCFCF",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    // height: 40,
    [theme.breakpoints.down(700)]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  cardContentWrap: {
    [theme.breakpoints.down(700)]: {
      border: "1px solid #414141",
      borderRadius: 10,
      padding: "12px 0",
      marginBottom: 16,
    },
  },
  issue: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "20px",
    color: "#FEFEFE",
    marginBottom: 12,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    maxHeight: "3em",
    [theme.breakpoints.down(700)]: {
      WebkitLineClamp: 5,
      maxHeight: "120px",
    },
  },
  repo: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.25,
    color: "#888",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    "& span": {
      color: "#FEFEFE",
      marginLeft: 8,
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.25,
    },
    [theme.breakpoints.down(700)]: {
      fontSize: "0.75rem",
      lineHeight: 1,
      width: "100%",
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  issueStatus: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "20px",
    color: "#888",
    marginBottom: 16,
  },
  bodyContent: {
    [theme.breakpoints.down(700)]: {
      padding: "0 12px",
    },
  },
  footerCols: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  stats: {
    justifyContent: "space-between",
    [theme.breakpoints.down(700)]: {
      flexDirection: "column",
      justifyContent: "flex-end",
      gap: 12,
    },
  },
  score: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    color: "#888",
    marginTop: 14,
    // marginBottom: 20,
    [theme.breakpoints.down(700)]: {
      marginTop: 12,
      marginBottom: "unset",
    },
  },
  scoreValue: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "30px",
    background:
      "linear-gradient(109.15deg, #FBDA61 0%, rgba(255, 90, 205, 0.84) 86.08%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    marginLeft: 12,
  },
  lastUpdate: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    color: "#888",
    display: "flex",
    flexWrap: "wrap",
  },
  lastUpdateValue: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "20px",
    color: "#FEFEFE",
    marginLeft: 16,
  },
  progressBarStyles: {
    borderRadius: 10,
  },
  viewBtnMobile: {
    "& button": {
      width: "100% !important",
    },
    marginTop: 20,
    [theme.breakpoints.up(700)]: {
      display: "none",
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down(700)]: {
      display: "none",
    },
  },
  hideOnDesktop: {
    [theme.breakpoints.up(700)]: {
      display: "none",
    },
  },
}));

export default useStyles;
