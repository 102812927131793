import { makeStyles } from "@mui/styles";
import ghosh from "../../images/landing/ghosh.jpg";
import shashar from "../../images/landing/shashar.png";
import amarachiCrystal from "../../images/landing/amarachiCrystal.jpeg";
import calleja from "../../images/landing/calleja.jpg";
import soniya from "../../images/landing/soniya.jpg";
import nwokeOma from "../../images/landing/nwokeOma.jpg";
import abelEmmanuel from "../../images/landing/abelEmmanuel.jpg";
import iediong from "../../images/landing/iediong.jpg";
import victorOkeke from "../../images/landing/victorOkeke.jpg";
import deepanshu from "../../images/landing/deepanshu.jpg";
import twitterLogo from "../../images/twitterSocialIcon.svg";
// import linkedinSocialIcon from "../../images/landing/linkedinIcon.svg";
import mediumIcon from "../../images/landing/mediumIcon.png";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  mission: {
    paddingBottom: 88,
    paddingTop: 140,
    width: "400px",
    maxWidth: "1200px",
    margin: "auto",
    [theme.breakpoints.down(900)]: {
      width: "358px",
      paddingBottom: 80,
    },
    "&>h1": {
      fontSize: 36,
      fontWeight: 700,
      lineHeight: "52px",
      textAlign: "center",
      color: "#fefefe",
      marginBottom: 20,
      [theme.breakpoints.down(900)]: {
        fontSize: 30,
        lineHeight: "45px",
      },
      "&>span": {
        background: "linear-gradient(to right, #FBDA61 0%, #FF5ACD 100%)",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        WebkitTextFillColor: "transparent",
        textFillColor: "transparent",
      },
    },
    "&>p": {
      color: "#B7B7B7",
      lineHeight: "24px",
      fontSize: 16,
      fontWeight: 500,
      textAlign: "center",
      width: "100%",
    },
  },
  testimonials: {
    padding: "0 0 0 64px",
    margin: "auto",
    paddingBottom: "212px",
    [theme.breakpoints.down(900)]: {
      padding: "0 0 203px 0px",
    },
    [theme.breakpoints.down(1200)]: {
      maxWidth: "1200px",
    },
    [theme.breakpoints.up(1201)]: {
      padding: "0 0 140px 0px",
      // width: '100%',
      marginLeft: 80,
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: 400,
    },
  },

  testimonialCard: {
    padding: 20,
    border: "1px solid transparent",
    background: "#19191B",
    borderRadius: "20px",
    zIndex: 999,
    width: "100%",

    "&>p": {
      color: "#B7B7B7",
      lineHeight: "24px",
      fontSize: 16,
      fontWeight: 500,
      wordWrap: "break-word",
    },
  },

  testimonialBox: {
    width: 340,
    paddingLeft: "26px",
    [theme.breakpoints.down(900)]: {
      paddingLeft: "16px",
    },
  },

  info: {
    "&>p": {
      color: "#fefefe",
      lineHeight: "24px",
      fontSize: 16,
      fontWeight: 500,
    },
    "&> .handle": {
      color: "#888888",
      textDecoration: "none",
      lineHeight: "20px",
      fontSize: 14,
      fontWeight: 500,
    },
  },

  image: { height: "40px", width: "40px", borderRadius: "50%" },
}));

export const testimonialsData = [
  {
    name: "E.I. Joe",
    handle: "@IEdiong",
    testimonial:
      "Huge thanks to @tublian for the awesome Frontend challenge! Building this project was a rewarding experience that helped me level up my Angular skills. Especially enjoyed using Reactive Forms.",
    image: iediong,
    socialIcon: twitterLogo,
    link: "https://x.com/IEdiong/status/1759597542445035858?t=DSb37HHrtoNSk4awttHg0Q&s=19",
  },
  {
    name: "Olibhia Ghosh🦋",
    handle: "@OlibhiaGhosh",
    testimonial:
      "Just finished week 1 of my @tublian Open Source Internship 🥳🎉 Made two open-source contributions and loved the experience🙌 Excited for the remaining weeks 🙌🚀",
    image: ghosh,
    socialIcon: twitterLogo,
    lin: "https://x.com/OlibhiaGhosh/status/1769280010420990194?t=I10gQslR0GGmYGbb1H0M4w&s=19",
  },
  {
    name: "Deepanshu Solana",
    handle: "@deez_codes",
    testimonial:
      "Done with Week 1 @tublian Internship 🙇‍♂️✨ ✅ Importance of Open Source in the Software Industry ✅Git and GitHub ✅ First Contribution to Open Source Week 2 will be about Communities and Collaborations! Looking forward to learning and collaborating on a project with peers!",
    image: deepanshu,
    socialIcon: twitterLogo,
    link: "https://x.com/deez_codes/status/1769562917240787177?t=I10gQslR0GGmYGbb1H0M4w&s=19",
  },
  {
    name: "Jorge Calleja",
    handle: "@CallejaPJ",
    testimonial:
      "I just finished the week 1 of my @tublian Open Source Internship 🥳🎉",
    image: calleja,
    socialIcon: twitterLogo,
    link: "https://x.com/CallejaPJ/status/1768784308481736719?t=I10gQslR0GGmYGbb1H0M4w&s=19",
  },
  {
    name: "Harsha S",
    handle: "Software Engineer",
    testimonial:
      "Started Week 3 of Open Source Internship on #TUBLIAN with the wonderful and insightful live session by @kito99. Thank you for this session @tublian. #opensource #internship",
    image: shashar,
    socialIcon: twitterLogo,
    link: "https://x.com/sharsha315/status/1772280444060323968?t=I10gQslR0GGmYGbb1H0M4w&s=19",
  },
  {
    name: "Soniya Prasad",
    handle: "@soniyaprasad77",
    testimonial:
      "Glad to be the part of @tublian community. Attended an amazing session on future of software development by @nraychaudhuri.",
    image: soniya,
    socialIcon: twitterLogo,
    link: "https://x.com/soniyaprasad77/status/1775937091639365635?t=I10gQslR0GGmYGbb1H0M4w&s=19",
  },
  {
    name: "Amarachi Crystal Omereife",
    handle: "@juliegabrielmiller",
    testimonial:
      "In the world of open-source software, one never knows what each day might bring. As I geared up for the second week of my internship, I was tasked with fixing a seemingly straightforward readme issue. Little did I know that this task would soon evolve into something more intricate.",
    image: amarachiCrystal,
    socialIcon: mediumIcon,
    link: "https://medium.com/@marameref/my-second-week-at-tublian-internship-navigating-the-world-of-ci-with-github-actions-22571644a0f9",
  },
  {
    name: "nwoke oma",
    handle: "@Victor_codejs",
    testimonial: "This is a gold mine, thank you Nilanjan",
    image: nwokeOma,
    socialIcon: twitterLogo,
    link: "https://twitter.com/Victor_codejs/status/1684195480077492225?s=20",
  },
  {
    name: "Abel Emmanuel",
    handle: "@abellmanuell",
    testimonial:
      "Hip Hurray! 🎉🚀 I made my first contribution to an Open-Source project. Contributing to open-source is not rocket science. Acheived this with the help of @tublian. I'm happy with this new development, and I'll love to contribute more to #opensource projects.",
    image: abelEmmanuel,
    socialIcon: twitterLogo,
    link: "https://twitter.com/abellmanuell/status/1675879796725669889",
  },

  {
    name: "Victor Okeke",
    handle: "@Vicscot1",
    testimonial:
      "I just signed up on https://tublian.com and I am so swelled to indulge and get better. @hecantalk, I could see your feeds/contribution on stack overflow. I am definitely following suit. Thanks @nraychaudhuri for the exposure and for what you are doing at tublian.",
    image: victorOkeke,
    socialIcon: twitterLogo,
    link: "https://twitter.com/Vicscot1/status/1562895726551543813?s=20",
  },
];

export const sliderSettings = {
  infinite: true,
  slidesToShow: 3.5,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 5000,
  pauseOnHover: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3.3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2.8,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 799,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1.09,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
      },
    },
  ],
};

interface TestimonialCardProps {
  name: string;
  handle: string;
  testimonial: string;
  image: string;
  link?: string;
  socialIcon: string;
}

export const TestimonialSliderTemplate = ({
  testimonial,
  newLayout,
}: {
  testimonial: TestimonialCardProps;
  newLayout: boolean;
}) => {
  const classes = useStyles();
  return (
    <a
      href={testimonial.link}
      target="blank"
      style={{ textDecoration: "none" }}
    >
      <div className={classes.testimonialCard}>
        <p>{testimonial.testimonial}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "28px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: "12px" }}>
              <img src={testimonial.image} alt="" className={classes.image} />
            </div>
            <div className={classes.info}>
              <p>{testimonial.name}</p>
              <p className="handle">{testimonial.handle}</p>
            </div>
          </div>

          <div>
            <img src={testimonial.socialIcon} alt="" />
          </div>
        </div>
      </div>
    </a>
  );
};
