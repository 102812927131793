import React from "react";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";

export default function Menu({
  open,
  anchorRef,
  onClose,
  onListKeyDown,
  children,
}: IProps) {
  return (
    <Popper
      sx={{
        zIndex: 3,
      }}
      open={open}
      anchorEl={anchorRef.current}
      transition
      disablePortal
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 19],
          },
        },
      ]}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper
            sx={{ background: "#272727", borderRadius: "10px", width: "200px" }}
          >
            <ClickAwayListener onClickAway={onClose}>
              <MenuList autoFocusItem={open} onKeyDown={onListKeyDown}>
                {children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

interface IProps {
  open: boolean;
  anchorRef: React.RefObject<HTMLElement>;
  // onClose: React.MouseEventHandler<EventTarget>;
  onClose: any;
  onListKeyDown: React.KeyboardEventHandler;
  children?: React.ReactNode;
}
