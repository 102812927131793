import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  styled,
} from "@mui/material";
import { CheckCircleOutlined, Close } from "@mui/icons-material";
import { useLearnContext } from "./LearnContext";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { logEvent } from "../../../libs/amplitude";
import { userProfile } from "../../../libs/UserProfile";
import {
  ActiveSectionContentType,
  CoursePresentationPageType,
} from "../Common/LearningTypes";
import { CourseSidebarSkeleton } from "../Skeletons/Skeletons";
import DetailedCustomTooltip from "../../Common/DetailedCustomTooltip";

// eslint-disable-next-line
const MoreCourseDetails = ({
  objectives,
  sectionProgress,
  closeHandler,
}: {
  objectives: string[];
  sectionProgress: ActiveSectionContentType | undefined;
  closeHandler: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}) => {
  const location = useLocation();
  const history = useHistory();
  const userCache = userProfile(localStorage);
  const { roadmapId, courseId } = useParams<CoursePresentationPageType>();

  const {
    activeCourse,
    activeSectionId,
    learningProgress,
    setActiveSectionId,
  } = useLearnContext();

  const courseStructure = activeCourse.structure;
  // const completedTasks = sectionProgress?.completedTasks;
  // const openTasks = sectionProgress?.openTasks;

  const setSection = (section: number) => {
    const currentUrl = location.pathname;
    const newUrl = `${currentUrl}?section=${section}`;
    history.replace(newUrl);
    logEvent("8020", userCache.userId(), {
      action: "openSection",
      roadmapId: roadmapId,
      phaseId: courseId,
      sectionId: section,
    });
    setActiveSectionId(section);
  };

  return (
    <StyledWrap>
      <>
        <HeaderWrap>
          <StyledHeader>Sections</StyledHeader>
          <Close className="closeIcon" onClick={closeHandler} />
        </HeaderWrap>
        <CourseStructureList>
          {courseStructure?.map((c, i) => (
            <CourseStructureListItem
              sx={{ background: i + 1 === activeSectionId ? "#292929" : "" }}
              onClick={() => setSection(i + 1)}
              key={i}
            >
              {learningProgress?.progress[i + 1]?.status && (
                <ListItemIcon sx={{ minWidth: "unset" }}>
                  <CheckCircleOutlined
                    sx={{ minWidth: "unset !important" }}
                    htmlColor="#1AFF8C"
                  />
                </ListItemIcon>
              )}
              {c}
            </CourseStructureListItem>
          ))}
        </CourseStructureList>

        {(!courseStructure || courseStructure.length === 0) && (
          <CourseSidebarSkeleton />
        )}

        {/* {openTasks && openTasks.length > 0 && (
          <>
            <SubtitleText>
              Here are the open tasks (pull requests).
            </SubtitleText>
            <TaskList>
              {openTasks?.map(({ url, title }) => (
                <li>
                  <a href={url} target="_blank" rel="noreferrer">
                    {title}
                  </a>
                </li>
              ))}
            </TaskList>
          </>
        )} */}
      </>
      <StyledDivider />

      <DetailedCustomTooltip
        title={
          <Box>
            <Typography component="h4">Estimated Streetcred</Typography>
            <Typography>
              On successful completion of this phase, you will be rewarded with
              up to 2 StreetCred points.
            </Typography>
          </Box>
        }
      >
        <StreetcredText sx={{ cursor: "help" }}>
          Est. Score: <span>2.00</span>
        </StreetcredText>
      </DetailedCustomTooltip>
      <StyledDivider />
      <SubtitleText>
        <a
          href="https://discord.gg/S6KGq3NR7F"
          target="_blank"
          rel="noreferrer"
        >
          Meet other learners
        </a>
      </SubtitleText>
    </StyledWrap>
  );
};

export default MoreCourseDetails;

const StyledWrap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 14,
});

const StyledHeader = styled(Typography)(({ theme }) => ({
  color: "#FEFEFE",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "1.5rem",
}));

const HeaderWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .closeIcon": {
    display: "none",
  },
  [theme.breakpoints.down(768)]: {
    "& .closeIcon": {
      display: "block",
      color: "#FEFEFE",
      fontSize: "18px",
    },
  },
}));

const SubtitleText = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "1.5rem",
  fontWeight: 500,
  color: "#B7B7B7",
  [theme.breakpoints.down(700)]: {
    fontSize: "1rem",
  },
}));

// const TaskList = styled("ol")(({ theme }) => ({
//   fontSize: "0.875rem",
//   lineHeight: "1.5rem",
//   fontWeight: 500,
//   color: "#B7B7B7",
//   "& li": {
//     marginLeft: 10,
//     marginBottom: 5,
//   },
//   [theme.breakpoints.down(700)]: {
//     fontSize: "1rem",
//   },
// }));

const CourseStructureList = styled(List)({
  display: "flex",
  flexDirection: "column",
  marginTop: "-4px",
  padding: 0,
  gap: "4px",
  marginLeft: "-8px",
  listStyleType: "none",
});

const CourseStructureListItem = styled(ListItem)({
  display: "flex",
  gap: "6px",
  alignItems: "center",
  padding: "8px 12px",
  color: "#FEFEFE",
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: "1.25rem",
  cursor: "pointer",
  borderRadius: "10px",
  "&:hover": {
    background: "#292929",
    borderRadius: "10px",
  },
});
const StyledDivider = styled(Divider)({
  backgroundColor: "#292929",
});

const StreetcredText = styled(Box)({
  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: "1.25rem",
  color: "#888",
  "& span": {
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: "1.5rem",
    color: "#FEFEFE",
    marginLeft: "12px",
  },
});
