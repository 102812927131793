import { Box, styled } from "@mui/material";

const spinKeyframes = {
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

const Spinner = ({
  size = 30,
  pryColor = "#FDD649",
  secColor = "#171717",
}: {
  size?: number;
  pryColor?: string;
  secColor?: string;
}) => {
  return (
    <StyledSpinner
      size={size}
      primarycolor={pryColor}
      secondarycolor={secColor}
      sx={spinKeyframes}
    />
  );
};

export default Spinner;

const StyledSpinner = styled(Box)<{
  size: number;
  primarycolor: string;
  secondarycolor: string;
}>(({ theme, size, primarycolor, secondarycolor }) => ({
  border: `5px solid ${secondarycolor}`,
  borderTop: `5px solid ${primarycolor}`,
  borderRadius: "50%",
  width: `${size}px`,
  height: `${size}px`,
  animation: "spin  0.5s linear infinite",
}));
