import { Box } from "@mui/material";
import ChallengePresentationCard from "./ChallengePresentationCard";
import { previousChallenges } from "./ChallengePresentationData";
import ShowMoreButton from "../../UserProfile/Common/ShowMoreButton";
import { useState } from "react";

const PreviousChallenges = () => {
  const [limit, setLimit] = useState(3);

  const showMore = () => {
    setLimit(limit + 2);
  };

  const showLess = () => {
    setLimit(3);
  };

  const isShowingAll = limit >= previousChallenges.length;

  return (
    <>
      {previousChallenges?.slice(0, limit)?.map((challenge, index) => (
        <div
          key={index}
          style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <ChallengePresentationCard
            chalName={challenge.chalName}
            description={challenge.description}
            endDate={challenge.endDate}
            id={challenge.id}
            imageUrl={challenge.imageUrl}
          />
        </div>
      ))}

      {previousChallenges?.length > limit && (
        <Box style={{ marginBottom: "20px" }}>
          <ShowMoreButton
            showMore={showMore}
            showLess={showLess}
            isShowingAll={isShowingAll}
          />
        </Box>
      )}
    </>
  );
};

export default PreviousChallenges;
