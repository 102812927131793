import { Box, Typography, styled } from "@mui/material";
import "./style.css";
import _8020 from "../../../images/learning/80-20.png";
import { FC } from "react";

const LoadingText = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "1.5rem",
  color: "#B7B7B7",
  textAlign: "center",
  marginTop: "10px",
});

const SecLoadingText = styled(Typography)({
  fontSize: "0.75rem",
  fontWeight: 400,
  lineHeight: "1rem",
  color: "#CFCFCF",
  textAlign: "center",
  // marginTop: "10px",
});

const CustomLoader: FC<ICustomLoader> = ({ primaryMsg, secondaryMsg }) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box className="customLoaderWrap">
        <Box className="face">
          <Box className="circle"></Box> {/* outer dot */}
        </Box>

        <Box className="face">
          <Box className="circle"></Box> {/* inner dot */}
        </Box>

        <img
          style={{
            position: "absolute",
            left: "calc(50% - 54.25px)",
            top: "calc(50% - 37.5px)",
          }}
          width="108.5px"
          height="75px"
          src={_8020}
          alt="loader"
        />
      </Box>
      {primaryMsg && <LoadingText>{primaryMsg}</LoadingText>}
      {secondaryMsg && <SecLoadingText>{secondaryMsg}</SecLoadingText>}
    </Box>
  );
};

export default CustomLoader;

type ICustomLoader = { primaryMsg?: string; secondaryMsg?: string };
