import { ICustomButtonProps } from "./CustomBtnResources";
import AuthButton from "./AuthButton";
import CompanyButton from "./CompanyBtn";
import NeutralButton from "./NeutralBtn";
import PrimaryButton from "./PrimaryBtn";
import TertiaryButton from "./TertiaryBtn";
import SecondaryButton from "./SecondaryBtn";
import GreyButton from "./GreyButton";

const CustomButton = ({ type, ...props }: ICustomButtonProps) => {
  switch (type) {
    case "primary":
      return <PrimaryButton {...props} />;
    case "secondary":
      return <SecondaryButton {...props} />;
    case "tertiary":
      return <TertiaryButton {...props} />;
    case "neutral":
      return <NeutralButton {...props} />;
    case "company":
      return <CompanyButton {...props} />;
    case "auth":
      return <AuthButton {...props} />;
    case "grey":
      return <GreyButton {...props} />;
    default:
      return <PrimaryButton {...props} />;
  }
};

export default CustomButton;
