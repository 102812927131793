import { FC } from "react";
import { IOnClose } from "./tubliChatBotResources";
import { Box, Paper, Typography } from "@mui/material";
import TublibotAvatar from "./TublibotAvatar";
import { Close } from "@mui/icons-material";
import useTubliChatBotStyles from "./tubliChatBotStyles";
import { useChatBotContext } from "../../Context/ChatBotContext";

const ChatIdlePopup: FC<IOnClose> = ({ onClose }) => {
  const classes = useTubliChatBotStyles();

  const { setChatBotData } = useChatBotContext();

  const handlePaperClick = () =>
    setChatBotData((prevData) => ({ ...prevData, chatBotView: "chat view" }));

  return (
    <Paper
      className={`${classes.chatWindowPaper} ${classes.chatIdleWindowPaper}`}
      onClick={handlePaperClick}
    >
      <Box className="chatHeader" style={{ padding: 0 }}>
        <TublibotAvatar size={28} />
        <Close htmlColor="#FDD649" onClick={onClose} />
      </Box>
      <Typography>Got any questions? I would be happy to help.</Typography>
    </Paper>
  );
};

export default ChatIdlePopup;
