import { useEffect, useState } from "react";
import { styled } from "@mui/material";
import { userProfile } from "../../libs/UserProfile";
import useAuthProvider from "./Authorization/auth-provider-hook";
import ProfileEndpoint from "../../libs/ProfileEndpoint";
import Loading from "../Common/Loading";
import { logEvent } from "../../libs/amplitude";

const DiscordAuthWrapper = styled("div")({
  position: "fixed",
  height: "100%",
  width: "100%",
  backgroundColor: "#121212",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const ReAuthentication = () => {
  const [reAuthenticationCompleted, setReAuthenticationCompleted] =
    useState(false);

  // Retrieving user profile and authentication provider
  const user = userProfile(localStorage);
  const authProvider = useAuthProvider();

  // Function to handle the re-authentication process
  const run = async () => {
    const tokenResponse = await authProvider?.getAccessToken();
    authProvider?.setUserInfo(tokenResponse);

    await ProfileEndpoint.updateGithubToken();
    // console.log(tokenResponse);

    setReAuthenticationCompleted(true);
    logEvent("Authorized Codespace", user.userId());

    user.setGithubScopes(tokenResponse?.scope);

    if (window.opener?.location?.href?.startsWith(window.location.origin)) {
      window.opener?.postMessage("success", "*");
      window.close();
    }
  };

  useEffect(() => {
    if (!authProvider) return;
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authProvider]);

  return (
    <DiscordAuthWrapper>
      {reAuthenticationCompleted && <Loading />}
    </DiscordAuthWrapper>
  );
};

export default ReAuthentication;
