import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";

import CustomButton from "../../Common/CustomButton";
import { useLearnContext } from "./LearnContext";
import { Course } from "../Common/LearningTypes";
import ProgressBar from "../../Common/progressBar";
import { logEvent } from "../../../libs/amplitude";
import { userProfile } from "../../../libs/UserProfile";
import helperFunctions from "../../../libs/helperFunctions";

import defaultImg from "../../../images/learning/80-20.png";

const CoursePresentationCard: FC<CoursePresentationCardProps> = ({
  course,
  sectionNumber = 1,
  completedCnt,
  sectionsCnt,
  btnText,
  imageUrl,
  viewOnly = false,
}) => {
  const history = useHistory();
  const { courseName, description, id, phaseRoadmapId } = course;
  const { setActiveCourse } = useLearnContext();
  const userCache = userProfile(localStorage);
  const [imageLoaded, setImageLoaded] = useState(false);

  const goToCourse = () => {
    setActiveCourse(course);
    logEvent("8020", userCache.userId(), {
      action: "openPhase",
      roadmapId: phaseRoadmapId,
      phaseId: id,
      sectionId: sectionNumber,
    });
    history.push(
      `/learn/${userCache.userId()}/${phaseRoadmapId}/${id}?section=${sectionNumber}`
    );
  };

  return (
    <CourseCardWrapper>
      <CourseCardColOne>
        <Box>
          <CardHeaderWrap>
            <StackWrap>
              {course?.stacks?.slice(0, 2).map((s, i) => (
                <StackTag key={i}>{s}</StackTag>
              ))}
            </StackWrap>
          </CardHeaderWrap>

          <CourseCardHeader onClick={goToCourse}>{courseName}</CourseCardHeader>
          <CourseCardDescription linelimit={completedCnt > 0 ? 2 : 3}>
            {description}
          </CourseCardDescription>
          {completedCnt > 0 && (
            <Box width="152px">
              <ProgressBar
                max={sectionsCnt}
                current={completedCnt}
                startText={`${completedCnt} of ${sectionsCnt} sections completed`}
                startTextColor="#FEFEFE"
                height={2}
                borderRadius={2}
                margin={2}
                progressColor="linear-gradient(
                  to right,
                  #fbda61 0%,
                  rgba(255, 90, 205, 0.84) 100%
                )"
                textRowStyles={{
                  color: "#FEFEFE",
                  "& p": {
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    lineHeight: "1rem",
                  },
                }}
              />
            </Box>
          )}
        </Box>
        <ActionRow>
          <CustomButton
            disabled={viewOnly}
            type="grey"
            size="medium"
            onClick={goToCourse}
          >
            {btnText}
          </CustomButton>
        </ActionRow>
      </CourseCardColOne>

      <CourseCardColTwo>
        <CourseCardColImage
          loaded={imageLoaded}
          src={imageUrl || defaultImg}
          onLoad={() => setImageLoaded(true)}
          onError={(e) => {
            setImageLoaded(true);
            helperFunctions.setImagePlaceholder(e, defaultImg);
          }}
          alt="8020"
        />
        {!imageLoaded && <PlaceholderImage src={defaultImg} alt="Loading..." />}
      </CourseCardColTwo>
    </CourseCardWrapper>
  );
};

export default CoursePresentationCard;

type CoursePresentationCardProps = {
  course: Course;
  sectionNumber?: 1 | 2 | 3 | 4;
  completedCnt: number;
  sectionsCnt: number;
  btnText: string;
  imageUrl?: any;
  viewOnly?: boolean;
};

const CourseCardWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#1E1E1E",
  display: "flex",
  borderRadius: 20,
  overflow: "hidden",
  justifyContent: "space-between",
  [theme.breakpoints.down(768)]: {
    flexDirection: "column-reverse",
    height: "auto",
  },
}));

const CourseCardColOne = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  flex: 1,
  justifyContent: "space-between",
});

const CourseCardColTwo = styled(Box)(({ theme }) => ({
  backgroundColor: "#1E1E1E",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
  flexWrap: "wrap",
  width: "188px",
  margin: "30px 20px 29px 0",
  height: "calc(100% - 59px)",
  borderRadius: "15px",
  [theme.breakpoints.down(768)]: {
    padding: "10px 16px 0",
    margin: "unset",
    height: "195px",
    width: "auto",
    display: "flex",
    justifyContent: "flex-start",
  },
  [theme.breakpoints.down(480)]: {
    justifyContent: "center",
  },
}));

const CourseCardHeader = styled(Typography)(({ theme }) => ({
  fontSize: "1.125rem",
  fontWeight: 700,
  lineHeight: "1.875rem",
  color: "#FEFEFE",
  marginBottom: "8px",
  cursor: "pointer",
  [theme.breakpoints.down(520)]: {
    fontSize: "1.25rem",
  },
}));
const CourseCardDescription = styled(Typography)<{ linelimit: number }>(
  ({ theme, linelimit = 3 }) => ({
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
    color: "#CFCFCF",
    marginBottom: linelimit === 3 ? 0 : 12,
    // width: "373px",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitLineClamp: linelimit,
    WebkitBoxOrient: "vertical",
    overflowWrap: "break-word",
    overflow: "hidden",
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
    [theme.breakpoints.down(520)]: {
      width: "318px",
      fontSize: "1rem",
    },

    [theme.breakpoints.down(390)]: {
      width: "100%",
    },
  })
);

const ActionRow = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 12,
  marginTop: "20px",
  alignItems: "center",
}));

const CourseCardColImage = styled("img")<{ loaded: boolean }>(
  ({ theme, loaded }) => ({
    maxHeight: "100%",
    maxWidth: "100%",
    borderRadius: "15px",
    display: loaded ? "block" : "none",
    [theme.breakpoints.down(768)]: {},
  })
);

const PlaceholderImage = styled("img")({
  maxHeight: "100%",
  maxWidth: "100%",
});

const CardHeaderWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  alignItems: "flex-start",
  marginBottom: "14px",
  [theme.breakpoints.down(768)]: {
    gap: "8px",
  },
}));

const StackWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",
}));

const StackTag = styled(Box)(({ theme }) => ({
  color: "#FEFEFE",
  padding: "4px 8px",
  borderRadius: "20px",
  border: "1px solid #292929",
  background: "#1E1E1E",
  fontSize: "0.75rem",
  fontWeight: 500,
  lineHeight: "1rem",
}));
