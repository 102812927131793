import { useState, useEffect } from "react";
import { Box, styled } from "@mui/material";
import CustomButton from "../../Common/CustomButton";
import challengeIllustration from "../../../images/challenges/challengeIllutration.png";
import { useHistory } from "react-router-dom";
import ProfileEndpoint from "../../../libs/ProfileEndpoint";

const ChallengePresentationCard: React.FC<ChallengePresentationCardProps> = ({
  chalName,
  imageUrl,
  description,
  endDate,
  id,
}) => {
  const [status, setStatus] = useState("");
  const history = useHistory();

  const goToChallenge = () => {
    history.push(`/challenge/${id}`);
  };

  useEffect(() => {
    let isMounted = true;

    const challengeStatus = async () => {
      const response = await ProfileEndpoint.getChallengeStatus(id);
      if (isMounted) setStatus(response.status);
    };

    challengeStatus();

    // cleanup function
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ChallengeCardWrapper>
      <ChallengeCardColOne>
        <ChallengeCardHeader>{chalName}</ChallengeCardHeader>
        <ChallengeCardDescription>{description}</ChallengeCardDescription>
        <ChallengeCardDate>
          End: <ChallengeCardDateBold>{endDate}</ChallengeCardDateBold>
        </ChallengeCardDate>
        <ActionRow>
          <CustomButton type="primary" size="small" onClick={goToChallenge}>
            View Challenge
          </CustomButton>
          {status === "started" && (
            <Box
              style={{
                display: "flex",
                gap: "8px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StatusIndicator />
              <Status>Started</Status>
            </Box>
          )}
          {status === "submitted" && (
            <Box
              style={{
                display: "flex",
                gap: "8px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StatusIndicator />
              <Status>Completed</Status>
            </Box>
          )}
        </ActionRow>
      </ChallengeCardColOne>
      <ChallengeCardColTwo imageUrl={imageUrl}>
        <img src={imageUrl || challengeIllustration} alt="" />
      </ChallengeCardColTwo>
    </ChallengeCardWrapper>
  );
};

export default ChallengePresentationCard;

type ChallengePresentationCardProps = {
  chalName: string;
  description: string;
  endDate: string;
  id: string;
  imageUrl?: string;
};

const ChallengeCardWrapper = styled(Box)(({ theme }) => ({
  width: "627px",
  backgroundColor: "#1E1E1E",
  display: "flex",
  borderRadius: 20,
  overflow: "hidden",
  justifyContent: "space-between",
  [theme.breakpoints.down(768)]: {
    flexDirection: "column-reverse",
    width: "100%",
    maxWidth: "380px",
  },
}));

const ChallengeCardColOne = styled(Box)(({ theme }) => ({
  padding: "20px",
}));

const ChallengeCardHeader = styled("p")(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: "24px",
  color: "#fefefe",
  marginBottom: 8,
  [theme.breakpoints.down(768)]: {
    fontSize: 20,
  },
}));
const ChallengeCardDescription = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: "#B7B7B7",
  marginBottom: 12,
  width: "343px",
  [theme.breakpoints.down(768)]: {
    width: "100%",
    fontSize: 16,
  },
  // [theme.breakpoints.down(520)]: {
  //   width: "318px",
  //   fontSize: 16,
  // },
  [theme.breakpoints.down(390)]: {
    width: "100%",
  },
}));

const ChallengeCardDate = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "20px",
  color: "#888",
  marginBottom: 20,
}));
const ChallengeCardDateBold = styled("span")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "20px",
  color: "#fefefe",
}));

const ActionRow = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 12,
  alignItems: "center",
}));

const StatusIndicator = styled("div")({
  height: 10,
  width: 10,
  borderRadius: "50%",
  backgroundColor: "#4DED3B",
});

const Status = styled("p")({
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "20px",
  color: "#fefefe",
});

const ChallengeCardColTwo = styled(Box)<{ imageUrl?: string | undefined }>(
  ({ theme, imageUrl }) => ({
    backgroundColor: "#1E1E1E",
    display: "flex",
    justifyContent: imageUrl ? "center" : "",
    alignItems: imageUrl ? "center" : "",
    width: imageUrl ? "100%" : "",
    padding: imageUrl ? "20px" : "10px",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down(768)]: {
      padding: "10px 16px",
      "& img": {
        width: "100%",
      },
    },
  })
);
