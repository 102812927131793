import { Box, Typography } from "@mui/material";
import TublibotAvatar from "./TublibotAvatar";
import useTubliChatBotStyles from "./tubliChatBotStyles";
import {
  handleSendMsg,
  suggestedQueries,
  welcomeMsgs,
} from "./tubliChatBotResources";
import { useChatBotContext } from "../../Context/ChatBotContext";

const ChatSuggestions = () => {
  const classes = useTubliChatBotStyles();

  const { setChatBotData } = useChatBotContext();

  const handleClickSuggestion = (chatBotMessage: string) => {
    const inputError = {
      error: false,
      errMsg: "",
    };
    setChatBotData((prevData) => {
      const updatedData = {
        ...prevData,
        inputError,
        chatBotMessage,
      };

      handleSendMsg(updatedData, setChatBotData);
      return updatedData;
    });
  };

  return (
    <Box className={classes.chatSuggestions}>
      <TublibotAvatar />
      <Box>
        <Box className="welcomeMsgs">
          {welcomeMsgs?.map((m, i) => (
            <Box key={i} className={classes.incomingMessage}>
              <Typography>{m}</Typography>
            </Box>
          ))}
        </Box>

        <Box className="suggestedQueriesWrap">
          {suggestedQueries?.map((s, i) => (
            <Box
              key={i}
              className="suggestedQueries"
              onClick={() => handleClickSuggestion(s)}
            >
              <Typography>{s}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatSuggestions;
