import { FC } from "react";
import { Box, Typography, styled } from "@mui/material";
import CustomButton from "../../../Common/CustomButton";

import success from "../../../../images/onboarding/successframe.png";
import errorImg from "../../../../images/onboarding/errorframe.png";
import logo from "../../../../images/landing/latest_logo.svg";

const EightyTwentyPaymentStatus: FC<EightyTwentyPaymentStatusProps> = ({
  status = "success",
  message,
  handleAction,
}) => {
  let msg;

  if (status === "error") {
    msg = message || "We are unable to complete your payment process.";
  } else {
    msg = message || "Your payment went through successfully. ";
  }

  return (
    <StatusWrap>
      <Status>
        <img src={status === "success" ? success : errorImg} alt="status" />
      </Status>
      <Box sx={{ padding: "0 20px" }}>
        <HeaderText>
          {status === "success" ? "Payment Successful" : "Payment Failed"}
        </HeaderText>
        <SubHeader>{msg}</SubHeader>
      </Box>
      <BtnWrap>
        <CustomButton
          type={status === "success" ? "primary" : "secondary"}
          size="large"
          onClick={handleAction}
        >
          {status === "success" ? "Proceed" : "Try again"}
        </CustomButton>
      </BtnWrap>
      <LogoWrap>
        <img src={logo} alt="Tublian logo" />
      </LogoWrap>
    </StatusWrap>
  );
};

export default EightyTwentyPaymentStatus;

interface EightyTwentyPaymentStatusProps {
  status?: "success" | "error";
  message?: string;
  handleAction: () => void;
}

const StatusWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  width: "580px",
  paddingBottom: "20px",
  borderRadius: "20px",
  border: "1px solid #292929",
  background: "#1E1E1E",
  [theme.breakpoints.down(1024)]: {
    width: "548px",
  },
  [theme.breakpoints.down(700)]: {
    width: "358px",
    // height: "395px",
  },
  [theme.breakpoints.down(390)]: {
    width: "calc(100vw - 16px)",
  },
}));

const HeaderText = styled(Typography)({
  fontSize: "1.875rem",
  fontWeight: 700,
  lineHeight: "2.125rem",
  color: "#FEFEFE",
  textAlign: "center",
});

const SubHeader = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "1.5rem",
  color: "#CFCFCF",
  textAlign: "center",
  margin: "18px 0 28px",
});

const BtnWrap = styled(Box)({
  padding: "0 20px",
  width: "100%",
  "& button": {
    width: "100% !important",
  },
});

const LogoWrap = styled(Box)({
  position: "absolute",
  top: "20px",
  left: "40px",
  "& img": {
    width: "111px",
  },
});

const Status = styled(Box)({
  "& img": {
    width: "100%",
  },
});
