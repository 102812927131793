import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, styled } from "@mui/material";
import Footer from "../Footer";
// import osHeroMask from "../../../images/landing/open-source-bg.svg";
import osHeroMask from "../../../images/landing/OsPage/os-background.png";
import { logEvent } from "../../../libs/amplitude";
import GithubClient from "../../../libs/GithubClient";
import queryString from "query-string";
import TublianOsHero from "./TublianOsHero";
import TransformYourSoftware from "./TransformYourSoftware";
import TublianOsVideo from "./TublianOsVideo";
import NewHeader from "../NewHeader";
import HowItWorksSection from "./HowItWorksSection";

const TublianOs = () => {
  const [solidNav, setSolidNav] = useState(false);
  const { search } = useLocation();
  const { code } = queryString.parse(search);
  const signInCode = code || "afec85996583354e1ef840ab9aaf4c5575cde7f2";

  const handleGithubSignIn = () => {
    logEvent("TublianOS_SignUp", "public-view");
    GithubClient.signIn(signInCode);
    return false;
  };

  useEffect(() => {
    logEvent("TublianOS_LandingPage", "public-view");
  }, [signInCode]);

  useEffect(() => {
    const changeNavToSolid = () => {
      if (window.scrollY >= 64) {
        setSolidNav(true);
      } else {
        setSolidNav(false);
      }
    };

    window.addEventListener("scroll", changeNavToSolid);
    return () => {
      window.removeEventListener("scroll", changeNavToSolid);
    };
  }, []);

  return (
    <Root>
      <TopWrap>
        <Box className="bgMask" />
        <TopSection>
          <HeaderContainer solidNav={solidNav}>
            <NewHeader pageName="tublianOS" />
          </HeaderContainer>

          <HeroWrap>
            <TublianOsHero handleGithubSignIn={handleGithubSignIn} />
          </HeroWrap>
        </TopSection>
      </TopWrap>

      <Container>
        <TublianOsVideo />
        <HowWrap>
          <HowItWorksSection />
        </HowWrap>
      </Container>

      <ContactWrap>
        <TransformYourSoftware handleGithubSignIn={handleGithubSignIn} />
      </ContactWrap>
      <Footer />
    </Root>
  );
};

export default TublianOs;

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: "#111",
  position: "relative",
  overflow: "hidden",
  width: "100%",
  minHeight: "100vh",
  color: "#FEFEFE",
  zIndex: 0,
}));

const HeaderContainer = styled(Box)<{ solidNav: boolean }>(({ solidNav }) => ({
  position: "fixed",
  width: "100%",
  top: 0,
  background: solidNav ? "rgba(17, 17, 17, 0.05)" : "none",
  backdropFilter: solidNav ? "blur(20px) !important" : "blur(0px) !important",
  zIndex: 1000,
}));

const Container = styled(Box)(({ theme }) => ({
  maxWidth: 1400,
  position: "relative",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0 64px",
  [theme.breakpoints.down(768)]: {
    width: "100%",
    padding: "0 16px",
  },
}));

const HowWrap = styled(Box)(({ theme }) => ({
  margin: "100px 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down(768)]: {
    margin: "100px 0 80px",
    width: "100%",
  },
}));

const TopWrap = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: 630,
  height: "fit-content",
  position: "relative",
  "& .bgMask": {
    backgroundImage: `url(${osHeroMask})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "absolute",
    backgroundSize: "cover",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
  [theme.breakpoints.down(768)]: {
    minHeight: "auto",
  },
}));

const TopSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "100%",
  minHeight: 630,
  height: "100vh",
  maxWidth: 1400,
  margin: "auto",
  [theme.breakpoints.down(768)]: {
    height: "auto",
    minHeight: "auto",
  },
}));

const HeroWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  padding: "0 64px",
  [theme.breakpoints.down(768)]: {
    height: "auto",
    width: "100%",
    padding: 0,
    margin: "124px 0 100px",
  },
}));

const ContactWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  padding: "0 64px",
  marginBottom: "63px",
  [theme.breakpoints.down(768)]: {
    height: "auto",
    width: "100%",
    padding: 0,
    // marginBottom: "182px",
    marginBottom: "60px",
  },
}));
