import { Button, styled } from "@mui/material";
import { ICustomButtonProps, getSizeStyles } from "./CustomBtnResources";

const AuthButton = ({
  disabled,
  children,
  onClick,
  style,
  nativeType,
  loading = false,
  loadingText = "Loading...",
  preImg,
  postImg,
}: ICustomButtonProps) => {
  const AuthButtonWrap = styled(Button)`
    && {
      text-transform: none;
      width: 342px;
      font-weight: 500;
      display: flex;
      align-items: center;
      color: #fefefe;
      display: flex;
      align-items: center;
      border-radius: 72px;
      background: linear-gradient(#121212, #121212) padding-box,
        linear-gradient(109.15deg, #fbda61 0%, rgba(255, 90, 205, 0.84) 86.08%)
          border-box;
      padding: 36px 0;
      border: 1px solid transparent;
      padding: ${getSizeStyles("xl", "github").padding};
      font-size: ${getSizeStyles("xl", "github").fontSize};
      line-height: ${getSizeStyles("xl", "github").lineHeight};
      &:hover {
        & svg {
          color: #062441;
        }
        background: linear-gradient(
          109.15deg,
          #fbda61 0%,
          rgba(255, 90, 205, 0.84) 86.08%
        );
        border: none;
        color: #121212;
      }
      @media (max-width: 700px) {
        width: 358px;
      }
      @media (max-width: 390px) {
        width: 100%;
      }
      .preImg {
        display: flex;
        margin-right: 8px;
      }
      .ghPreImg {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        color: #137de7;
      }
      .postImg {
        display: flex;
        margin-left: 8px;
      }
    }
  `;

  return (
    <AuthButtonWrap
      disabled={disabled}
      type={nativeType ? nativeType : undefined}
      onClick={onClick}
      style={style}
    >
      {!loading && preImg && typeof preImg === "string" ? (
        <img
          src={preImg}
          alt="preImg"
          style={{ display: "flex", marginRight: 8 }}
          width="24px"
          height="24px"
        />
      ) : (
        !loading &&
        preImg && (
          <div style={{ display: "flex", marginRight: 8 }}>{preImg}</div>
        )
      )}

      {!loading ? children : loadingText}

      {!loading && postImg && typeof postImg === "string" ? (
        <img
          src={postImg}
          style={{ display: "flex", marginLeft: 8 }}
          alt="postImg"
          width="24px"
          height="24px"
        />
      ) : (
        !loading &&
        postImg && (
          <div style={{ display: "flex", marginLeft: 8 }}>{postImg}</div>
        )
      )}
    </AuthButtonWrap>
  );
};

export default AuthButton;
