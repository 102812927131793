const helperFunctions: IHelperFunctions = {
  setImagePlaceholder(
    event: React.SyntheticEvent<HTMLImageElement, Event>,
    defaultImgUrl: string
  ) {
    const target = event.target as HTMLImageElement;
    target.src = defaultImgUrl;
  },
  reencodeRoadmapId(roadmapId: string | undefined, pathwayType = "course") {
    if (roadmapId && pathwayType === "course") {
      const replacements = {
        "’": "%E2%80%99",
        '"': "%22",
        "'": "%27",
        "(": "%28",
        ")": "%29",
        "[": "%5B",
        "]": "%5D",
        "|": "%7C",
      };

      roadmapId = Object.entries(replacements).reduce(
        (acc, [oldChar, newChar]) => {
          return acc.split(oldChar).join(newChar);
        },
        roadmapId
      ); // Replacing certain characters

      return roadmapId;
    }
    return undefined;
  },
};

export default helperFunctions;

interface IHelperFunctions {
  setImagePlaceholder: (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
    defaultImgUrl: string
  ) => void;
  reencodeRoadmapId: (
    roadmapId: string | undefined,
    pathwayType?: "course" | "internship"
  ) => string | undefined;
  //Add more here
}
