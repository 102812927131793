import { Box, styled } from "@mui/material";

export default function OverlayModal({ children, isPreviewOpen }) {
  return (
    <Box>
      {isPreviewOpen && (
        <StyledModalOverlay>
          <Box>{children}</Box>
        </StyledModalOverlay>
      )}
    </Box>
  );
}

const StyledModalOverlay = styled(Box)({
  fontStyle: "normal",
  position: "fixed",
  boxSizing: "border-box",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  background: "rgba(18, 18, 18, 0.5)",
  WebkitBackdropFilter: "blur(20px) !important",
  backdropFilter: "blur(20px) !important",
  zIndex: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflowY: "scroll",
});
