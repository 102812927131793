import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import OnboardingHeader from "../Onboarding/OnboardingHeader";
import { styled } from "@mui/system";

const StyledMain = styled("main")({
  position: "fixed",
  height: "calc(100% - 63px)",
  width: "100%",
  backgroundColor: "#121212",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const StyledRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  width: "100%",
});

const StyledColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexBasis: "100%",
  flex: "1",
  justifyContent: "center",
  alignItems: "center",
});

const StyledTypography = styled(Typography)({
  marginTop: "10px",
  marginBottom: "10px",
  color: "#F59C0B",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: 1.875,
});

export default function Loading({ msg }: { msg?: string }) {
  return (
    <>
      <OnboardingHeader />
      <StyledMain>
        <CircularProgress color="secondary" size={120} />
        {msg && (
          <StyledRow>
            <StyledColumn>
              <StyledTypography variant="h2">{msg}</StyledTypography>
            </StyledColumn>
          </StyledRow>
        )}
      </StyledMain>
    </>
  );
}
