import { Box, Typography, styled } from "@mui/material";
import CustomButton from "../../Common/CustomButton";

const WarningPopup = ({
  handleClick,
  setAlertActive,
}: {
  handleClick: any;
  setAlertActive: any;
}) => {
  return (
    <WarningPopupWarp>
      <StyledHeader>
        Are you sure you want to customize your content?
      </StyledHeader>
      <StyledDescription>
        This action is irreversible and will replace your content,
        including any content you have marked as complete.
      </StyledDescription>
      <ConfirmBtnWrap>
        <CustomButton type="primary" size="medium" onClick={handleClick}>
          Confirm
        </CustomButton>
      </ConfirmBtnWrap>
      <CancelBtnWrap>
        <CustomButton
          type="tertiary"
          size="medium"
          onClick={() => setAlertActive(false)}
        >
          Cancel
        </CustomButton>
      </CancelBtnWrap>
    </WarningPopupWarp>
  );
};

export default WarningPopup;

const WarningPopupWarp = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "614px",
  padding: "20px",
  borderRadius: "20px",
  border: "1px solid #292929",
  background: "#1E1E1E",
  [theme.breakpoints.down(768)]: {
    width: "90vw",
    maxWidth: "614px",
  },
}));

const StyledHeader = styled(Typography)({
  color: "#FEFEFE",
  fontWeight: 700,
  fontSize: "1.25rem",
  lineHeight: "2rem",
  marginBottom: "12px",
  textAlign: "center",
});

const StyledDescription = styled(Typography)({
  color: "#FEFEFE",
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "1.5rem",
  marginBottom: "20px",
  textAlign: "center",
});

const ConfirmBtnWrap = styled(Box)(({ theme }) => ({
  marginBottom: "16px",
  "& button": {
    width: "383px !important",
  },
  [theme.breakpoints.down(768)]: {
    "& button": {
      width: "60vw !important",
    },
  },
}));

const CancelBtnWrap = styled(Box)(({ theme }) => ({
  "& button": {
    color: "#FEFEFE !important",
    [theme.breakpoints.down(768)]: {},
  },
}));
