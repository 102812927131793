import { Box } from "@mui/material";
import { useLearnContext } from "./LearnContext";
import QuestionAnswerCard from "../../Copilot/Playground/jd/prepare/QuestionAnswerCard";

const QuestionLearningTasks = ({ roadmapId }: { roadmapId: string }) => {
  const {
    activeCourse,
    aiQuestions,
    activeSectionId,
    learningProgress,
    updateCourseTaskProgress,
  } = useLearnContext();

  const questionsLimit = 5;

  function isQuestionAnswered(question: string) {
    return (
      learningProgress &&
      learningProgress.progress[activeSectionId]?.completedTasks?.some(
        (task: any) =>
          task.analysis.question === question || task.question === question
      )
    );
  }

  function renderQuestionCards() {
    const questions =
      aiQuestions?.[activeCourse?.id]?.[activeSectionId]?.slice(
        0,
        questionsLimit
      ) || [];

    return questions.map((question, index) => (
      <Box key={index}>
        <QuestionAnswerCard
          question={question}
          questionNumber={index + 1}
          showAnswer={false}
          bgColor="#1E1E1E"
          callback={(questionResponse: any) =>
            updateCourseTaskProgress(questionResponse, activeCourse, roadmapId)
          }
          answered={isQuestionAnswered(question)}
        />
      </Box>
    ));
  }

  return <>{renderQuestionCards()}</>;
};

export default QuestionLearningTasks;
