import { useContext, createContext } from 'react';

export const AppContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

export const ConnectionRequestsContext = createContext();

export function useConnectionRequestsContext() {
  return useContext(ConnectionRequestsContext);
}

export const ViewMoreConnectionsContext = createContext();

export function useViewMoreConnectionsContext() {
  return useContext(ViewMoreConnectionsContext);
}

export const ProfileContext = createContext();

export function SetProfileContext() {
  return useContext(ProfileContext);
}
