import { styled, Box, Typography } from "@mui/material";
import eigthyTwentyLogo from "../../images/landing/EightyTwenty/eightyTwentyBanner.png";
import { smallArrowRightSvg, eightyTwentyIcon } from "../UserHome/Base/SVG";
import { Link } from "react-router-dom";
import { Link as ExternalLink } from "@mui/material";

const AsideContentBox = styled(Box)(({ theme }) => ({
  maxWidth: "277px",
  minWidth: "221px",
  width: "100%",
  backgroundColor: "#1e1e1e",
  borderRadius: "20px",
  padding: "20px",
  color: "#fefefe",
  [theme.breakpoints.down(701)]: {
    width: "100%",
    maxWidth: "460px",
    margin: "16px 0",
  },
}));

const LinkBox = styled(Link)(({ theme }) => ({
  backgroundColor: "#292929",
  padding: "8px 16px",
  borderRadius: "30px",
  color: "#fefefe",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 8,
  "&:hover": {
    textDecoration: "none",
    backgroundColor: "#1e1e1e",
  },
  [theme.breakpoints.down(1160)]: {
    fontSize: "14px",
    padding: "6px 14px",
  },
  [theme.breakpoints.down(1060)]: {
    fontSize: "12px",
    padding: "4px 12px",
  },
  [theme.breakpoints.down(701)]: {
    fontSize: "16px",
    padding: "8px 16px",
  },
  [theme.breakpoints.down(285)]: {
    fontSize: "14px",
    padding: "4px 14px",
  },
}));

const LearnMoreLink = styled(Box)(({ theme }) => ({
  "& a": {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    gap: 8,
    lineHeight: "20px",
    textDecoration: "none",
    fontWeight: 500,
    fontSize: 14,
    color: "#fdd649",
    [theme.breakpoints.down(1160)]: {
      fontSize: "12px",
    },

    "& span": {
      display: "flex",
    },

    "&:hover": {
      color: "#fefefe",
    },
  },
}));

const RoadmapBanner = ({
  mainMsg,
  secondaryMsg,
}: {
  mainMsg: string;
  secondaryMsg: string;
}) => {
  return (
    <AsideContentBox>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <img src={eigthyTwentyLogo} alt="Eighty twenty " />
      </Box>
      <Box>
        <Typography
          component="h4"
          sx={{ fontWeight: 700, fontSize: "18px", lineHeight: "30px" }}
        >
          8020
        </Typography>
        <Typography
          component="p"
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            marginTop: "8px",
            marginBottom: "6px",
          }}
        >
          {mainMsg}
        </Typography>
        <Typography
          component="p"
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            marginTop: "10px",
            marginBottom: "6px",
          }}
        >
          {secondaryMsg}
        </Typography>
        <LinkBox to="/learn">Generate Now {eightyTwentyIcon}</LinkBox>
        <LearnMoreLink style={{ paddingTop: 8 }}>
          <ExternalLink
            href="https://devlog.tublian.com/tublian-8020-the-future-of-developer-education"
            target="_blank"
          >
            Learn more about 8020
            <span>{smallArrowRightSvg}</span>
          </ExternalLink>
        </LearnMoreLink>
      </Box>
    </AsideContentBox>
  );
};

export default RoadmapBanner;
