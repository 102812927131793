import { makeStyles } from "@mui/styles";
import heroImage from "../../../images/landing/landingHeroImage.png";
import heroImageSm from "../../../images/landing/heroImageSm.png";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  // Custom styles
  header: {
    marginTop: 100,

    "&>h2": {
      margin: "auto",
      width: 560,
      fontSize: "48px",
      textAlign: "center",
      lineHeight: "72px",
      background: "linear-gradient(to right, #FEFEFE 0%, #FED254 100%)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textFillColor: "transparent",
      fontWeight: 700,
      paddingBottom: 18,

      [theme.breakpoints.down(600)]: {
        fontSize: 30,
        lineHeight: "45px",
        width: 358,
      },
      [theme.breakpoints.down(400)]: {
        width: "100%",
      },
    },
    "&>p": {
      width: 581,
      margin: "auto",
      textAlign: "center",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "24px",
      color: "#fefefe",
      paddingBottom: 60,

      [theme.breakpoints.down(600)]: {
        marginTop: 20,
        fontSize: 14,
        lineHeight: "20px",
        width: 294,
      },
      [theme.breakpoints.down(330)]: {
        width: "100%",
      },
    },
  },

  // MainTopSec styles
  root: {
    maxWidth: "1200px",
    margin: "auto",
    [theme.breakpoints.down(900)]: {
      paddingTop: 16,
    },
  },

  mainCon: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: 80,
    [theme.breakpoints.down(900)]: {
      paddingTop: 40,
    },
  },
  paperHead: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
    margin: "auto",
    textAlign: "center",
    marginTop: 45,
    color: "#fefefe",
    width: 407,
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
      fontSize: 14,
      lineHeight: "20px",
      width: 294,
    },
    [theme.breakpoints.down(330)]: {
      width: 240,
    },
  },

  mainText: {
    width: "671px",
    margin: "auto",

    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
    [theme.breakpoints.down(900)]: {
      maxWidth: "520px",
      width: "100%",
    },
    "&>h1": {
      background: "linear-gradient(to right, #FEFEFE 0%, #FED254 100%)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textFillColor: "transparent",
      fontWeight: 700,
      fontSize: "68px",
      textAlign: "center",
      lineHeight: "86.77px",
      textTransform: "capitalize",
      [theme.breakpoints.down(900)]: {
        fontSize: "36px",
        lineHeight: "46px",
      },
    },
  },
  emphasizedText: {
    color: "#EFB81F",
    textDecorationThickness: "0.3vw",
    [theme.breakpoints.down(600)]: {
      fontSize: "2rem",
    },
  },

  switchBtn: {
    marginTop: 70,
    marginBottom: 60,
    [theme.breakpoints.down(900)]: {
      // marginTop: 48,
      marginTop: 31,
      marginBottom: 12,
    },
  },

  ctaBtnBox: {
    margin: "64px 0 40px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(600)]: {
      margin: "20px 0",
    },
  },
  joinBtn: {
    fontWeight: 700,
    fontSize: "1.25rem",
    color: "#FEFEFE",
    textDecoration: "none",
    padding: "16px 46px",
    borderRadius: 0,
    background: "linear-gradient(101.33deg, #F4C134 -1.26%, #FF296D 85.42%)",
    [theme.breakpoints.down(900)]: {
      fontSize: "1rem",
    },
  },

  btn: {
    padding: "6px 6px 6px 6px",
    cursor: "pointer",
    border: "1px solid transparent",
    background:
      "linear-gradient(#090600, #090600) padding-box, linear-gradient(109.15deg, #FBDA61 0%, #FF5ACD 100%) border-box",
    borderRadius: 20,
    width: 196,
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 51,
    fontSize: 14,
    [theme.breakpoints.down(900)]: {
      marginTop: 48,
    },
    "&>a": {
      textDecoration: "none",
    },
  },

  activeButton: {
    textDecoration: "none",
    padding: "0px 10px",
    background:
      "linear-gradient(to right, #FBDA61 0%, rgba(255, 90, 205, 0.84) 100%)",
    color: "#121212",
    borderRadius: 20,
    fontWeight: 500,
    lineHeight: "20px",
  },
  inactiveButton: {
    padding: "0px 10px",
    color: "#888888",
  },

  //   VideoSec styles
  videoFrame: {
    height: 767,
    margin: "71px 0",
    zIndex: 999,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `url(${heroImage})`,
    backgrondRepeat: "no-repeat",
    backgroundSize: "cover",
    maxWidth: "1135px",
    minWidth: "100%",
    [theme.breakpoints.down(1150)]: {
      height: 650,
    },

    [theme.breakpoints.down(830)]: {
      marginTop: 40,
    },
    [theme.breakpoints.down(720)]: {
      marginTop: 40,
    },
    [theme.breakpoints.down(600)]: {
      background: `url(${heroImageSm})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  },

  videoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 30px",
    [theme.breakpoints.down(600)]: {
      margin: "0 0 0 16px",
    },
  },

  blur: {
    position: "absolute",
    width: 971,
    height: 116,
    borderRadius: 971,
    background:
      "linear-gradient(91deg, rgba(127, 93, 221, 0.80) 25.81%, rgba(0, 114, 232, 0.80) 77.14%)",
    filter: "blur(90px)",
    pointerEvents: "none",
    top: "700px",
    left: "113px",
    [theme.breakpoints.down(900)]: {
      top: "37.75rem",
      width: 800,
      left: "3rem",
    },
    [theme.breakpoints.down(600)]: {
      width: 392,
      top: "25.75rem",
      left: "3rem",
    },
  },

  playControl: {
    position: "absolute",
    cursor: "pointer",
    [theme.breakpoints.down(600)]: {
      width: 80,
      height: 80,
    },
  },

  //   AffiliatedCompanies styles
  headerText: {
    width: "800px",
    margin: "auto",
    paddingTop: "100px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
      paddingTop: "60px",
    },
    [theme.breakpoints.down(900)]: {
      maxWidth: "520px",
      width: "100%",
    },
    "&>h4": {
      background: "linear-gradient(to right, #FEFEFE 0%, #FED254 100%)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textFillColor: "transparent",
      fontWeight: 700,
      fontSize: "30px",
      textAlign: "center",
      lineHeight: "45px",
      marginBottom: 60,
      marginTop: 100,
      textTransform: "capitalize",
      [theme.breakpoints.down(900)]: {
        fontSize: "30px",
        lineHeight: "46px",
        marginTop: 0,
      },
    },
  },
  companies: {
    margin: "auto",
    width: 818,
    height: 80,
  },
  companyBox: {
    marginRight: 12,
  },

  // TechJourneyEmpowerment styles

  empowermentWrapper: {
    display: "flex",
    gap: "20px",
    justifyContent: "center",
    margin: "0 64px",
    [theme.breakpoints.down(900)]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down(720)]: {
      margin: 0,
    },
  },
  empowermentWrapper2: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 64px 0 64px",

    [theme.breakpoints.down(720)]: {
      margin: "20px 0 0 0",
    },
  },

  empowermentBox: {
    backgroundColor: "#1F1400",
    borderRadius: 20,
    // padding: 20,
    color: "#fefefe",
    textAlign: "center",

    "&>h4": {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "34px",
      marginBottom: 8,
    },
    "&>p": {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "24px",
    },
    "&>div": {
      height: "fit-content",
      width: "100%",
      marginBottom: 28,
      [theme.breakpoints.down(900)]: {
        height: "160px",
      },
      "&>img": {
        width: "100%",
        overflow: "hidden",
        [theme.breakpoints.down(900)]: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
    },
  },
  box1: {
    width: 344,
    "&>div": {
      "&>img": {
        objectFit: "contain",
      },
    },
    [theme.breakpoints.down(900)]: {
      width: "100%",
      height: "298px",
    },
    [theme.breakpoints.down(720)]: {
      width: "100%",
      height: "100%",
    },

    "&>h4": {
      padding: "0 20px 0px 20px",
    },
    "&>p": {
      padding: "0 20px 20px 20px",
    },
  },
  box2: {
    width: 708,
    [theme.breakpoints.down(900)]: {
      width: "100%",
      height: "298px",
    },
    [theme.breakpoints.down(720)]: {
      width: "100%",
      height: "100%",
    },

    "&>p": {
      padding: "0 110px 20px 110px",
      [theme.breakpoints.down(900)]: {
        padding: "0 20px 20px 20px",
      },
    },
  },
  box3: {
    width: "100%",
    [theme.breakpoints.down(900)]: {
      width: "100%",
      overflow: "hidden",
    },
    [theme.breakpoints.down(720)]: {
      width: "100%",
      height: "100%",
    },
    "&>p": {
      width: 581,
      margin: "auto",
      paddingBottom: 20,
      [theme.breakpoints.down(720)]: {
        width: "100%",
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },
  },

  // BecomeFutureReadyStyles
  futureReadyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 40,
    margin: "0 64px",

    [theme.breakpoints.down(900)]: {
      margin: "0 64px",
      gap: 28,
    },

    [theme.breakpoints.down(600)]: {
      margin: "0",
      gap: 28,
    },
  },
  futureReadyWrapper: {
    display: "flex",
    justifyContent: "center",
    color: "#fefefe",
    alignItems: "center",
    borderRadius: 20,
    height: 514,
    gap: 60,
    overflow: "hidden",

    [theme.breakpoints.down(1001)]: {
      gap: 20,
    },

    [theme.breakpoints.down(900)]: {
      flexDirection: "column",
      gap: 28,
      height: "fit-content",
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      gap: 28,
      height: "575px",
    },
  },
  futureReadyCardWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  futureReadySubtext: {
    width: 511,
    marginBottom: 100,
    [theme.breakpoints.down(900)]: {
      fontSize: 30,
      lineHeight: "45px",
      width: 240,
    },
  },
  futureReadyBox1: {
    backgroundColor: "#7F5DDD",
    flexDirection: "row",
    [theme.breakpoints.down(900)]: {
      flexDirection: "column",
      padding: "20px 0 0 20px",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      height: "fit-content",
    },
    "& .flex1": {
      width: 494,
      marginLeft: "40px",

      [theme.breakpoints.down(720)]: {
        width: 289,
        marginLeft: "0px",
      },

      [theme.breakpoints.down(350)]: {
        width: "100%",
      },

      "&>h1": {
        fontSize: 30,
        fontWeight: 700,
        lineHeight: "45px",
        marginBottom: 8,
        [theme.breakpoints.down(900)]: {
          fontSize: 24,
          lineHeight: "34px",
        },
        [theme.breakpoints.down(350)]: {
          paddingRight: 20,
        },
      },

      "&>p": {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "24px",
        [theme.breakpoints.down(350)]: {
          paddingRight: 20,
        },
      },
    },

    "& .flex2": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      height: 514,
      position: "relative",
      width: 578,

      [theme.breakpoints.down(900)]: {
        height: 369,
        width: "100%",
        paddingTop: 18,
        paddingLeft: 12,
        justifyContent: "flex-end",
      },

      "&> .illustration": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        zIndex: 1,
        [theme.breakpoints.down(900)]: {
          height: "100%",
          width: "100%",
        },

        "&> .flexBlur": {
          position: "absolute",
          backgroundColor: "#27135D",
          filter: "blur(40px)",
          left: -16,
          bottom: 0,
          width: 83,
          height: "100%",
          [theme.breakpoints.down(900)]: {
            height: "353px",
            left: 36,
          },
        },
      },

      "& .subImage": {
        display: "none",
      },
    },
  },
  futureReadyBox2: {
    backgroundColor: "#37527C",
    flexDirection: "row-reverse",
    [theme.breakpoints.down(900)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      height: "fit-content",
    },

    "& .flex1": {
      width: 494,
      marginRight: "40px",

      [theme.breakpoints.down(900)]: {
        width: 289,
        padding: "20px 20px 0 20px",
        alignItems: "flex-start",
      },
      [theme.breakpoints.down(350)]: {
        width: "100%",
      },

      "&>h1": {
        fontSize: 30,
        fontWeight: 700,
        lineHeight: "45px",
        marginBottom: 8,
        [theme.breakpoints.down(900)]: {
          fontSize: 24,
          lineHeight: "34px",
        },
        [theme.breakpoints.down(350)]: {
          paddingRight: 20,
        },
      },
      "&>p": {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "24px",
        [theme.breakpoints.up(1200)]: {
          width: 376,
        },
        [theme.breakpoints.down(350)]: {
          width: "100%",
          paddingRight: 20,
        },
      },
    },

    "& .flex2": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: 514,
      position: "relative",
      width: 578,
      [theme.breakpoints.down(900)]: {
        height: 369,
        width: "100%",
      },

      "&> .illustration": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        position: "relative",
        zIndex: 1,
        [theme.breakpoints.down(900)]: {
          height: "100%",
          width: "100%",
          paddingBottom: "10px",
        },
        // "&> .flexBlur": {
        //   position: "absolute",
        //   backgroundColor: "#27135D",
        //   filter: "blur(40px)",
        //   right: -16,
        //   width: 83,
        //   height: "100%",
        //   [theme.breakpoints.down(900)]: {
        //     height: "353px",
        //     right: 36,
        //   },
        // },
      },
      "& .subImage": {
        display: "none",
      },
    },
  },

  futureReadyBox3: {
    backgroundColor: "#37527C",
    "& .flex2": {
      "&> .illustration": {
        "&> .flexBlur": {
          backgroundColor: "#1F2F47",
        },
      },
    },
  },
  futureReadyBox4: {
    backgroundColor: "#008777",
    "& .flex2": {
      "&> .illustration": {
        "&> .flexBlur": {
          backgroundColor: "#004752",
        },
      },
    },
  },

  futureReadyBox5: {
    backgroundColor: "#00857A",
    flexDirection: "row",

    [theme.breakpoints.down(900)]: {
      flexDirection: "column",
      padding: "20px 20px 0px 20px",
      height: "fit-content",
      alignItems: "flex-start",
    },
    "& .flex1": {
      width: 386,
      marginLeft: "40px",
      color: "#fefefe",
      [theme.breakpoints.down(990)]: {
        marginLeft: "0px",
        width: 491,
      },
      [theme.breakpoints.down(720)]: {
        width: 289,
        marginLeft: "0px",
        justifyContent: "flex-start",
      },
      [theme.breakpoints.down(370)]: {
        width: "100%",
        padding: "20px 20px 0 0px",
      },
      "&>h1": {
        fontSize: 30,
        fontWeight: 700,
        lineHeight: "45px",
        marginBottom: 8,
        marginTop: 20,
        [theme.breakpoints.down(900)]: {
          fontSize: 24,
          lineHeight: "34px",
        },
        [theme.breakpoints.down(370)]: {
          marginTop: 0,
          paddingRight: 20,
        },
      },
      "&>p": {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "24px",
        [theme.breakpoints.down(350)]: {
          paddingRight: 20,
        },
      },
    },
    "& .flex2": {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      height: 514,
      zIndex: 1,
      width: 578,
      marginTop: 28,
      [theme.breakpoints.down(900)]: {
        height: 387,
        justifyContent: "left",
        width: "100%",
        paddingRight: 10,
      },

      "&> .illustration": {
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down(900)]: {
          height: "100%",
          paddingTop: 20,
        },
        // "&> .flexBlur": {
        //   position: "absolute",
        //   // backgroundColor: "#9D7402",
        //   filter: "blur(24.2px)",
        //   width: "100%",
        //   zIndex: -1,
        //   height: "100%",
        // },
      },
      "& .subImage": {
        zIndex: 999,
        position: "absolute",
        top: "45px",
        right: "-100px",
        [theme.breakpoints.down(1051)]: {
          left: "-100px",
        },
        [theme.breakpoints.down(1000)]: {
          left: "-80px",
        },
        [theme.breakpoints.down(958)]: {
          left: "130px",
        },

        [theme.breakpoints.down(900)]: {
          width: 215,
          height: 196,
          top: "60px",
        },
      },
    },
  },

  // Dev testimonials
  testimonials: {
    padding: "0 0 0 64px",
    margin: "auto",
    maxWidth: "1600px",
    height: "fit-content",
    [theme.breakpoints.down(900)]: {
      padding: "0 0 40px 0px",
      width: "100%",
    },
    [theme.breakpoints.down(1200)]: {
      maxWidth: "1200px",
    },
  },

  testimonialBox: {
    width: 340,
    paddingLeft: "20px",
    [theme.breakpoints.down(900)]: {
      paddingLeft: "16px",
    },
  },

  innerWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // height: 255,
    padding: "20px",
    overflow: "hidden",
  },

  streetcredCta: {
    display: "flex",
    justifyContent: "center",
    margin: "60px 0",
    [theme.breakpoints.down(900)]: {
      marginTop: "28px 0",
    },
  },

  devTesBtn: {
    display: "flex",
    justifyContent: "center",
    marginTop: 68,
    [theme.breakpoints.down(900)]: {
      marginTop: 40,
    },
  },

  testimonialCard: {
    border: "1px solid transparent",
    backgroundColor: "#19191B",
    borderRadius: "20px",
    zIndex: 999,
    width: 340,
    height: "fit-content",
    overflow: "hidden",
    [theme.breakpoints.down(361)]: {
      width: 300,
    },
    [theme.breakpoints.down(325)]: {
      width: 260,
    },
    [theme.breakpoints.down(279)]: {
      width: "100%",
    },
  },
  text: {
    overflowWrap: "break-word",
    color: "#B7B7B7",
    lineHeight: "24px",
    fontSize: 16,
    fontWeight: 500,
    marginTop: "17px",
  },

  info: {
    "&>p": {
      color: "#fefefe",
      lineHeight: "32px",
      fontSize: 20,
      fontWeight: 700,
    },
  },

  image: { height: "40px", width: "40px", borderRadius: "50%" },

  //Open source journey styles
  opensourceContainer: {
    backgroundColor: "#FDCA3A",
    borderRadius: 20,
    margin: "100px 0",
  },
  opensourceWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 77,
    width: "100%",

    margin: "auto",
    maxWidth: 1200,
    [theme.breakpoints.down(1050)]: {
      gap: 37,
      paddingBottom: 40,
    },
    [theme.breakpoints.down(900)]: {
      flexDirection: "row",
      gap: 20,
      paddingTop: 40,
    },
    [theme.breakpoints.down(720)]: {
      flexDirection: "column",
      gap: 20,
    },
  },
  opensourceBox1: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingLeft: 80,
    [theme.breakpoints.down(1093)]: {
      width: "50%",
    },
    [theme.breakpoints.down(720)]: {
      padding: "20px 20px 0 20px",
      width: "100%",
    },
    "&>h2": {
      color: "#121212",
      fontSize: 36,
      fontWeight: 700,
      lineHeight: "52px",
      [theme.breakpoints.down(900)]: {
        fontSize: 30,
        lineHeight: "45px",
      },
      [theme.breakpoints.down(720)]: {
        width: "90%",
      },
      [theme.breakpoints.down(500)]: {
        width: "100%",
      },
    },
    "&>p": {
      color: "#121212",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "24px",
      [theme.breakpoints.down(720)]: {
        width: "90%",
      },
      [theme.breakpoints.down(500)]: {
        width: "100%",
      },
    },
  },
  opensourceLink: {
    color: "#fefefe",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    backgroundColor: "#121212",
    padding: "8px 32px",
    borderRadius: 20,
    textDecoration: "none",
    cursor: "pointer",
  },

  opensourceBox2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(1093)]: {
      width: "50%",
    },
    [theme.breakpoints.down(720)]: {
      width: "100%",
      height: "100%",
    },
    "&>img": {
      [theme.breakpoints.down(1093)]: {
        width: "100%",
        height: "100%",
      },
    },
    "& .desktop": {
      [theme.breakpoints.down(720)]: {
        display: "none",
      },
    },
    "& .mobile": {
      [theme.breakpoints.up(720)]: {
        display: "none",
      },
    },
  },
}));
