import { useEffect } from "react";
import LearningRoadmapEndpoint from "../../../../libs/LearningRoadmapEndpoint";
import { useLearnContext } from "../LearnContext";
import { userProfile } from "../../../../libs/UserProfile";

const useFetchDeepdiveRoadmap = (roadmapId: string) => {
  const {
    activeCourse,
    activeSectionId,
    setDeepdiveRoadmapPhases,
    updateLoadingState,
  } = useLearnContext();
  const userId = userProfile(localStorage).userId();

  useEffect(() => {
    const deepdiveRoadmapId =
      activeCourse?.sections.get(activeSectionId)?.goDeeperRoadmapId;

    const fetchDeepdiveRoadmap = async () => {
      try {
        updateLoadingState("loadingDeepdiveRoadmap", "loading");
        const deepdiveRoadmapData = await LearningRoadmapEndpoint.getRoadmap(
          deepdiveRoadmapId,
          userId
        );
        if (deepdiveRoadmapData.error) {
          throw new Error("Error encountered while fetching deep dive roadmap");
        }
        setDeepdiveRoadmapPhases(deepdiveRoadmapData);
      } catch (error) {
        console.error("Error fetching deep dive roadmap:", error);
        // setError("Error fetching deep dive roadmap, please retry.");
        updateLoadingState("loadingDeepdiveRoadmap", "loaded");
      } finally {
        updateLoadingState("loadingDeepdiveRoadmap", "loaded");
      }
    };

    if (deepdiveRoadmapId) {
      fetchDeepdiveRoadmap();
    }

    return () => {
      // Cleanups go here
    };

    // eslint-disable-next-line
  }, [roadmapId, activeCourse, activeSectionId, userId]);
};

export default useFetchDeepdiveRoadmap;
