import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { userProfile } from './libs/UserProfile';

Bugsnag.start({
  apiKey: 'b5c075bec5c6dcc6dd0127c543ed2104',
  plugins: [new BugsnagPluginReact()],
  notifyReleaseStages: ['production', 'staging'],
  onError: function (event) {
    console.log(">>>> onerror ", event);
    event.setUser(userProfile(localStorage).userId())
  }
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <App />
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
