import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import {
  ArrowBackOutlined,
  CheckCircleOutlineRounded,
  Close,
} from "@mui/icons-material";
import { useLearnContext } from "../LearnContext";
import CustomButton from "../../../Common/CustomButton";
import PackageCard from "./PackageCard";
import EightyTwentyCheckoutWrap from "./EightyTwentyCheckoutWrap";
import Spinner from "../../../Common/Spinner";
import DateUtils from "../../../../libs/DateUtils";
import FT from "../../../../libs/FT";
import { CreditPaymentHistoryItem } from "../../Common/LearningTypes";
import StatusNotification from "../../../Common/StatusNotification";
import { PaymentHistorySkeleton } from "../../Skeletons/Skeletons";
import { userProfile } from "../../../../libs/UserProfile";
import CustomLoader from "../../../Common/CustomLoader";

const BuyCredits = () => {
  const history = useHistory();
  const [loadingPackages, setLoadingPackages] = useState<
    "not_loading" | "loading" | "loaded"
  >("not_loading");
  const [loadingPaymentHistory, setLoadingPaymentHistory] = useState(false);
  const paymentHistoryRef = useRef(null);
  const { credits, error, loadingState, getCreditStatus, setError } =
    useLearnContext();
  const [paymentHistory, setPaymentHistory] = useState<
    CreditPaymentHistoryItem[] | undefined
  >();
  const historyLimit = 5;
  const [product, setProduct] = useState<Product>();

  const defaultPaymentDetails = {
    isActive: false,
    credits: 0,
    price: 0,
    priceId: "",
    currency: "$",
    currencyId: "usd",
    name: userProfile(localStorage).userId(),
  };

  const [paymentDetails, setPaymentDetails] =
    useState<EightyTwentyPaymentDetails>(defaultPaymentDetails);

  const handlePurchase = (price: number, priceId: string, credits: number) => {
    setPaymentDetails((prevData) => ({
      ...prevData,
      isActive: true,
      credits,
      price,
      priceId,
    }));
  };

  const getLocation = async () => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = (position as any).coords;
      const res = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      ).then((res) => res.json());

      return res.address.country;
    } catch (geolocationError) {
      console.error("Geolocation error:", geolocationError);
      try {
        const res = await fetch("https://api.country.is").then((res) =>
          res.json()
        );

        return res.country;
      } catch (error) {
        console.error("IP fetch error:", error);
        throw error;
      }
    }
  };

  const getPaymentDetails = async () => {
    try {
      const location = await getLocation();
      let updatedDetails = { ...paymentDetails };

      if (["NG", "Nigeria"].includes(location)) {
        updatedDetails = {
          ...updatedDetails,
          currency: "₦",
          currencyId: "ngn",
        };
      } else if (["IN", "India"].includes(location)) {
        updatedDetails = {
          ...updatedDetails,
          currency: "₹",
          currencyId: "inr",
        };
      }
      setPaymentDetails(updatedDetails);
    } catch (error) {
      console.error("error", error);
      setPaymentDetails((prevData) => ({
        ...prevData,
        currency: "$",
        currencyId: "usd",
        // name: "Okwudili Joshua Odumodu",
      }));
    }
  };

  const fetchProduct = async () => {
    setLoadingPackages("loading");
    const productId = process.env.REACT_APP_8020_PRODUCT_ID;
    try {
      const productResult = await FT.getCreditPaymentInfo(
        "productInfo",
        `productId=${productId}&currency=${paymentDetails.currencyId}`
      );
      setProduct(productResult);
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoadingPackages("loaded");
    }
  };

  const fetchPaymentHistory = async () => {
    setLoadingPaymentHistory(true);
    try {
      const res = await FT.getCreditPaymentInfo("paymentHistoryInfo");
      if (res.error) {
        console.error("error", res.error);
        throw new Error(res.error);
        // return undefined;
      }
      setPaymentHistory(res);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoadingPaymentHistory(false);
    }
  };

  useEffect(() => {
    if (!loadingState.loadedCredits && !loadingState.loadedCredits) {
      getCreditStatus();
    }

    (async () => {
      await getPaymentDetails();
      await fetchProduct();
    })();

    // Fetch history only when in view
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // When 50% of the element is visible
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !paymentHistory) {
          fetchPaymentHistory();
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (paymentHistoryRef.current) {
      observer.observe(paymentHistoryRef.current);
    }

    return () => {
      observer.disconnect();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {(paymentDetails.isActive || error.error) && (
        <ModalOverlay>
          <Box>
            {paymentDetails.isActive && (
              <EightyTwentyCheckoutWrap
                credits={paymentDetails.credits}
                price={paymentDetails.price}
                priceId={paymentDetails.priceId}
                currency={paymentDetails.currency || "$"}
                currencyId={paymentDetails.currencyId || "usd"}
                setPaymentDetails={setPaymentDetails}
              />
            )}

            {error.error && (
              <StatusNotification
                status="error"
                statusMessage={error.errorMsg}
                cancelBtnName="Close"
                cancelBtnHandleClick={() =>
                  setError({ error: false, errorMsg: "" })
                }
              />
            )}
          </Box>
        </ModalOverlay>
      )}
      <BuyCreditsWrap>
        <CurrentStatus>
          <CustomButton
            size="small"
            type="tertiary"
            onClick={() => window.history.back()}
          >
            <ArrowBackOutlined />
            Back
          </CustomButton>
          <SectionHeader sx={{ margin: "12px 0 20px" }}>
            <StyledHeader>Add More Credits</StyledHeader>
            <StyledSubHeader>
              Continue your tech journey with additional credits
            </StyledSubHeader>
          </SectionHeader>

          <CreditCard padding="16px 20px">
            <StyledSubHeader color="#CFCFCF">Credit Balance</StyledSubHeader>
            {loadingState.loadingCredits ? (
              <Spinner size={18} pryColor="#FEFEFE" secColor="#121212" />
            ) : (
              <StyledCredits>{credits || 0}</StyledCredits>
            )}
          </CreditCard>
        </CurrentStatus>

        <CreditPackagesWrap>
          <SectionHeader sx={{ margin: "0 0 20px" }}>
            <StyledHeader>Credit Packages</StyledHeader>
            <StyledSubHeader>Choose your credit package</StyledSubHeader>
            {loadingPackages === "loaded" && !product && (
              <Box padding="8px 0">
                <Typography
                  fontSize="0.875rem"
                  fontWeight={500}
                  lineHeight="1.25rem"
                  color="#CFCFCF"
                >
                  No payment packages found
                </Typography>
              </Box>
            )}
          </SectionHeader>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "18px" }}>
            {/* {loadingPackages && <CreditsPackagesSkeleton />} */}
            {loadingPackages !== "loaded" && (
              <LoaderWrap>
                <CustomLoader primaryMsg="Getting Payment Packages..." />
              </LoaderWrap>
            )}

            {loadingPackages === "loaded" &&
              product?.prices?.map(
                ({ id, credits, currency_options, unit_amount }) => (
                  <PackageCard
                    key={id}
                    currency={paymentDetails.currency || "$"}
                    currencyId={paymentDetails.currencyId || "usd"}
                    price={
                      currency_options[paymentDetails.currencyId || "usd"]
                        .unit_amount / 100 || unit_amount
                    }
                    // price={unit_amount}
                    credits={credits}
                    features={[
                      "Customizable Content: Choose videos or blogs, sorted from newest to top hits.",
                      "Tublian AI Bot: Your go-to for exploring and understanding new topics.",
                      "Digital Certificate: Earn and share your proof of completion.",
                    ]}
                    handlePurchase={() =>
                      handlePurchase(
                        currency_options[paymentDetails.currencyId || "usd"]
                          .unit_amount / 100,
                        id,
                        credits
                      )
                    }
                  />
                )
              )}
          </Box>
        </CreditPackagesWrap>

        <PaymentHistoryWrap ref={paymentHistoryRef}>
          <HistoryHeaderWrap>
            <SectionHeader>
              <StyledHeader>Payment History</StyledHeader>
              <StyledSubHeader>View your payment History</StyledSubHeader>
            </SectionHeader>
            {!loadingPaymentHistory &&
              paymentHistory &&
              paymentHistory.length > 0 && (
                <CustomButton
                  type="grey"
                  size="medium"
                  onClick={() => history.push("/8020/credits/history")}
                >
                  View payment history
                </CustomButton>
              )}
          </HistoryHeaderWrap>

          {!loadingPaymentHistory &&
            (!paymentHistory || paymentHistory.length === 0) && (
              <Box padding="8px 0">
                <Typography
                  fontSize="0.875rem"
                  fontWeight={500}
                  lineHeight="1.25rem"
                  color="#CFCFCF"
                >
                  No payment history found
                </Typography>
              </Box>
            )}

          {loadingPaymentHistory && <PaymentHistorySkeleton />}

          {!loadingPaymentHistory && (
            <PaymentHistoryCardWrap>
              {paymentHistory
                ?.slice(0, historyLimit)
                ?.map(({ currency, amount, created, status }, i) => {
                  let currencyId =
                    currency === "inr" ? "₹" : currency === "ngn" ? "₦" : "$";

                  return (
                    <PaymentHistoryCard key={i}>
                      <Box>
                        <StyledHeader>
                          {currencyId}
                          {amount / 100}
                        </StyledHeader>
                        <StyledSubHeader sx={{ fontWeight: 500 }}>
                          {DateUtils.getFormattedDate(new Date(created * 1000))}
                        </StyledSubHeader>
                      </Box>
                      <StyledSubHeader
                        sx={{ fontWeight: 500, color: "#B7B7B7" }}
                      >
                        {status === "succeeded" ? (
                          <StyledCheck htmlColor="#1AFF8C" />
                        ) : (
                          <StyledCloseIcon htmlColor="#FA8785" />
                        )}
                        {status === "succeeded" ? "Successful" : "Failed"}
                      </StyledSubHeader>
                    </PaymentHistoryCard>
                  );
                })}
            </PaymentHistoryCardWrap>
          )}
        </PaymentHistoryWrap>
      </BuyCreditsWrap>
    </>
  );
};

export default BuyCredits;

export type EightyTwentyPaymentDetails = {
  isActive?: boolean;
  credits: number;
  price: number;
  priceId: string;
  currency?: string;
  currencyId?: string;
  name: string;
};

type Price = {
  id: string;
  unit_amount: number;
  currency: string;
  currency_options?: any;
  credits: number;
};

type Product = {
  id: string;
  name: string;
  description: string | null;
  prices: Price[];
};

const BuyCreditsWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "28px",
}));

const CurrentStatus = styled(Box)({});

const HistoryHeaderWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "20px",
  [theme.breakpoints.down(768)]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "20px",
  },
}));

const SectionHeader = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const CreditCard = styled(Box)<{ padding: string }>(
  ({ theme, padding, width = "100%" }) => ({
    display: "flex",
    flexDirection: "column",
    background: "#1E1E1E",
    borderRadius: "20px",
    gap: "8px",
    padding,
    width: "354px",
    "& button": {
      marginTop: "20px",
      alignSelf: "flex-end",
    },
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  })
);

const CreditPackagesWrap = styled(Box)({});

const StyledHeader = styled(Typography)<{ color?: string }>(
  ({ theme, color = "#FEFEFE" }) => ({
    color,
    fontWeight: 700,
    fontSize: "1.125rem",
    lineHeight: "1.875rem",
  })
);

const StyledSubHeader = styled(Typography)<{ color?: string }>(
  ({ theme, color = "#888" }) => ({
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color,
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  })
);

const StyledCredits = styled(Typography)(({ theme }) => ({
  color: "#FEFEFE",
  fontWeight: 700,
  fontSize: "1.875rem",
  lineHeight: "2.8125rem",
}));

const PaymentHistoryWrap = styled(Box)({});

const ModalOverlay = styled(Box)({
  fontStyle: "normal",
  position: "fixed",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  background: "rgba(18, 18, 18, 0.5)",
  WebkitBackdropFilter: "blur(20px) !important",
  backdropFilter: "blur(20px) !important",
  zIndex: 100,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const PaymentHistoryCardWrap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  margin: "20px 0 0",
  gap: "4px",
});

const PaymentHistoryCard = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 0",
  "&>div": {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
}));

const StyledCheck = styled(CheckCircleOutlineRounded)({
  width: "18px",
  height: "18px",
});

const StyledCloseIcon = styled(Close)({
  borderRadius: "50%",
  border: "1px solid #FA8785",
  padding: "4px",
  width: "18px",
  height: "18px",
});

const LoaderWrap = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "12px 0",
});
