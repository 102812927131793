import { Box, Typography, styled } from "@mui/material";
import CustomButton from "../../../Common/CustomButton";
import { CheckOutlined } from "@mui/icons-material";

const PackageCard = ({
  currency,
  price,
  credits,
  features,
  handlePurchase,
}: PackageType8020) => {
  return (
    <CreditCard padding="20px">
      <HeaderWrap>
        <PriceText>{currency + "" + price.toLocaleString()}</PriceText>
        <CreditText>
          <span>{credits}</span> Credits
        </CreditText>
      </HeaderWrap>
      <FeaturesWrap>
        {features?.map((f, i) => (
          <StyledFeature key={i}>
            <CheckOutlined
              sx={{ width: "18px", height: "18px" }}
              htmlColor="#888"
            />
            <StyledSubHeader color="#CFCFCF">{f}</StyledSubHeader>
          </StyledFeature>
        ))}
      </FeaturesWrap>

      <CustomButton size="medium" type="grey" onClick={handlePurchase}>
        Purchase
      </CustomButton>
    </CreditCard>
  );
};

export default PackageCard;

const HeaderWrap = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  margin: "0 0 14px",
});

const StyledSubHeader = styled(Typography)<{ color?: string }>(
  ({ theme, color = "#888" }) => ({
    color,
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  })
);

const PriceText = styled(Typography)({
  color: "#FEFEFE",
  fontWeight: 700,
  fontSize: "1.875rem",
  lineHeight: "2.8125rem",
});

const CreditText = styled(Typography)({
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  color: "#B7B7B7",
  border: "1px solid transparent",
  borderRadius: "81px",
  padding: "0 18px",
  background:
    "linear-gradient(#292929, #292929) padding-box, linear-gradient(109.15deg, #FBDA61 0%, #FF5ACD 100%) border-box",
  "& span": {
    fontSize: "1.125rem",
    lineHeight: "1.875rem",
    color: "#FEFEFE",
  },
});

const FeaturesWrap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const StyledFeature = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const CreditCard = styled(Box)<{ padding: string; width?: string }>(
  ({ theme, padding, width = "100%" }) => ({
    display: "flex",
    flexDirection: "column",
    background: "#1E1E1E",
    borderRadius: "20px",
    padding,
    width,
    "& button": {
      marginTop: "20px",
      alignSelf: "flex-end",
      [theme.breakpoints.down(768)]: {
        alignSelf: "flex-start",
      },
    },
  })
);

export type PackageType8020 = {
  currency: string;
  currencyId: string;
  price: number;
  credits: number;
  features: string[];
  handlePurchase: (currency: string, price: number, credits: number) => void;
};
